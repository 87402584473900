import { useEffect, useState, forwardRef } from 'react'
import Container from '../container/container'
import useDashbord from '../../hooks/dashbord.hook'
import DatePicker, { registerLocale } from 'react-datepicker'
import { toISOStringWithTimezone } from '../../helpers/common'
import Loader from '../loader/loader'

function PrintLoading() {
  const { getPrintLoading } = useDashbord()
  const [date, setDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const onChangeHandler = date => {
    setDate(date)
  }

  useEffect(() => {
    setLoading(true)
    const [dateFormated] = toISOStringWithTimezone(date).split('T')
    ;(async () => {
      const data = await getPrintLoading(dateFormated)
      setData(data)
      setLoading(false)
    })()
  }, [getPrintLoading, date])

  const Td = ({ name, children, className }) => {
    return (
      <td
        className={`
			${className}
			px-[2rem] py-[2.3rem]  border border-Content/Border`}>
        {children || name}
      </td>
    )
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    const isToday = date.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]
    return (
      <div className='flex gap-[1.2rem] items-center' onClick={onClick} ref={ref}>
        <i className='icon-i-calendar text-Regular(24_30) flex items-center text-Accent/Blue'></i>
        <span className='font-semibold'>{isToday ? 'Сегодня' : value}</span>
      </div>
    )
  })

  // if (!data.drawings) return null

  return (
    <Container>
      <div className='text-Regular(16_18) font-semibold mb-[1.2rem]'>Загрузка печати</div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <table>
          <tbody>
            <tr className='text-Regular(14_16) font-semibold text-center'>
              <Td className='hover:cursor-pointer w-[16rem] bg-[#E1CCE7]'>
                <DatePicker
                  selected={date}
                  locale='ru'
                  dateFormat='dd.MM.yyyy'
                  onChange={date => onChangeHandler(date)}
                  customInput={<ExampleCustomInput />}></DatePicker>
              </Td>
              {Object.values(data.drawings || {}).map(x => (
                <Td name={x.name}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name='Итого'></Td>
            </tr>
            <tr className='text-Regular(16_18) text-center'>
              <Td className='bg-[#E1CCE7] font-semibold' name='Тираж, шт'></Td>
              {Object.values(data.drawings || {}).map(({ circulation }) => (
                <Td name={circulation}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name={data.totalCirculation}></Td>
            </tr>
            <tr className='text-Regular(16_18) text-center'>
              <Td className='bg-[#E1CCE7] font-semibold' name='Позиций, шт'></Td>
              {Object.values(data.drawings || {}).map(({ positions: { count } }) => (
                <Td name={count}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name={data.totalPositions}></Td>
            </tr>
            <tr className='text-Regular(16_18) text-center'>
              <Td className='bg-[#E1CCE7] font-semibold' name='Заказов, шт'></Td>
              {Object.values(data.drawings || {}).map(({ order: { count } }) => (
                <Td name={count}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name={data.totalOrders}></Td>
            </tr>
          </tbody>
        </table>
      )}
    </Container>
  )
}

export default PrintLoading
