import useUsers from './users.hook'
import dashbordStore from '../store/dashbord'

export const defaultSettings = {
  sort: {
    name: 'Срочные наверх',
    _default: ['isUrgent:desc', 'id:desc']
  },
  groupView: false,
  table: {
    user: {
      title: 'Ответственный',
      available: true,
      className: '',
      initialWidth: 16,
      width: 16,
      configurable: true,
      padding: false,
      sortPath: 'user.username',
      hideOnService: true
    },
    title: {
      available: true,
      title: 'Назв-е товара',
      configurable: true,
      initialWidth: 21.7,
      width: 21.7,
      className: 'text-Regular(14_16)',
      sortPath: 'position.title'
    },
    code: {
      available: true,
      configurable: true,
      initialWidth: 10,
      width: 10,
      title: 'Артикул',
      className: 'text-Regular(16_18) relative'
    },
    quantity: {
      available: true,
      title: 'Кол-во',
      configurable: true,
      initialWidth: 10,
      width: 10,
      className: 'text-Regular(16_18)',
      sortPath: 'position.quantity'
    },
    logo: {
      available: true,
      title: 'Логотип',
      configurable: true,
      className: '',
      padding: false,
      initialWidth: 10,
      width: 10
      // sortPath: "position.order.logo",
    },
    drawings: {
      available: true,
      title: 'М-д печати',
      configurable: true,
      className: '',
      padding: false,
      initialWidth: 11.5,
      width: 11.5
      // sortPath: "position.drawings.name",
    },
    stamps: {
      available: true,
      title: 'Кол-во цветов.',
      configurable: true,
      className: '',
      padding: false,
      initialWidth: 10,
      width: 10
    },
    manager: {
      available: true,
      title: 'Менеджер',
      configurable: true,
      className: '',
      padding: false,
      initialWidth: 11.3,
      width: 11.3,
      sortPath: 'position.order.manager'
    },
    order: {
      available: true,
      title: '№ зак.',
      configurable: true,
      className: '',
      initialWidth: 12.2,
      width: 12.2,
      sortPath: 'position.order.orderId'
    },
    company: {
      available: true,
      title: 'Заказчик',
      configurable: true,
      className: '',
      initialWidth: 11.5,
      width: 11.5,
      sortPath: 'position.order.company'
    },
    date: {
      available: true,
      title: 'Дата заказа',
      configurable: true,
      className: '',
      initialWidth: 11.5,
      width: 11.5,
      sortPath: 'position.order.date'
    },
    dateDashbord: {
      available: true,
      title: 'Дата прихода на дашборд',
      configurable: true,
      className: '',
      initialWidth: 11.5,
      width: 11.5,
      sortPath: 'dashbordDate'
    },
    dateReady: {
      available: true,
      title: 'Дата готовности',
      configurable: true,
      className: '',
      initialWidth: 11.5,
      width: 11.5,
      sortPath: 'position.readyDate'
    },
    status: {
      available: true,
      title: 'Статус',
      configurable: true,
      className: 'absolute right-[5rem] bg-Dominant/Dop',
      dimensional: false,
      initialWidth: 18.7,
      constant: true,
      width: 18.7,
      padding: false,
      sortPath: 'status.title'
    },
    stage: {
      available: true,
      title: 'Этап',
      configurable: true,
      className: 'absolute right-[5.3rem] bg-Dominant/Dop',
      dimensional: false,
      padding: false,
      constant: true,
      initialWidth: 18.7,
      width: 18.7,
      sortPath: 'stage.title'
    }
  }
}

function useSettings() {
  const { saveSettings } = useUsers()
  const { settings, setSettings } = dashbordStore

  const sanitizeSettings = settings => {
    return Object.entries(settings).reduce((acc, [key, value]) => {
      if (value.configurable) {
        acc[key] = {
          available: value.available,
          width: value.width
        }
      }
      return acc
    }, {})
  }

  const mergeTableSettings = settings => {
    const table = { ...defaultSettings.table }
    Object.entries(settings?.table || {}).forEach(([key, value]) => {
      table[key].available = value.available
      table[key].width = value.width
      table[key].initialWidth = value.width
    })
    return {
      ...settings,
      table
    }
  }

  const setInitialSettings = settings => {
    if (settings) {
      if (settings.groupView && settings.sort.custom) {
        settings.sort = defaultSettings.sort
      }
      dashbordStore.setSettings(mergeTableSettings(settings))
    } else {
      dashbordStore.setSettings(defaultSettings)
    }
  }

  const updateGroupView = async view => {
    const updatedSettings = {
      ...settings,
      groupView: view
    }
    await saveSettings(updatedSettings)
    dashbordStore.setSettings(mergeTableSettings(updatedSettings))
    dashbordStore.clearSelected()
  }

  const updateTableSettings = async table => {
    const updatedSettings = {
      ...settings,
      table
    }
    await saveSettings(updatedSettings)
    dashbordStore.setSettings(mergeTableSettings(updatedSettings))
  }

  const updateSettings = async () => {
    await saveSettings(settings)
  }

  return {
    sanitizeSettings,
    mergeTableSettings,
    updateTableSettings,
    updateSettings,
    setInitialSettings,
    updateGroupView
  }
}

export default useSettings
