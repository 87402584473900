import { useCallback, useEffect, useState, memo } from 'react'

import { observer } from 'mobx-react-lite'
import { useDrag, useDrop } from 'react-dnd'
import ReadyTimePicker from '../readyTimePicker/readyTimePicker'
import CopyToClipboard from '../copyToClipboard/copyToClipboard'
import PositionStatus from '../positionStatus/positionStatus'
import usePosition from '../../hooks/position.hook'
import PositionUser from '../positionUser/positionUser'
import Checkbox from '../ui/checkbox/checkbox'
import DrawingsUserInfo from '../drawingsUserInfo'
import Cell from '../cell/cell'
import dashbordStore from '../../store/dashbord'
import OutsideAlerter from '../outsideAlerter/outsideAlerter'
import Comments from '../comments/comments'
import usersStore from '../../store/users'
import stageStore from '../../store/stage'
import { emmiter } from '../../helpers/Emmiter'
import { date } from '../../utils/date'
import './style.css'
import ProductSetLabel from '../productSetLabel/ProductSetLabel'

function Position(props) {
  const {
    id,
    isUrgent,
    settings,
    title,
    onOrderDetail,
    onPositionDetail,
    user,
    isAllMod,
    quantity,
    positionStageId,
    createdAt,
    status,
    timestamps,
    order,
    setComment,
    setSticker,
    comments,
    stage,
    dashbordDate,
    isCurrentStage,
    blocked,
    moveCard,
    index,
    code,
    drawings,
    getData,
    debouncedGetData,
    stampSpecialId,
    readyDate,
    groupView,
    stageInfo = [],
    showOrderKitModal,
    drawingsFilter,
    positionStock,
    kitID
  } = props
  const [positionRef, setPositionRef] = useState(null)
  const { setUser, setStatus, setPrintStatus, setPrintUser } = usePosition()
  const [isVisible, setIsVisible] = useState(true)
  const [actionsPanelOpen, setActionsPanelOpen] = useState(false)
  const isSelected = dashbordStore.getIsPositionsSelected(positionStageId)
  const [active, setIsActive] = useState(false)
  const [extra, setExtra] = useState(false)
  const [displayedDrawings, setDisplayedDrawings] = useState(drawings)

  useEffect(() => {
    if (!drawingsFilter || drawingsFilter.length > 1) {
      setDisplayedDrawings(drawings)
    } else if (drawingsFilter.length) {
      const filteredDrawings = drawings.filter(drawing => drawingsFilter.includes(drawing.groupCode))
      setDisplayedDrawings(filteredDrawings)
    }
  }, [drawingsFilter, drawings])

  const {
    currentUser: { role }
  } = usersStore
  const { currentStage } = stageStore
  const {
    settings: { sort },
    selectedPositionsOrderId
  } = dashbordStore
  const { id: stageId, service, title: stageName, color: stageColor, step: stageStep } = stage
  const isRoleFitStage = role.find(x => x.godmod) || role.find(x => x.stage?.id === stageId)

  let isPast24Hours =
    !isAllMod && (stageStep === 3 || stageStep === 2) ? date.isPast24Hours(dashbordDate || createdAt) : false
  if (status.initial && status.isForInStock === false) {
    isPast24Hours = false
  }

  const setInactive = useCallback(() => {
    setIsActive(false)
  }, [])

  const onSetUser = async userId => {
    await setUser(positionStageId, userId)
    await getData(true)
  }

  const onSetStatus = async statusId => {
    await setStatus(positionStageId, statusId)
    dashbordStore.clearSelected()
    debouncedGetData()
  }

  const onSetPrintStatus = drawingId => async statusId => {
    await setPrintStatus(positionStageId, statusId, drawingId)
    dashbordStore.clearSelected()
    await getData(true)
  }

  const onSetPrintUser = drawingId => async userId => {
    await setPrintUser(positionStageId, userId, drawingId)
  }

  const onSelectPosition = () => {
    if (groupView && selectedPositionsOrderId !== order.id) {
      dashbordStore.clearSelected()
    }
    if (groupView) {
      dashbordStore.addPosition({
        id: positionStageId,
        position_id: id,
        drawings: drawings,
        status: status,
        selectedItemsOrderId: order.id,
        title,
        code,
        blocked
      })
    } else {
      dashbordStore.addPosition({
        id: positionStageId,
        drawings: drawings,
        position_id: id,
        status: status,
        title,
        code,
        blocked
      })
    }
  }

  const onSetCommentHandler = () => {
    setComment({
      id,
      stageId
    })
  }

  const onSetStickerHandler = () => {
    setSticker({
      title,
      company: order.company,
      quantity,
      code
    })
  }

  const onSetStampProgress = ev => {
    ev.stopPropagation()
    return data => {
      emmiter.emmit('stampProgressModal', data)
    }
  }

  const [_, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!positionRef) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = positionRef?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, id)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  useEffect(() => {
    !extra && setActionsPanelOpen(false)
  }, [extra])

  useEffect(() => {
    if (isRoleFitStage?.godmod && sort.custom) {
      drag(drop(positionRef))
    }
  }, [isRoleFitStage, drag, drop, positionRef, sort])

  useEffect(() => {
    const overHandler = ev => {
      const { x, y, height } = ev.currentTarget.getBoundingClientRect()
      const { width } = ev.currentTarget.closest('.js-scrollable-dashbord').getBoundingClientRect()
      setExtra({ x, y, width, height })
    }

    const outHandler = () => {
      setExtra(null)
    }

    document.addEventListener('scroll', outHandler)
    if (positionRef) {
      positionRef.addEventListener('mouseover', overHandler)
      positionRef.addEventListener('mouseout', outHandler)
    }
    return () => {
      document.removeEventListener('scroll', outHandler)
      if (positionRef) {
        positionRef.removeEventListener('mouseover', overHandler)
        positionRef.removeEventListener('mouseout', outHandler)
      }
    }
  }, [positionRef])

  const multiStageRowHeight = `${(stageInfo.length * 60 + 2) / 10}`

  const onOpenSetModal = e => {
    e.stopPropagation()
    showOrderKitModal(order.id, kitID)
  }

  if (!isVisible) return null

  return (
    <OutsideAlerter
      tr={true}
      onEvent={setInactive}
      setRef={setPositionRef}
      onClick={() => onPositionDetail(id, positionStageId)}
      style={stageInfo.length ? { minHeight: `${multiStageRowHeight}rem` } : {}}
      className={`items-stretch relative flex
				${isDragging ? 'translate-z' : ''}
				${isDragging ? 'opacity-0' : 'opacity-100'}
				${active ? ' border-Accent/Blue ' : 'border-Content/Border'}
				${isPast24Hours ? '!bg-[#ffb6c1]' : ''}
				hover:border-Accent/Blue position odd:bg-[#fff] even:bg-Dominant/Light flex  border mt-[-1px]`}>
      <Cell
        isPast24Hours={isPast24Hours}
        {...settings.Checkbox}
        checkbox={true}
        className='opacity-0'
        height={'h-[auto]'}>
        <Checkbox active={isSelected} onChange={onSelectPosition}></Checkbox>
      </Cell>
      {currentStage.id && !stage.service && currentStage.stampStage && (
        <Cell {...settings.user} height={'h-[auto]'}>
          <div
            className={`
							${displayedDrawings?.length > 1 ? 'py-[0.2rem]' : ''}
							grid
							flex-col
							gap-[1px]
							w-[100%]							
							h-[100%]`}>
            {displayedDrawings.map(({ user, subusers = [], id }, _, arr) => {
              return (
                <div key={id} className=' grid grid-cols-[1fr_3rem] gap-[1rem] items-center'>
                  <PositionUser
                    service={service}
                    stageId={stageId}
                    currentData={user}
                    multi={arr.length > 1}
                    onSetData={onSetPrintUser(id)}
                    isCurrentStage={isCurrentStage}></PositionUser>
                  <DrawingsUserInfo subusers={subusers}></DrawingsUserInfo>
                </div>
              )
            })}
          </div>
        </Cell>
      )}
      {currentStage.id && !stage.service && !currentStage.stampStage && (
        <Cell {...settings.user} height={'h-[auto]'}>
          {!isAllMod ? (
            <PositionUser
              service={service}
              stageId={stageId}
              currentData={user}
              onSetData={onSetUser}
              isCurrentStage={isCurrentStage}></PositionUser>
          ) : (
            <span className='text-Regular(16_18)'>{user?.username}</span>
          )}
        </Cell>
      )}
      <Cell {...settings.title} height={'h-[auto]'}>
        <span className='line-clamp-3'>{title}</span>
        <CopyToClipboard string={title}></CopyToClipboard>
      </Cell>
      <Cell {...settings.code} height={'h-[auto]'}>
        <div>
          <div className='flex items-end leading-1'>
            <div className={'flex flex-col items-start'}>
              {kitID && <ProductSetLabel kitID={kitID} onOpenSetModal={onOpenSetModal} />}
              <span className='text-Regular(14_16)'>{code}</span>
            </div>
            <CopyToClipboard string={code}></CopyToClipboard>
          </div>

          {comments?.length > 0 && <Comments id={id} comments={comments}></Comments>}
        </div>
      </Cell>
      {currentStage.stampStage ? (
        <Cell {...settings.quantity} padding={false} height={'h-[auto]'}>
          <div
            className={`
							${displayedDrawings?.length > 1 ? 'py-[0.2rem]' : ''}
							grid
							flex-col
							gap-[1px]
							w-[100%]							
							h-[100%]`}>
            {displayedDrawings.map(({ subusers = [], name, id }) => (
              <div className=' flex h-[100%] px-[1.2rem] py-[0.2rem] items-center text-Regular(12_14)'>
                <div className='flex gap-[0.5rem] items-center'>
                  <span> {quantity}</span>
                  <span>
                    (готово{' '}
                    <span className='text-Accent/Green'>
                      {subusers.reduce((acc, { readyCount }) => (acc += readyCount), 0) || 0}
                    </span>
                    )
                  </span>
                  <div
                    onClick={ev =>
                      onSetStampProgress(ev)({
                        ...props,
                        name,
                        drawingId: id
                      })
                    }
                    className='hover:cursor-pointer'>
                    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
                        stroke='#7BB2E7'
                        stroke-width='2'
                      />
                      <g clipPath='url(#clip0_1034_27789)'>
                        <circle cx='6.5' cy='6.5' r='1.25' stroke='#2F80ED' stroke-width='1.5' />
                        <path
                          d='M12.7515 11.4795C12.7515 12.1698 12.1918 12.7295 11.5015 12.7295C10.8111 12.7295 10.2515 12.1698 10.2515 11.4795C10.2515 10.7891 10.8111 10.2295 11.5015 10.2295C12.1918 10.2295 12.7515 10.7891 12.7515 11.4795Z'
                          stroke='#2F80ED'
                          stroke-width='1.5'
                        />
                        <line x1='12.5303' y1='5.53033' x2='5.53033' y2='12.5303' stroke='#2F80ED' stroke-width='1.5' />
                      </g>
                      <mask id='path-5-inside-1_1034_27789' fill='white'>
                        <path d='M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 3.60419e-07 9 0V1.97721C10.8626 1.97721 12.6488 2.71711 13.9659 4.03414C15.2829 5.35117 16.0228 7.13744 16.0228 9C16.0228 10.8626 15.2829 12.6488 13.9659 13.9659C12.6488 15.2829 10.8626 16.0228 9 16.0228V18Z' />
                      </mask>
                      <path
                        d='M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 3.60419e-07 9 0V1.97721C10.8626 1.97721 12.6488 2.71711 13.9659 4.03414C15.2829 5.35117 16.0228 7.13744 16.0228 9C16.0228 10.8626 15.2829 12.6488 13.9659 13.9659C12.6488 15.2829 10.8626 16.0228 9 16.0228V18Z'
                        fill='#D9D9D9'
                        stroke='#2F80ED'
                        stroke-width='4'
                        mask='url(#path-5-inside-1_1034_27789)'
                      />
                      <defs>
                        <clipPath id='clip0_1034_27789'>
                          <rect width='9' height='9' fill='white' transform='translate(4.5 4.5)' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Cell>
      ) : (
        <Cell {...settings.quantity} height={'h-[auto]'}>
          <div className='relative flex items-center gap-[4px]'>
            {quantity}
            {positionStock?.status === 'done' && (
              <div className='flex gap-[0.3rem] left-[100%] bottom-[100%]  text-Regular(14_16)'>
                <span className='text-[#6b6b6b]' title={'В наличии'}>
                  {' '}
                  ({positionStock.inStock}
                </span>
                /{' '}
                <span className='text-Accent/Green' title={'Доступно'}>
                  {positionStock.available})
                </span>
              </div>
            )}
          </div>
        </Cell>
      )}

      {/* <Cell {...settings.logo}>{order.logo}</Cell> */}
      <Cell {...settings.logo} height={'h-[auto]'}>
        <div className='grid gap-[0.4rem] h-[100%]'>
          {displayedDrawings.map(({ name, id, logo }, _, arr) => (
            <div key={id} className='flex items-center'>
              <span className={`${arr.length === 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                {logo}
              </span>
            </div>
          ))}
        </div>
      </Cell>
      <Cell {...settings.drawings} height={'h-[auto] py-[5px]'}>
        <div className='grid gap-[0.4rem] h-[100%]'>
          {displayedDrawings.map(({ name, id }, _, arr) => (
            <div
              data-tooltip-id={'cell-info'}
              data-tooltip-content={name}
              data-tooltip-place='top'
              key={id}
              className='flex items-center'>
              <span className={`${arr.length === 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                {name}
              </span>
            </div>
          ))}
        </div>
      </Cell>
      <Cell {...settings.stamps} height={'h-[auto]'}>
        <div className='grid gap-[0.4rem] h-[100%] w-[100%] justify-center'>
          {displayedDrawings.map(({ stamps, id }, _, arr) => (
            <div key={id} className='flex items-center '>
              <span className={`${arr.length === 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                {stamps || 1}
              </span>
            </div>
          ))}
        </div>
      </Cell>
      <Cell {...settings.manager} height={'h-[auto]'}>
        <span className='text-Regular(14_16)'>{order.manager} </span>
      </Cell>
      <Cell {...settings.order} className='relative' height={'h-[auto]'}>
        <span
          onClick={ev => {
            ev.stopPropagation()
            onOrderDetail(order.id)
          }}
          className='text-Accent/Blue text-Regular(16_18) border border-l-0 border-r-0 border-t-0 border-Accent/Blue hover:cursor-pointer hover:border-transparent'>
          {order.orderId}
        </span>
        <CopyToClipboard string={order.orderId}></CopyToClipboard>
        {(!!order.localComment?.comment || order.managerComment) && (
          <Comments id={id} comments={[order.localComment || { comment: order.managerComment }]}></Comments>
        )}
      </Cell>
      <Cell {...settings.company} height={'h-[auto]'}>
        <span className='text-Regular(14_16)'>{order.company}</span>
        <CopyToClipboard string={order.company}></CopyToClipboard>
      </Cell>
      <Cell {...settings.date} height={'h-[auto]'}>
        <span className='text-Regular(16_18)'>{date.transform(order.date, false)}</span>
      </Cell>
      <Cell {...settings.dateDashbord} height={'h-[auto]'} className={isPast24Hours ? 'relative' : ''}>
        {isPast24Hours && (
          <div className='absolute top-[2%] left-[50%] translate-x-[-50%] whitespace-nowrap'>
            <span className={`text-Regular(14_16) text-Accent/Blue`}>
              Более 24 часов <i className={`text-Regular(14_16) icon-watch text-Accent/Blue`}></i>
            </span>
          </div>
        )}
        <div className='flex flex-col gap-[0.2rem] items-center m-auto'>
          <span className='text-Regular(16_18)'>{date.transform(dashbordDate || createdAt).split('в')[0]}</span>
          <span className='text-Regular(12_14) opacity-70'>
            в {date.transform(dashbordDate || createdAt).split('в')[1]}
          </span>
        </div>
      </Cell>
      <Cell {...settings.dateReady} className='relative' height={'h-[auto]'}>
        {readyDate && (
          <div className='flex flex-col gap-[0.2rem] items-center m-auto'>
            <span className='text-Regular(16_18)'>{date.transform(readyDate).split('в')[0]}</span>
            <span className='text-Regular(12_14) opacity-70'>в {date.transform(readyDate).split('в')[1]}</span>
          </div>
        )}

        <div onClick={ev => ev.stopPropagation()}>
          <div className='absolute top-0 right-0 flex gap-[4px] items-start'>
            {order.dontDelay && <span className='text-Regular(14_16) text-[#FF7F50]'>Не переносить</span>}
            <ReadyTimePicker
              dontDelay={order.dontDelay}
              className={isPast24Hours ? '!bg-[#ffb6c1]' : ''}
              tooltip='Время готовности позиции'
              small={true}
              positionId={id}
              callback={() => getData(true)}></ReadyTimePicker>
          </div>
        </div>
      </Cell>
      {!isAllMod ? (
        <Cell {...settings.status} className='absolute right-[5rem] top-0 bottom-0' height={'h-[auto]'}>
          {currentStage.stampStage && !blocked ? (
            <div
              className={`
							${displayedDrawings?.length > 1 ? 'py-[0.2rem]' : ''}
							grid
							flex-col
							gap-[1px]
							w-[100%]							
							h-[100%]`}>
              {displayedDrawings.map(({ status: drawingStatus, id: drawingId }, _, arr) => {
                let currentStatus

                if (drawingStatus) {
                  currentStatus = drawingStatus
                } else {
                  currentStatus = status
                }

                return (
                  <div key={drawingId} className=' flex'>
                    <PositionStatus
                      positionId={id}
                      user={user}
                      service={service}
                      blocked={blocked}
                      stageId={stageId}
                      isCurrentStage={isCurrentStage}
                      currentData={currentStatus}
                      onSetData={onSetPrintStatus(drawingId)}
                      setIsVisible={setIsVisible}
                      timestamps={timestamps}
                      multi={arr.length > 1}
                      getData={getData}
                      stampSpecialId={stampSpecialId}
                      positionStageId={positionStageId}
                      debouncedGetData={debouncedGetData}></PositionStatus>
                  </div>
                )
              })}
            </div>
          ) : (
            <PositionStatus
              positionId={id}
              user={user}
              service={service}
              blocked={blocked}
              stageId={stageId}
              isCurrentStage={isCurrentStage}
              currentData={status}
              onSetData={onSetStatus}
              setIsVisible={setIsVisible}
              timestamps={timestamps}
              getData={getData}
              debouncedGetData={debouncedGetData}
              stampSpecialId={stampSpecialId}
              positionStageId={positionStageId}></PositionStatus>
          )}
        </Cell>
      ) : (
        <Cell
          {...settings.stage}
          className='absolute right-[5.3rem] top-0 bottom-0'
          title='Этап'
          padding={false}
          height={isAllMod ? 'h-[auto]' : 'h-[auto]'}>
          {isAllMod ? (
            <div className={`w-[100%] h-[100%] grid`}>
              {stageInfo.length &&
                stageInfo.map(el => (
                  <div className='text-Regular(14_16) p-[0.6rem] w-[100%]' style={{ backgroundColor: el.stage.color }}>
                    <div className='flex flex-col'>
                      <span>{el.stage.title}</span>
                      <span className='text-[1.4rem]'>Статус: {el.status.title}</span>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className='h-[100%] text-Regular(16_18) p-[1.2rem] w-[100%]' style={{ backgroundColor: stageColor }}>
              <div className='flex flex-col'>
                <span>{stageName}</span>
                <span className='text-[12px]'>Статус: {status.title}</span>
              </div>
            </div>
          )}
        </Cell>
      )}
      <Cell
        {...settings.Checkbox}
        checkbox={true}
        onClick={ev => ev.stopPropagation()}
        className={`
				${isUrgent ? 'after:absolute after:top-[0] after:left-[100%] after:bottom-0 after:w-[0.4rem] after:bg-Accent/Red' : ''}
				${isPast24Hours ? '!bg-[#ffb6c1]' : ''}
				absolute bg-white left-[2px] top-[50%] translate-y-[-50%] text-left`}>
        <Checkbox active={isSelected} onChange={onSelectPosition}></Checkbox>
      </Cell>
      <Cell className='absolute bg-white right-[2px] top-[50%] translate-y-[-50%]  w-[4.8rem]' width={'4.8'}>
        <div
          onClick={() => onPositionDetail(id)}
          className='hover:cursor-pointer w-[20px] h-[20px] flex justify-center items-center'>
          <i className='duration-300 ease-in-out hover:text-Accent/Blue icon-drag text-Regular(24_30) text-Content/Light'></i>
        </div>
      </Cell>
      <Cell className='pointer-events-none right-0   w-[4.8rem] '></Cell>
      <td
        onClick={ev => ev.stopPropagation()}
        style={{
          height: extra?.height + 'px',
          right: (window.innerWidth - extra?.width) / 2 + 'px',
          top: extra?.y + 'px'
        }}
        className={`
						${extra ? 'visible opacity-100 translate-x-[100%]' : 'invisible opacity-0 translate-x-[0%]'}
						border   border-Accent/Blue   top-0 fixed z-20  w-[6rem] flex items-center justify-center
					`}>
        <div
          onClick={() => setActionsPanelOpen(!actionsPanelOpen)}
          className='flex bg-white group hover:cursor-pointer p-[0.8rem]'>
          <i className='icon-list group-hover:text-Accent/Blue text-Regular(18_24) text-Content/Light'></i>
        </div>
        <ul
          className={`
						${actionsPanelOpen ? 'visible opacity-100' : 'invisible opacity-100 d-none'}
							before:absolute
							before:left-0
							before:right-0
							before:bottom-[100%]
							before:h-[10px]
							absolute z-30 top-[calc(100%+4px)] right-0 shadow-default bg-white`}>
          <li
            onClick={onSetCommentHandler}
            className='px-[1.2rem] py-[0.9rem] ease-in-out duration-300 hover:bg-Dominant/Dop hover:cursor-pointer flex items-center gap-[0.8rem]'>
            <i className='icon-comment text-Accent/Blue text-Regular(18_24)'></i>
            <span className='text-Regular(16_18)'>Комментарий</span>
          </li>
          <li
            onClick={onSetStickerHandler}
            className='px-[1.2rem] py-[0.9rem] ease-in-out duration-300 hover:bg-Dominant/Dop hover:cursor-pointer flex items-center gap-[0.8rem]'>
            <i className='icon-stik text-Accent/Lilac text-Regular(18_24)'></i>
            <span className='text-Regular(16_18)'>Стикеровка</span>
          </li>
        </ul>
      </td>
    </OutsideAlerter>
  )
}

export default observer(Position)
