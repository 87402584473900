import React from 'react'
import { date } from '../../../utils/date'
import ReadyTimePicker from '../../readyTimePicker/readyTimePicker'
import Checkbox from '../../ui/checkbox/checkbox'

export const PositionHeader = ({ currenStage, orderId, company, data, getData, onUrgent, onOrderDetail, id }) => {
  return (
    <div className='py-[3.6rem] px-[8rem] bg-Accent/Light_Yellow'>
      <div className='grid grid-cols-[1fr_1fr_2fr_1fr] gap-[2.6rem] mb-[2.4rem]'>
        <div className='flex gap-[0.5rem]'>
          <span className='block text-Regular(16_20)'>Статус позиции:</span>
          <span className='block text-Regular(16_20) font-bold'>{currenStage?.title}</span>
        </div>
        <div className='flex flex-col gap-[1.2rem]'>
          <div className='flex gap-[0.5rem]'>
            <span className='block text-Regular(16_20)'>Заказ:</span>
            <span
              onClick={() => onOrderDetail(id)}
              className='block text-Regular(16_20) font-bold text-Accent/Blue border border-l-0 border-r-0 border-t-0 border-Accent/Blue hover:cursor-pointer hover:border-transparent '>
              №{orderId}
            </span>
          </div>
          <div className='flex gap-[0.5rem]'>
            <span className='block text-Regular(16_20)'>Заказчик:</span>
            <span className='block text-Regular(16_20) font-bold'>{company}</span>
          </div>
        </div>
        <div className='flex flex-col gap-[1.2rem]'>
          <div className='flex gap-[0.5rem]'>
            <span className='block text-Regular(16_20)'>Время выполнения:</span>
            <span className='block text-Regular(16_20) font-bold '>-</span>
          </div>
          <div className='flex gap-[0.5rem] relative '>
            <span className='block text-Regular(16_20)'>Дата готовности:</span>
            <span className='block text-Regular(16_20) font-bold '>{date.transform(data.readyDate) || '-'}</span>
            <ReadyTimePicker small={true} positionId={data.id} callback={() => getData(true)}></ReadyTimePicker>
          </div>
        </div>
        {/* <div className="flex flex-col gap-[1.2rem]">
                <div className="flex gap-[0.5rem]">
                  <span className="block text-Regular(16_20)">
                    Проблемная позиция:
                  </span>
                  <span className="block text-Regular(16_20) font-bold ">
                    -
                  </span>
                </div>
              </div> */}
        <div className='flex flex-col gap-[1.2rem]'>
          <Checkbox
            onChange={onUrgent}
            label='Срочная позиция'
            padding={false}
            className='text-Accent/Red font-medium'
            active={data.isUrgent}></Checkbox>
        </div>
      </div>
    </div>
  )
}
