import React, { useState } from 'react'
import ButtonGrey from '../ui/buttonGrey/buttonGrey'
import { NewPositionForm } from '../newPositionForm/newPositionForm'
import usePosition from '../../hooks/position.hook'
import Loader from '../loader/loader'

const initData = {
  code: '',
  title: '',
  quantity: 1,
  drawings: []
}

export const CreateNewPosition = ({ orderIdDB, getData, getDashboardData }) => {
  const { createPosition } = usePosition()
  const [addPositionMode, setAddPositionMode] = useState(false)
  const [newPositionData, setNewPositionData] = useState(initData)
  const [isLoading, setIsLoading] = useState(false)

  const onRemoveEditMode = () => {
    setAddPositionMode(false)
    setNewPositionData(initData)
  }

  const onSaveAction = async () => {
    try {
      setIsLoading(true)
      await createPosition({ ...newPositionData, orderId: orderIdDB })
      onRemoveEditMode()
      await Promise.all([getData(), getDashboardData(true)])
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      {isLoading && (
        <div className={`absolute inset-0 z-[10] bg-[#000000] opacity-[0.4]`}>
          <Loader />
        </div>
      )}
      {addPositionMode && <NewPositionForm newPositionData={newPositionData} setNewPositionData={setNewPositionData} />}
      {!addPositionMode ? (
        <ButtonGrey onPress={() => setAddPositionMode(true)} name='Добавить позицию'></ButtonGrey>
      ) : (
        <div className={`flex gap-[1rem]`}>
          <ButtonGrey onPress={onRemoveEditMode} name='Отмена'></ButtonGrey>
          <ButtonGrey disabled={!newPositionData.title} onPress={onSaveAction} name={'Сохранить'} success></ButtonGrey>
        </div>
      )}
    </div>
  )
}
