import { makeAutoObservable } from 'mobx'

class Users {
  users = []
  currentUser = {}

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentUser(user) {
    this.currentUser = user
  }

  setUsers(users) {
    this.users = users
  }

  isGodMod() {
    return this.currentUser?.role?.find(x => x.godmod)
  }
}

const users = new Users()

export default users
