import ReactModal from 'react-modal'
import './style.css'

function Modal({ isOpen, title, children, closeModal }) {
  const setOverflow = ref => {
    setTimeout(() => {
      // ref && (ref.style.overflow = 'auto')
    }, 400)
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      overlayRef={setOverflow}
      onRequestClose={closeModal}
      style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          backgroundColor: 'rgba(	114, 112, 112, 0.6)',
          zIndex: 999,
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        },
        content: {
          minHeight: 'calc(100% - 3.5rem)',
          display: 'flex',
          alignItems: 'center',
          position: 'static',
          padding: '0px',
          border: '0px',
          background: 'transparent',
          overflow: 'visible',
          margin: '1.75rem auto'
        }
      }}>
      <div onClick={ev => ev.stopPropagation()} className='ReactModal__Content  bg-white w-[100%]  relative'>
        <span
          onClick={closeModal}
          className='w-[4rem] h-[4rem] absolute right-[1.8rem] top-[1.8rem] flex items-center justify-center hover:cursor-pointer hover:rotate-180 ease-in-out duration-300 hover:text-Accent/Blue'>
          <i className='icon-close text-Regular(38_42) flex items-center justify-center'></i>
        </span>

        {children}
      </div>
    </ReactModal>
  )
}

export default Modal
