import React, { useEffect, useState } from 'react'
import Input from '../ui/input/input'
import { DrawingsControl } from '../drawingsControl/DrawingsControl'

export const NewPositionForm = ({ setNewPositionData, newPositionData }) => {
  const onCommonDataChange = (e, type) => {
    setNewPositionData({ ...newPositionData, [type]: e.target.value })
  }

  const onDrawingAdditionsChange = (e, type, id) => {
    const updatedEditableData = {
      ...newPositionData,
      drawings: newPositionData.drawings.map(el => (el.id === id ? { ...el, [type]: e.target.value } : el))
    }
    setNewPositionData(updatedEditableData)
  }

  return (
    newPositionData && (
      <div className='border border-Content/Border border-t-0 border-r-0 border-b-0 w-[100%] mb-[30px]'>
        <div className='grid grid-table-row__v3'>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
            Артикул
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
            Название
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
            Количество
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
            Метод печати
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
            Логотип
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
            Кол-во цветов
          </div>
        </div>
        <div className='text-Regular(16_18) grid grid-table-row__v3'>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] break-all border border-Content/Border flex flex-col justify-center'>
            <div>
              {/*артикул*/}
              <Input border={true} initial={newPositionData.code} onInput={e => onCommonDataChange(e, 'code')}></Input>
            </div>
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border flex flex-col justify-center'>
            <div>
              {/*название*/}
              <Input
                border={true}
                initial={newPositionData.title}
                onInput={e => onCommonDataChange(e, 'title')}></Input>
            </div>
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border border-t-[0] items-center text-center flex justify-center'>
            <div>
              {/*количество*/}
              <Input
                type={'number'}
                border={true}
                initial={newPositionData.quantity}
                onInput={e => onCommonDataChange(e, 'quantity')}></Input>
            </div>
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border '>
            <div className='flex flex-col h-[100%] gap-[0.4rem]'>
              <DrawingsControl editableData={newPositionData} setEditableData={setNewPositionData} />
            </div>
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border'>
            {/*логотип*/}
            <div className={`flex flex-col gap-[8px]`}>
              {!newPositionData.drawings.length ? (
                <Input small disabled border={true} initial={'-'}></Input>
              ) : (
                newPositionData.drawings.map(({ logo, id }) => (
                  <Input
                    key={id}
                    small
                    border={true}
                    onInput={e => onDrawingAdditionsChange(e, 'logo', id)}
                    initial={logo || '-'}></Input>
                ))
              )}
            </div>
          </div>
          <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border '>
            {/*колво цветов*/}
            <div className={`flex flex-col gap-[8px]`}>
              {!newPositionData.drawings.length ? (
                <Input small disabled border={true} initial={'-'}></Input>
              ) : (
                newPositionData.drawings.map(({ stamps, id }) => (
                  <Input
                    key={id}
                    small
                    border={true}
                    onInput={e => onDrawingAdditionsChange(e, 'stamps', id)}
                    initial={stamps || '1'}></Input>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}
