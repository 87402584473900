import { useRef, useState, useLayoutEffect } from 'react'

function Dropdown({ active, data, children, style, positon = 'top-[100%] absolute', minWidth }) {
  const ref = useRef()
  const [state, setState] = useState({})

  useLayoutEffect(() => {
    const { height, y } = ref.current.getBoundingClientRect()
    const container = ref.current.parentElement.parentElement?.getBoundingClientRect()
    if (y + height > window.innerHeight) {
      setState({
        height,
        offset: container?.height
      })
    } else {
      setState({})
    }
  }, [active])

  return (
    <div
      style={
        style
          ? style
          : {
              // marginTop: state.height ? (state.height + (state.offset || 0))*-1 + 'px' : null
              marginTop: state.height ? (state.height + (state.offset || 0)) * -1 + 'px' : null
            }
      }
      ref={ref}
      className={`
			${positon}
      
			${active ? '' : 'd-none invisible opacity-0 '}
			shadow-default  left-0 right-0  z-10  bg-white   max-h-[33.5rem] overflow-auto
			${minWidth || ''}
			`}>
      {data?.length ? data.map(children) : <span></span>}
    </div>
  )
}

export default Dropdown
