import PositionForPrint from '../position/positionForPrint'
import dashbordStore from '../../store/dashbord'
import { observer } from 'mobx-react-lite'
const PrintHeader = () => {
  return (
    <tr className='flex bg-white'>
      <th
        style={{
          width: '25rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Название
      </th>
      <th
        style={{
          width: '15rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Артикул
      </th>
      <th
        style={{
          width: '10rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Количество
      </th>
      <th
        style={{
          width: '15rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Номер заказа
      </th>
      <th
        style={{
          width: '15rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Заказчик
      </th>
      <th
        style={{
          width: '15rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Менеджер
      </th>
      <th
        style={{
          width: '15rem'
        }}
        className={`h-[6rem] p-[1.2rem] flex items-center  overflow-hidden`}>
        Дата заказа
      </th>
    </tr>
  )
}

function PrintPositions({ items, ...props }) {
  return (
    <div className='positions-to-print hidden opacity-0'>
      {Array.isArray(items[0]) ? (
        items?.map(([id, { items: item, deliveryinfo }]) => {
          return (
            <table className='product-table '>
              <tbody>
                {!!item.filter(({ id }) => dashbordStore.getIsPositionsSelected(id))?.length && (
                  <PrintHeader></PrintHeader>
                )}

                {item.map(
                  (
                    {
                      isUrgent,
                      blocked,
                      comments,
                      stage,
                      position,
                      user,
                      id,
                      status: currentStatus,
                      isCurrentStage,
                      stageChangeTimeStamps
                    },
                    index
                  ) => {
                    const isSelected = dashbordStore.getIsPositionsSelected(id)
                    if (!isSelected) return null
                    return (
                      <PositionForPrint
                        {...position}
                        key={id}
                        index={index}
                        isUrgent={isUrgent}
                        positionStageId={id}
                        timestamps={stageChangeTimeStamps}
                        user={user}
                        status={currentStatus}
                        comments={comments}
                        stage={stage}
                        isCurrentStage={isCurrentStage}
                        blocked={blocked}
                        {...props}></PositionForPrint>
                    )
                  }
                )}
              </tbody>
            </table>
          )
        })
      ) : (
        <table className='product-table'>
          <tbody>
            {!!items.filter(({ id }) => dashbordStore.getIsPositionsSelected(id))?.length && (
              <PrintHeader></PrintHeader>
            )}

            {items.map(
              (
                {
                  isUrgent,
                  blocked,
                  comments,
                  stage,
                  position,
                  user,
                  id,
                  status: currentStatus,
                  isCurrentStage,
                  stageChangeTimeStamps
                },
                index
              ) => {
                const isSelected = dashbordStore.getIsPositionsSelected(id)

                if (!isSelected) return null

                return (
                  <PositionForPrint
                    {...position}
                    key={id}
                    index={index}
                    isUrgent={isUrgent}
                    positionStageId={id}
                    timestamps={stageChangeTimeStamps}
                    user={user}
                    status={currentStatus}
                    comments={comments}
                    stage={stage}
                    isCurrentStage={isCurrentStage}
                    blocked={blocked}
                    {...props}></PositionForPrint>
                )
              }
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default observer(PrintPositions)
