import OutsideAlerter from '../outsideAlerter/outsideAlerter'
import Dropdown from '../ui/dropdown/dropdown'
import { useEffect, useState, useRef, useCallback } from 'react'
import usersStore from '../../store/users'
import Modal from '../modal/modal-mobile'
import DefaultModal from '../modals/defaultModal/defaultModal-mobile'
import { emmiter } from '../../helpers/Emmiter'
import dashbordStore from '../../store/dashbord'

function CellPickerHOC(renderItem, Wrapped, modalTitle) {
  return function CellPicker({
    getData,
    currentData,
    data,
    stageId,
    isCurrentStage,
    onSetData,
    blocked,
    small,
    debouncedGetData,
    positionStageId,
    ...props
  }) {
    const dropdownRef = useRef(null)
    const [isDropDown, setIsDropDown] = useState(false)
    const [current, setCurrent] = useState(currentData)
    const [isDisabled, setIsDisabled] = useState(true)
    const {
      currentUser: { role }
    } = usersStore
    const isRoleFitStage = role.find(x => x.godmod) || role.find(x => x.stage?.id === stageId)
    const { selectedPositionsStages } = dashbordStore

    const onSetGroupStatus = useCallback(
      args => {
        const selectedPositionsIDs = selectedPositionsStages.map(el => el.id)
        if (selectedPositionsIDs.includes(positionStageId)) {
          debouncedGetData()
          setCurrent(args)
        }
      },
      [selectedPositionsStages, debouncedGetData]
    )

    useEffect(() => {
      emmiter.on('setGroupStatus', onSetGroupStatus)
      return () => emmiter.off('setGroupStatus')
    }, [onSetGroupStatus])

    useEffect(() => {
      let cause = false

      if (!isRoleFitStage) {
        cause = 'Недостаточная роль'
      }

      if (!isCurrentStage) {
        cause = 'Невозможно внести изменения вне текущего этапа'
      }

      if (current?.additionalWorkTrigger && blocked) {
        cause = 'Позиция заблокирована на допработах'
      }

      setIsDisabled(cause)
    }, [
      isCurrentStage,
      isRoleFitStage,
      blocked
      // current
    ])

    //не показываем выбранный элемент в дропдауне TODO
    const filteredData = data || data.filter(x => x.id !== currentData?.id)

    useEffect(() => {
      setCurrent(currentData)
    }, [currentData])

    useEffect(() => {
      const scrollableDashbord = dropdownRef.current.closest('.js-scrollable-dashbord')
      const handler = () => {
        if (isDropDown) {
          setIsDropDown(false)
        }
      }
      scrollableDashbord?.addEventListener('scroll', handler)
      document.addEventListener('scroll', handler)
      return () => {
        scrollableDashbord?.removeEventListener('scroll', handler)
      }
    }, [isDropDown])

    const openDropdown = ev => {
      if (isDropDown) {
        return setIsDropDown(false)
      }

      const dropdownHeight = dropdownRef.current.getBoundingClientRect().height
      let { x, y, height, width } = ev.currentTarget.getBoundingClientRect()
      const modal = ev.currentTarget.closest('.ReactModal__Content')

      let top

      if (window.innerHeight - (y + height) > dropdownHeight) {
        top = y + height
      } else {
        top = y - dropdownHeight
      }

      if (!filteredData.length) return

      if (modal) {
        const modalBox = modal.getBoundingClientRect()
        x -= modalBox.x
        top -= modalBox.y
      }

      setIsDropDown({
        left: x,
        top,
        width
      })
    }

    const closeDropdown = useCallback(() => {
      setIsDropDown(false)
    }, [])

    const onChooseHanlder = async user => {
      setIsDropDown(false)
      setCurrent(user)
      onSetData(user.id)
    }

    const renderItemWithHandler = renderItem(onChooseHanlder, {
      ...props,
      getData
    })

    return (
      <div
        data-tooltip-hidden={!isDisabled}
        data-tooltip-id={'cell-click-alert'}
        data-tooltip-content={isDisabled}
        data-tooltip-place='top'
        data-tooltip-delay-hide={1000}
        className={`
					w-[100%] flex self-stretch
				`}>
        <div
          onClick={openDropdown}
          className={`
						${isDisabled ? 'pointer-events-none' : 'hover:cursor-pointer'} 
						${isDropDown ? 'shadow-default' : ''}
						${small ? 'text-Regular(12_14) ' : 'text-Regular(16_18)'}
						relative 
						w-[100%]
					`}>
          <Wrapped
            current={current}
            isDisabled={isDisabled}
            isDropDown={isDropDown}
            small={small}
            debouncedGetData={debouncedGetData}
            blocked={blocked}
            {...props}></Wrapped>
          <div
            ref={dropdownRef}
            className={`
							${isDropDown ? 'visible' : 'invisible pointer-events-none  left-0 top-0'}
							fixed z-30 min-w-[200px]
						`}
            style={{
              left: isDropDown.left,
              top: isDropDown.top,
              width: isDropDown.width
            }}></div>
        </div>
        <Modal
          closeModal={() => setIsDropDown(false)}
          isOpen={isDropDown}
          title={modalTitle}
          style={{
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              backgroundColor: 'rgba(	114, 112, 112, 0.6)',
              zIndex: 990,
              top: '0',
              left: '0',
              width: '100%',
              height: '100%'
            }
          }}>
          <DefaultModal>
            <div className='flex flex-col gap-[6px]'>{filteredData.map(renderItemWithHandler)}</div>
          </DefaultModal>
        </Modal>
      </div>
    )
  }
}

export default CellPickerHOC
