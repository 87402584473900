import ReactModal from 'react-modal'
import './style.css'

function BottomSheet({ isOpen, title, children, closeModal }) {
  const setOverflow = ref => {
    setTimeout(() => {
      // ref && (ref.style.overflow = 'auto')
    }, 400)
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      overlayRef={setOverflow}
      onRequestClose={closeModal}
      style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          backgroundColor: 'rgba(	114, 112, 112, 0.6)',
          zIndex: 999,
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        },
        content: {
          minHeight: 'calc(100% - 3.5rem)',
          display: 'flex',
          alignItems: 'flex-end',
          position: 'static',
          padding: '0px',
          border: '0px',
          background: 'transparent',
          overflow: 'visible',
          margin: '37px 0 0 0',
          width: '100%'
        }
      }}>
      <div
        onClick={ev => ev.stopPropagation()}
        className='ReactModal__Content shadow-default px-[18px]  bg-white w-[100%]  relative rounded-t-[20px]'>
        <div className='flex justify-between py-[10px]  items-center '>
          <span className='text-Regular(18_24) text-Content/Middle'>{title}</span>
          <span onClick={closeModal} className='ml-auto'>
            <i className='translate-x-6 icon-close text-Regular(38_42) text-Content/Middle flex justify-center items-center'></i>
          </span>
        </div>
        {children}
      </div>
    </ReactModal>
  )
}

export default BottomSheet
