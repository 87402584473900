import CellPickerHOC from '../cellPickerHOC/cellPickerHOC'
import usersStore from '../../store/users'
import stageStore from '../../store/stage'

const Cell = ({ current, currentData, small, multi }) => {
  return current ? (
    <div
      className={`

			${multi ? 'px-[1.2rem] py-[0.2rem] text-Regular(12_14)' : small ? 'px-[1.2rem] py-[0.2rem]' : 'px-[1.2rem] py-[0.9rem]'}
			 h-[100%] flex items-center`}>
      {current?.username || currentData.username}
    </div>
  ) : (
    <div
      className={`
		${multi ? 'px-[1.2rem] py-[0.2rem] text-Regular(12_14)' : small ? 'px-[1.2rem] py-[0.2rem]' : 'px-[1.2rem] py-[0.9rem]'}
		text-Accent/Red font-medium  h-[100%] flex items-center`}>
      Выбрать
    </div>
  )
}

const renderItem = onClickHandler => item => {
  return (
    <span
      onClick={ev => {
        ev.stopPropagation()
        onClickHandler(item)
      }}
      key={item.id}
      className='hover:opacity-60 ease-in-out duration-300 block px-[1.2rem] py-[1.2rem]'>
      {item.username}
    </span>
  )
}

const UserCellPicker = CellPickerHOC(renderItem, Cell)

function PositionUser({ onSetUserHandler, ...props }) {
  if (!props.data) {
    const role = stageStore.stages.find(x => x.id === props.stageId)?.m_role
    const users = usersStore.users.filter(x => x.m_roles.find(y => y.id === role?.id))
    props.data = users
  }

  if (props.service) {
    return null
  }

  return <UserCellPicker {...props}></UserCellPicker>
}

export default PositionUser
