import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { date } from '../../utils/date'
import PositionStatus from '../positionStatus/positionStatus-mobile'
import usePosition from '../../hooks/position.hook'
import PositionUser from '../positionUser/positionUser-mobile'
import Checkbox from '../ui/checkbox/checkbox-mobile'
import ReadyTimePicker from '../readyTimePicker/readyTimePicker'
import dashbordStore from '../../store/dashbord'
import OutsideAlerter from '../outsideAlerter/outsideAlerter'
import Comments from '../comments/comments-mobile'
import usersStore from '../../store/users'
import stageStore from '../../store/stage'
import { emmiter } from '../../helpers/Emmiter'

import './style.css'
import ProductSetLabel from '../productSetLabel/ProductSetLabel'

function Position(props) {
  const {
    id,
    settings,
    title,
    onOrderDetail,
    onPositionDetail,
    user,
    isAllMod,
    quantity,
    positionStageId,
    quantityUnderOrder,
    quantityInStock,
    status,
    timestamps,
    order,
    setComment,
    setSticker,
    comments,
    stage,
    readyDate,
    dashbordDate,
    createdAt,
    isCurrentStage,
    blocked,
    drawings,
    index,
    code,
    getData,
    stampSpecialId,
    debouncedGetData,
    groupView,
    selectedPositionsOrderId,
    isUrgent,
    stageInfo = [],
    positionStock,
    showOrderKitModal,
    kitID
  } = props
  const [positionRef, setPositionRef] = useState(null)
  const { setUser, setStatus, setPrintStatus, setPrintUser } = usePosition()
  const [isVisible, setIsVisible] = useState(true)
  const [actionsPanelOpen, setActionsPanelOpen] = useState(false)
  const isSelected = dashbordStore.getIsPositionsSelected(positionStageId)
  const [active, setIsActive] = useState(false)
  const [extra, setExtra] = useState(false)
  const {
    currentUser: { role }
  } = usersStore
  const [folded, setFolded] = useState(false)
  const { currentStage } = stageStore
  const { sort } = dashbordStore
  const { id: stageId, service, title: stageName, color: stageColor, step: stageStep } = stage
  const isRoleFitStage = role.find(x => x.godmod) || role.find(x => x.stage?.id === stageId)

  const isPast24Hours = stageStep === 3 || stageStep === 2 ? date.isPast24Hours(dashbordDate || createdAt) : false

  const setInactive = useCallback(() => {
    setIsActive(false)
  }, [])

  const onSetUser = async userId => {
    await setUser(positionStageId, userId)
    // await getData(true)
    dashbordStore.clearSelected()
  }

  const onSetStatus = async statusId => {
    await setStatus(positionStageId, statusId)
    dashbordStore.clearSelected()
    debouncedGetData()
  }

  const onSetPrintStatus = drawingId => async statusId => {
    await setPrintStatus(positionStageId, statusId, drawingId)
    dashbordStore.clearSelected()
    await getData(true)
  }

  const onSetPrintUser = drawingId => async userId => {
    await setPrintUser(positionStageId, userId, drawingId)
  }

  const onSetStampProgress = ev => {
    ev.stopPropagation()
    return data => {
      emmiter.emmit('stampProgressModal', data)
    }
  }

  const onSelectPosition = () => {
    if (groupView && selectedPositionsOrderId !== order.id) {
      dashbordStore.clearSelected()
    }
    if (groupView) {
      dashbordStore.addPosition({
        id: positionStageId,
        position_id: id,
        drawings: drawings,
        status: status,
        selectedItemsOrderId: order.id,
        title,
        code,
        blocked
      })
    } else {
      dashbordStore.addPosition({
        id: positionStageId,
        drawings: drawings,
        position_id: id,
        status: status,
        title,
        code,
        blocked
      })
    }
    // dashbordStore.addPosition(
    // 	{
    // 		id: positionStageId,
    // 		position_id: id
    // 	}
    // )
  }

  const onSetCommentHandler = () => {
    setComment({
      id,
      stageId
    })
  }

  const onSetStickerHandler = () => {
    setSticker({
      title,
      quantity,
      company: order.company,
      code
    })
  }

  useEffect(() => {
    !extra && setActionsPanelOpen(false)
  }, [extra])

  useEffect(() => {
    const overHandler = ev => {
      const { x, y, height } = ev.currentTarget.getBoundingClientRect()
      const { width } = ev.currentTarget.closest('.js-scrollable-dashbord').getBoundingClientRect()
      setExtra({ x, y, width, height })
    }

    const outHandler = () => {
      setExtra(null)
    }

    document.addEventListener('scroll', outHandler)
    if (positionRef) {
      positionRef.addEventListener('mouseover', overHandler)
      positionRef.addEventListener('mouseout', outHandler)
    }
    return () => {
      document.removeEventListener('scroll', outHandler)
      if (positionRef) {
        positionRef.removeEventListener('mouseover', overHandler)
        positionRef.removeEventListener('mouseout', outHandler)
      }
    }
  }, [positionRef])

  const onOpenSetModal = e => {
    e.stopPropagation()
    showOrderKitModal(order.id, kitID)
  }

  if (!isVisible) return null

  return (
    <OutsideAlerter onEvent={setInactive} setRef={setPositionRef}>
      <div className={`flex flex-col bg-[#fff] mb-[24px] ${isPast24Hours ? '!bg-[#ffb6c1]' : ''}`}>
        <div
          className={`flex p-[12px]  border border-Content/Border border-l-0 border-r-0 border-t-0 relative ${
            isUrgent
              ? `after:absolute after:top-[0] 
					after:right-[100%] after:translate-x-[100%] after:bottom-0 after:w-[0.4rem] after:bg-Accent/Red`
              : ''
          } `}>
          <div className={`grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] `}>
            <div className='flex gap-[18px]'>
              <Checkbox active={isSelected} onChange={onSelectPosition}></Checkbox>
              {settings.code.available && (
                <div className='flex flex-col justify-center'>
                  <span className='text-Regular(14_16) text-Content/Middle'>Артикул</span>
                  <span className='text-Regular(16_18) break-all'>{code}</span>
                </div>
              )}
            </div>
            {settings.status.available && !isAllMod ? (
              currentStage.stampStage ? (
                <div
                  className={`
							${drawings?.length > 1 ? 'py-[0.2rem]' : ''}
							grid
							flex-col
							gap-[1px]
							w-[100%]							
							h-[100%]`}></div>
              ) : (
                <div>
                  <PositionStatus
                    positionId={id}
                    user={user}
                    service={service}
                    blocked={blocked}
                    stageId={stageId}
                    isCurrentStage={isCurrentStage}
                    currentData={status}
                    onSetData={onSetStatus}
                    setIsVisible={setIsVisible}
                    timestamps={timestamps}
                    getData={getData}
                    debouncedGetData={debouncedGetData}
                    stampSpecialId={stampSpecialId}
                    positionStageId={positionStageId}></PositionStatus>
                </div>
              )
            ) : (
              <div className='h-[100%] flex flex-col text-Regular(16_18) w-[100%]'>
                {stageInfo.map(el => (
                  <div className={`p-[1.2rem]`} style={{ backgroundColor: el.stage.color }}>
                    <span>{el.stage.title}</span>
                  </div>
                ))}
              </div>
            )}
            <div className='flex items-center gap-[10px] justify-end'>
              {!!comments?.length && <Comments id={id} comments={comments}></Comments>}
            </div>
          </div>
        </div>
        {drawings && currentStage.stampStage && (
          <div className=' p-[12px]  border border-Content/Border border-l-0 border-r-0 border-t-0'>
            {drawings.map(({ status: drawingStatus, user, subusers = [], name, id }, _, arr) => {
              let currentStatus
              if (drawingStatus) {
                currentStatus = drawingStatus
              } else {
                currentStatus = status
              }
              return (
                <div key={id} className=' grid gap-[10px] items-center grid-cols-[120px_130px_120px_50px_auto]'>
                  <PositionUser
                    service={service}
                    stageId={stageId}
                    currentData={user}
                    multi={arr.length > 1}
                    small={true}
                    onSetData={onSetPrintUser(id)}
                    isCurrentStage={isCurrentStage}></PositionUser>
                  <PositionStatus
                    positionId={id}
                    user={user}
                    service={service}
                    blocked={blocked}
                    stageId={stageId}
                    isCurrentStage={isCurrentStage}
                    currentData={currentStatus}
                    onSetData={onSetPrintStatus(id)}
                    setIsVisible={setIsVisible}
                    timestamps={timestamps}
                    multi={arr.length > 1}
                    getData={getData}
                    stampSpecialId={stampSpecialId}
                    positionStageId={positionStageId}
                    debouncedGetData={debouncedGetData}></PositionStatus>
                  <span className='text-Regular(12_14) line-clamp-2 overflow-hidden'>{name}</span>
                  <span>
                    (готово{' '}
                    <span className='text-Accent/Green'>
                      {subusers.reduce((acc, { readyCount }) => (acc += readyCount), 0) || 0}
                    </span>
                    )
                  </span>
                  <div
                    onClick={ev =>
                      onSetStampProgress(ev)({
                        ...props,
                        name,
                        drawingId: id,
                        getData
                      })
                    }
                    className='hover:cursor-pointer'>
                    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
                        stroke='#7BB2E7'
                        stroke-width='2'
                      />
                      <g clipPath='url(#clip0_1034_27789)'>
                        <circle cx='6.5' cy='6.5' r='1.25' stroke='#2F80ED' stroke-width='1.5' />
                        <path
                          d='M12.7515 11.4795C12.7515 12.1698 12.1918 12.7295 11.5015 12.7295C10.8111 12.7295 10.2515 12.1698 10.2515 11.4795C10.2515 10.7891 10.8111 10.2295 11.5015 10.2295C12.1918 10.2295 12.7515 10.7891 12.7515 11.4795Z'
                          stroke='#2F80ED'
                          stroke-width='1.5'
                        />
                        <line x1='12.5303' y1='5.53033' x2='5.53033' y2='12.5303' stroke='#2F80ED' stroke-width='1.5' />
                      </g>
                      <mask id='path-5-inside-1_1034_27789' fill='white'>
                        <path d='M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 3.60419e-07 9 0V1.97721C10.8626 1.97721 12.6488 2.71711 13.9659 4.03414C15.2829 5.35117 16.0228 7.13744 16.0228 9C16.0228 10.8626 15.2829 12.6488 13.9659 13.9659C12.6488 15.2829 10.8626 16.0228 9 16.0228V18Z' />
                      </mask>
                      <path
                        d='M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 3.60419e-07 9 0V1.97721C10.8626 1.97721 12.6488 2.71711 13.9659 4.03414C15.2829 5.35117 16.0228 7.13744 16.0228 9C16.0228 10.8626 15.2829 12.6488 13.9659 13.9659C12.6488 15.2829 10.8626 16.0228 9 16.0228V18Z'
                        fill='#D9D9D9'
                        stroke='#2F80ED'
                        stroke-width='4'
                        mask='url(#path-5-inside-1_1034_27789)'
                      />
                      <defs>
                        <clipPath id='clip0_1034_27789'>
                          <rect width='9' height='9' fill='white' transform='translate(4.5 4.5)' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {!isAllMod && (
          <div className='flex p-[12px]  border border-Content/Border border-l-0 border-r-0 border-t-0'>
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Ответственный</span>
              </div>
              <PositionUser
                service={service}
                stageId={stageId}
                currentData={user}
                onSetData={onSetUser}
                isCurrentStage={isCurrentStage}></PositionUser>
            </div>
          </div>
        )}
        <div className='p-[12px] flex flex-col gap-[12px] border border-Content/Border border-l-0 border-r-0 border-t-0'>
          {!!order.localComment ||
            (order.managerComment && (
              <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
                <div className='flex gap-[18px]'>
                  <span className='text-Regular(14_16) text-Content/Middle'>Комментарий администратора к заказу </span>
                </div>
                {order.localComment?.comment || order.managerComment}
              </div>
            ))}
          <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
            <div className='flex gap-[18px]'>
              <span className='text-Regular(14_16) text-Content/Middle'>Дата перехода на дашборд</span>
            </div>
            <span className='text-Regular(14_16)'>
              {date.transform(dashbordDate || createdAt)}
              {isPast24Hours && (
                <span className={`ml-[8px] text-Regular(14_16) text-Accent/Blue`}>
                  Более 24 часов
                  <i className={`text-Regular(14_16) icon-watch text-Accent/Blue`}></i>
                </span>
              )}
            </span>
          </div>
          <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
            <div className='flex gap-[18px]'>
              <span className='text-Regular(14_16) text-Content/Middle'>Дата готовности</span>
            </div>
            <span className='text-Regular(14_16) flex items-center'>
              {date.transform(readyDate)}
              <ReadyTimePicker
                dontDelay={order.dontDelay}
                className={isPast24Hours ? '!bg-[#ffb6c1]' : ''}
                small={true}
                positionId={id}
                callback={() => getData(true)}></ReadyTimePicker>
              {order.dontDelay && <span className='text-Regular(14_16) text-[#FF7F50]'>Не переносить</span>}
            </span>
          </div>

          {!!drawings.length && (
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Метод печати</span>
              </div>
              <div className='grid gap-[0.4rem] h-[100%]'>
                {drawings.map(({ name, id }, _, arr) => (
                  <div
                    data-tooltip-id={'cell-info'}
                    data-tooltip-content={name}
                    data-tooltip-place='top'
                    key={id}
                    className='flex items-center'>
                    <span
                      className={`${arr.length === 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                      {name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!drawings.length && (
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Количество цветов</span>
              </div>
              <div className='grid gap-[0.4rem] h-[100%]'>
                {drawings.map(({ stamps, id }, _, arr) => (
                  <div key={id} className='flex items-center '>
                    <span
                      className={`${arr.length === 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                      {stamps || 1}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!drawings.length && (
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Логотип</span>
              </div>
              <div className='grid gap-[0.4rem] h-[100%]'>
                <div className='grid gap-[0.4rem] h-[100%]'>
                  {drawings.map(({ name, id, logo }, _, arr) => (
                    <div key={id} className='flex items-center'>
                      <span
                        className={`${arr.length === 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                        {logo}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {settings.title.available && (
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Название товара</span>
              </div>
              <span onClick={() => onPositionDetail(id)} className='line-clamp-2 text-Regular(12_14)'>
                {title}
              </span>
            </div>
          )}
          {order.userinfo?.phone && (
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Телефон клиента</span>
              </div>
              <a
                href={`tel:${order.userinfo.phone}`}
                className='text-Accent/Blue underline line-clamp-2 text-Regular(16_18)'>
                {order.userinfo.phone}
              </a>
            </div>
          )}

          {settings.quantity.available && (
            <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>Количество</span>
              </div>
              <div className='relative'>
                {quantityUnderOrder && (
                  <div className=' flex gap-[0.3rem] left-[100%] bottom-[100%]  text-Regular(12_14)'>
                    <span className='text-Accent/Green'> {quantityInStock}</span>/{' '}
                    <span className='text-Accent/Orange'>{quantityUnderOrder}</span>
                  </div>
                )}

                <span onClick={() => onPositionDetail(id)} className='line-clamp-2 text-Regular(16_18)'>
                  {quantity} штук
                  {positionStock?.status === 'done' && (
                    <div className='flex gap-[0.3rem] left-[100%] bottom-[100%]  text-Regular(14_16)'>
                      <span className='text-[#6b6b6b]' title={'В наличии'}>
                        {' '}
                        ({positionStock.inStock}
                      </span>
                      /{' '}
                      <span className='text-Accent/Green' title={'Доступно'}>
                        {positionStock.available})
                      </span>
                    </div>
                  )}
                </span>
              </div>
            </div>
          )}
          {!folded && (
            <>
              {settings.manager.available && (
                <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
                  <div className='flex gap-[18px]'>
                    <span className='text-Regular(14_16) text-Content/Middle'>Менеджер</span>
                  </div>
                  <span className='line-clamp-2 text-Regular(16_18)'>{order.manager}</span>
                </div>
              )}
              {settings.company.available && (
                <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
                  <div className='flex gap-[18px]'>
                    <span className='text-Regular(14_16) text-Content/Middle'>Заказчик</span>
                  </div>
                  <span className='line-clamp-2 text-Regular(16_18)'>{order.company}</span>
                </div>
              )}
              {settings.date.available && (
                <div className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
                  <div className='flex gap-[18px]'>
                    <span className='text-Regular(14_16) text-Content/Middle'>Дата заказа</span>
                  </div>
                  <span className='line-clamp-2 text-Regular(16_18)'>{order.date}</span>
                </div>
              )}
            </>
          )}
          {settings.order.available && (
            <div
              onClick={() => onOrderDetail(order.id)}
              className='grid flex-1 grid-cols-[130px_1fr_100px] gap-[12px] items-center'>
              <div className='flex gap-[18px]'>
                <span className='text-Regular(14_16) text-Content/Middle'>№ Заказа</span>
              </div>
              <span className='line-clamp-2 text-Regular(16_18) text-Accent/Blue underline'>{order.orderId}</span>
              <span
                onClick={ev => {
                  ev.stopPropagation()
                  setFolded(!folded)
                }}
                className='ml-auto text-Regular(16_18) flex items-center gap-[5px] text-Accent/Blue'>
                {folded ? 'Еще' : 'Свернуть'}
                <i
                  className={`
									${!folded ? 'rotate-180' : ''}
									 icon-arrow text-Regular(12_14)`}></i>
              </span>
            </div>
          )}
        </div>
        <div className='p-[12px] flex justify-between'>
          <ul className='flex items-center gap-[36px]'>
            <li onClick={onSetCommentHandler} className='ease-in-out duration-300  flex items-center gap-[0.8rem]'>
              <i className='icon-comment text-Accent/Blue text-Regular(24_30)'></i>
            </li>
            <li onClick={onSetStickerHandler} className=' ease-in-out duration-300  flex items-center gap-[0.8rem]'>
              <i className='icon-stik text-Accent/Lilac text-Regular(24_30)'></i>
            </li>
            {kitID && (
              <li>
                <ProductSetLabel large mobile onOpenSetModal={onOpenSetModal} />
              </li>
            )}
          </ul>
          <div
            onClick={() => onPositionDetail(id)}
            className='flex items-center border border-Content/Border p-[8px] pr-[0px] '>
            <div className='text-Regular(12_14) text-Content/Middle'>Перейти в позицию</div>
            <div className='flex justify-end'>
              <i className='duration-300 ease-in-out  icon-drag text-Regular(24_30) flex justify-end text-Content/Light'></i>
            </div>
          </div>
        </div>
      </div>
    </OutsideAlerter>
  )
}

export default observer(Position)
