import Dashbord from '../components/dashbord/dashbord'
import Stages from '../components/stages/stages'

function Main() {
  return (
    <>
      <Stages></Stages>
      <Dashbord></Dashbord>
    </>
  )
}

export default Main
