import { useCallback, useEffect, useRef, useState } from 'react'

import React from 'react'
import OutsideAlerter from '../../outsideAlerter/outsideAlerter'
import Modal from '../../modal/modal-mobile'
import Multiselect from '../../multiselect/multiselect'
import Button from '../../ui/button/button'
import Input from '../../ui/input/input'
import Datepicker from '../../ui/datepicker/datepicker'
import usersStore from '../../../store/users'
import SearchModal from '../../modals/searchModal/searchModal-mobile'

const sanitizeScheme = data => {
  const result = Object.entries(data || {}).reduce((acc, [path, { type, val }]) => {
    let query = {}
    let counter = 0
    path = path.split('.')
    path.forEach(x => {
      if (acc[x]) {
        counter++
        query = acc[x]
      }
    })
    if (counter) {
      path.splice(0, counter)
    }
    const buildQuery = obj => {
      if (!path.length) {
        if (type === 'multi') {
          obj['$in'] = val.map(x => x.id)
        }
        if (type === 'search') {
          obj['$containsi'] = val
        }
        return
      }
      const key = path.splice(0, 1)
      if (!obj[key]) {
        obj[key] = {}
      }
      buildQuery(obj[key])
    }
    buildQuery(query)
    if (counter) {
      query = {}
    }
    return { ...acc, ...query }
  }, {})
  return result
}

function Search({ statuses, setFilter, isAllMod, stages, setPage }) {
  const schemeRef = useRef({})
  const formRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { users } = usersStore

  const updateScheme = ({ val, type, label, path }) => {
    if ((Array.isArray(val) && !val.length) || !val) {
      delete schemeRef.current[path]
    } else {
      schemeRef.current[path] = {
        label,
        val,
        type,
        path
      }
    }
  }

  const onSubmit = useCallback(async () => {
    const res = sanitizeScheme(schemeRef.current)
    setPage(1)
    setFilter({ ...res })

    setIsOpen(false)
  }, [schemeRef.current])

  console.log(schemeRef.current)

  return (
    <div className='relative flex-1 flex '>
      <div
        onClick={() => setIsOpen(true)}
        className={`
				${Object.values(schemeRef.current).length ? 'before:w-[8px] before:h-[8px] before:rounded-[50%] before:bg-Accent/Blue before:absolute before:top-[3px] before:right-[3px]' : ''}
				w-[42px] h-[42px] border border-Content/Border flex justify-center items-center relative`}>
        <i className='icon-search  group-hover:text-Accent/Blue text-Regular(18_24) text-Content/Light'></i>
      </div>
      <Modal title='Применить фильтр' closeModal={() => setIsOpen(false)} isOpen={isOpen}>
        <SearchModal
          formRef={formRef}
          isAllMod={isAllMod}
          schemeRef={schemeRef}
          onSubmit={onSubmit}
          stages={stages}
          updateScheme={updateScheme}
          statuses={statuses}
          users={users}></SearchModal>
      </Modal>
    </div>
  )
}

export default React.memo(Search)
