import ReactModal from 'react-modal'
import './style.css'

function Modal({ isOpen, title, children, closeModal, style = {} }) {
  const setOverflow = ref => {
    setTimeout(() => {
      // ref && (ref.style.overflow = 'auto')
    }, 400)
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      overlayRef={setOverflow}
      onRequestClose={closeModal}
      style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          backgroundColor: 'rgba(	114, 112, 112, 0.6)',
          zIndex: 999,
          top: '0',
          left: '0',
          width: '100%',
          height: '100%'
        },
        content: {
          minHeight: 'calc(100% - 3.5rem)',
          display: 'flex',
          alignItems: 'center',
          position: 'static',
          padding: '0px',
          border: '0px',
          background: 'transparent',
          overflow: 'visible',
          margin: '1.75rem auto'
        },
        ...style
      }}>
      <div onClick={ev => ev.stopPropagation()} className='ReactModal__Content  bg-white w-[100%]  relative'>
        <div className='flex justify-between py-[10px] px-[30px] items-center border border-Content/Border'>
          <span className='text-Regular(18_24) text-Content/Middle'>{title}</span>
          <span onClick={closeModal} className='ml-auto'>
            <i className='translate-x-6 icon-close text-Regular(38_42) text-Content/Middle flex justify-center items-center'></i>
          </span>
        </div>

        {children}
      </div>
    </ReactModal>
  )
}

export default Modal
