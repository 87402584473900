import Header from '../header/header-mobile'
import Footer from '../footer/footer'

function Layout({ children }) {
  return (
    <div className=' flex flex-col min-h-[100vh] min-w-[575px]'>
      <Header></Header>
      {children}
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Layout
