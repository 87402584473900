import { useEffect, useRef, useState } from 'react'
import DropdownHOC from '../../dropdownHOC/dropdownHOC'
import stageStore from '../../../store/stage'
import DefaultModal from '../defaultModal/defaultModal-mobile'
import Button from '../../ui/button/button'
import usePosition from '../../../hooks/position.hook'
import usersStore from '../../../store/users'
import ButtonBorder from '../../ui/buttonBorder/buttonBorder'
import Checkbox from '../../ui/checkbox/checkbox-mobile'

function CommentModal({ positionId, positionsIDs, stageId, callback, isFullMod, buttonName = 'Отправить', getData }) {
  const textareaRef = useRef(null)
  const [urgentStage, setUrgentStage] = useState(false)
  const { setComment, groupSetComment } = usePosition()
  const {
    currentUser: {
      role: [{ name }],
      username
    }
  } = usersStore
  const { currentStage, stages } = stageStore
  const [stage, setStage] = useState(currentStage)

  useEffect(() => {
    if (stageId) {
      const candidate = stages.find(x => x.id === stageId)
      setStage(candidate)
    }
  }, [stageId])

  const dropdownItems = stages.map(x => ({
    onEvent: () => setStage(x),
    title: x.title
  }))

  const onCommentHandler = async () => {
    if (!textareaRef.current.value || !stage) {
      return
    }
    if (positionsIDs.length) {
      await groupSetComment({
        roleOwner: name,
        nameOwner: username,
        positionsIDs: positionsIDs,
        positionId: null,
        stageId: stage.id,
        comment: textareaRef.current.value,
        urgentStage
      })
    } else {
      await setComment({
        roleOwner: name,
        nameOwner: username,
        positionId: positionId,
        positionsIDs: [],
        stageId: stage.id,
        comment: textareaRef.current.value,
        urgentStage
      })
    }
    callback && callback()
  }

  const onSuggestHandler = suggest => {
    textareaRef.current.value = suggest
  }

  return (
    <div className=' min-h-[10rem]'>
      <DefaultModal>
        {isFullMod && (
          <div className='flex flex-col gap-[1.8rem] mb-[1.6rem]'>
            <span className='text-Regular(18_24)'>Отправить комментарий на этап</span>
            <div className='min-w-[20rem]'>
              <DropdownHOC items={dropdownItems} title={stage?.title || 'Выберите этап'}></DropdownHOC>
            </div>
          </div>
        )}

        <div className='flex flex-col gap-[2.4rem]'>
          <textarea
            placeholder='Введите комментарий'
            ref={textareaRef}
            className='p-[1.2rem] text-Regular(16_20) border w-[100%] border-Content/Border rounded-[4px] min-h-[14rem]'
          />
          {!!stage?.suggests?.length && (
            <div className='flex flex-col items-start gap-[1.8rem]'>
              {stage?.suggests?.map(x => (
                <ButtonBorder key={x.id} onPress={() => onSuggestHandler(x.suggest)} name={x.suggest}></ButtonBorder>
              ))}
            </div>
          )}

          {isFullMod && (
            <div className='flex justify-start'>
              <Checkbox onChange={() => setUrgentStage(!urgentStage)} label='Сделать этап срочным'></Checkbox>
            </div>
          )}
          <div className='self-end'>
            <Button onPress={onCommentHandler} name={buttonName}></Button>
          </div>
        </div>
      </DefaultModal>
    </div>
  )
}

export default CommentModal
