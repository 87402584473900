import React from 'react'
import { AiOutlineFileText } from 'react-icons/ai'

const ProductSetLabel = ({ onOpenSetModal, large, mobile, kitID }) => {
  return (
    <div
      className={`${large ? 'text-Regular(16_18)' : 'text-Regular(14_16)'} border 
		          border-transparent transition bg-Accent/Yellow p-[1px_4px]
		           flex gap-2 items-center ${
                 mobile
                   ? ''
                   : `hover:cursor-pointer hover:bg-transparent
		            hover:border-Accent/Yellow`
               }`}
      onClick={onOpenSetModal}>
      Набор {kitID ? `#${kitID}` : ''}
    </div>
  )
}

export default ProductSetLabel
