import React, { useEffect, useState } from 'react'
import useOrder from '../../../hooks/order.hook'
import Loader from '../../loader/loader'

export const ProductSetModal = ({ productsSetModal, mobile }) => {
  const { getOrderKitInfo } = useOrder()
  const [kitData, setKitData] = useState([])
  const getKitInfo = async (orderId, kitID) => {
    const res = await getOrderKitInfo(orderId, kitID)
    if (res.status === 'OK') {
      setKitData(res.data)
    }
  }

  useEffect(() => {
    const { orderId, kitID } = productsSetModal
    getKitInfo(orderId, kitID)
  }, [])

  return (
    <div className={`bg-[#FFFFFF] ${mobile ? 'py-[20px] px-[40px]' : 'py-[50px] px-[70px]'} max-w-[900px]`}>
      <div className={`text-Regular(24_30) mb-[30px]`}>
        <span className={`text-Regular(24_30)`}>Набор #{productsSetModal.kitID}</span>
      </div>
      <div className={`h-[1px] bg-[#000000] opacity-[0.3]`}></div>
      {kitData.length ? (
        <div className={`text-Regular(24_30) mt-[30px]`}>
          <div className={`text-Regular(16_20) opacity-[0.5] mb-[10px]`}>Комплектующие:</div>
          <div className={`text-Regular(18_24)`}>
            {kitData.map((el, i) => (
              <div className={`mb-[4px] p-[8px] border-b-[1px]`}>
                <div className={`flex gap-[1rem]`}>
                  <div>{i + 1}</div>
                  <div>{el.title}</div>
                </div>
                {!!el.drawings.length && (
                  <div>
                    {el.drawings.map(({ name }) => (
                      <div className={`text-Regular(16_20) opacity-[0.7] m-[8px_0] pl-[21px]`}>{name}</div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={`relative w-[400px] h-[100px]`}>
          <Loader></Loader>
        </div>
      )}
    </div>
  )
}
