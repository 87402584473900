import { useCallback, useEffect, useState } from 'react'

import useTimer from '../../hooks/timer.hook'
import stageStore from '../../store/stage'
import CellPickerHOC from '../cellPickerHOC/cellPickerHOC'
import usePosition from '../../hooks/position.hook'
import { emmiter } from '../../helpers/Emmiter'
import dashbordStore from '../../store/dashbord'

const renderItem =
  (onClickHandler, { positionId, getData, ...rest }) =>
  item => {
    const onClickHandlerWrapper = async ev => {
      ev.stopPropagation()
      if (!item.additionalWorkTrigger) {
        onClickHandler(item)
      } else {
        emmiter.emmit('setMoveToStageModal', {
          positionId,
          isOpen: true,
          callback: () => getData(true)
        })
      }
    }

    return (
      <span
        onClick={onClickHandlerWrapper}
        key={item.id}
        className='hover:opacity-60 ease-in-out duration-300 block px-[1.2rem] py-[1.2rem]'>
        {item.title}
      </span>
    )
  }

const Cell = ({
  current: { stageTrigger, title, pauseTrigger, pauseDescr, color, triggerTimeout, additionalWorkTrigger, ...props },
  getData,
  debouncedGetData,
  blocked,
  currentData,
  setIsVisible,
  isDropDown,
  timestamps = '{}',
  small,
  multi,
  isDisabled,
  user
}) => {
  const { changeTime } = JSON.parse(timestamps) || {}

  const onTimerFinish = useCallback(async () => {
    setIsVisible && !additionalWorkTrigger && setIsVisible(false)
    debouncedGetData && debouncedGetData()
  }, [additionalWorkTrigger])

  const { start, clear, value, isFinished } = useTimer({
    onFinish: onTimerFinish
  })

  // const checkUser = useCallback((ev) => {
  // 	if(!user){
  // 		ev.stopPropagation();
  // 		emmiter.emmit('setUserAlert')
  // 	}
  // },[user])

  useEffect(() => {
    if (stageTrigger || changeTime) {
      const diff =
        (changeTime - new Date().getTime()) / 1000 > 0 ? Math.ceil((changeTime - new Date().getTime()) / 1000) : 0
      if ((changeTime && changeTime < new Date().getTime()) || (blocked === false && additionalWorkTrigger)) return
      start(diff || triggerTimeout)
    } else {
      clear()
    }
    return () => {
      clear()
    }
  }, [triggerTimeout, stageTrigger, additionalWorkTrigger, changeTime, blocked, clear, start])

  return title ? (
    <div
      // onClick={checkUser}
      style={{ background: color }}
      className='flex h-[100%] justify-between w-[100%]'>
      {additionalWorkTrigger && blocked !== null && (
        <span
          className={`
					${blocked ? 'bg-Accent/Red_Middle' : 'bg-Accent/Light_Green'} 
					w-[1rem] h-[1rem] rounded-[50%] absolute top-[0.5rem] right-[0.5rem] 
				`}></span>
      )}

      <div
        className={`
				${small ? 'overflow-hidden text-ellipsis ' : ''}
				${multi ? 'px-[1.2rem] py-[0.2rem]' : 'px-[1.2rem] py-[0.9rem]'}
				h-[100%] flex gap-[0.2rem] items-center w-[100%]`}>
        <div
          style={{
            wordBreak: 'break-word'
          }}
          className={`
						${multi ? 'text-Regular(12_14)' : small ? 'text-Regular(14_16)' : 'text-Regular(16_18)'}
						flex-1
						
						`}>
          {' '}
          {title || currentData?.title}
        </div>
        {!isDisabled && (
          <i
            className={`
						${isDropDown ? 'rotate-180' : ''}
						${multi ? 'text-Regular(12_14)' : 'text-Regular(14_16)'}
						 icon-arrow  text-Content/Middle`}></i>
        )}
      </div>
      {!isFinished && triggerTimeout && (
        <div className='relative  flex items-center'>
          <div className='relative'>
            <i
              className={`
							 icon-ready text-Accent/Blue
							${small ? 'text-[2rem]' : 'text-[2.4rem]'}
						`}></i>
            <span
              className={`
						absolute right-[0.3rem] top-[1rem] text-Accent/Blue 
						${small ? 'text-[1rem]' : 'text-[1.2rem]'} 
						`}>
              {value}
            </span>
          </div>
        </div>
      )}
      {pauseTrigger && isFinished && !isDisabled && (
        <div
          data-tooltip-id={'cell-hover-alert'}
          data-tooltip-content={pauseDescr}
          data-tooltip-place='top'
          className='relative  flex items-center'>
          <div className='relative'>
            <i
              className={`
							 icon-pause text-Accent/Orange
							${small ? 'text-[2rem]' : 'text-[2.4rem]'}
						`}></i>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className='text-Accent/Red font-medium'>Статус?</div>
  )
}

const PositionCellPicker = CellPickerHOC(renderItem, Cell)

function PositionStatus({ ...props }) {
  let stage = props.stageId ? stageStore.stages.find(x => x.id === props.stageId) : stageStore.currentStage
  const { step } = stage
  stage = props?.currentData?.id ? stage : null

  if (!props.data) {
    // const stage = stageStore.stages.find(x => x.statuses.find(y => y.id === props?.currentData?.id));

    if (!stage) {
      return null
    } else {
      let statuses = stage.statuses
      //фильтруем статусы в зависимости от нанесения
      if (props.stampSpecialId && step === 1) {
        statuses = stage.statuses.filter(
          x => x.stampSpecialId === props.stampSpecialId || x.stampSpecialId === null || x.stampSpecialId === undefined
        )
      }
      //фильтруем статусы для этапа печати в зависимости от количества нанечений
      if (step === 5) {
        if (props.multi) {
          statuses = stage.statuses.filter(x => (x.stageTrigger && x.additionalWorkTrigger) || !x.stageTrigger)
        } else {
          statuses = stage.statuses.filter(x => !x.pauseTrigger)
        }
      }

      props.data = statuses
    }
  }

  return <PositionCellPicker {...props}></PositionCellPicker>
}

export default PositionStatus
