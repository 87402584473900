import { observer } from 'mobx-react-lite'
import Cell from '../cell/cell'
import OutsideAlerter from '../outsideAlerter/outsideAlerter'
import './style.css'

function Position(props) {
  const { id, settings, title, onOrderDetail, quantity, positionStageId, order, comments, code } = props

  return (
    <OutsideAlerter
      tr={true}
      className={`
				${'border-Content/Border'}
				hover:border-Accent/Blue position odd:bg-[#fff] even:bg-Dominant/Light flex  border mt-[-1px]`}>
      <Cell {...settings.title} available={true} width={25} height={`h-[6rem]`}>
        <div className='line-clamp-3 flex flex-col gap-[10px]'>
          <span>{title}</span>
        </div>
      </Cell>
      <Cell {...settings.code} available={true} width={15}>
        <div>
          <div className='flex items-center'>
            <span className=''>{code}</span>
          </div>
        </div>
      </Cell>
      <Cell {...settings.quantity} available={true} width={10}>
        {quantity}
      </Cell>
      <Cell {...settings.order} available={true} width={15}>
        <span
          onClick={ev => {
            ev.stopPropagation()
            onOrderDetail(order.id)
          }}
          className='text-Accent/Blue text-Regular(16_18) border border-l-0 border-r-0 border-t-0 border-Accent/Blue hover:cursor-pointer hover:border-transparent'>
          {order.orderId}
        </span>
      </Cell>
      <Cell {...settings.company} available={true} width={15}>
        <span className='text-Regular(16_18)'>{order.company}</span>
      </Cell>
      <Cell {...settings.manager} available={true} width={15}>
        <span className='text-Regular(16_18)'>{order.manager}</span>
      </Cell>

      <Cell {...settings.date} available={true} width={15}>
        <span className='text-Regular(16_18)'>{order.date}</span>
      </Cell>
    </OutsideAlerter>
  )
}

export default observer(Position)
