function DefaultModal({ title, children }) {
  return (
    <div className='py-[5rem] px-[7rem]'>
      {title && <span className='block text-center text-Regular(38_42) text-Content/Dark mb-[5rem]'>{title}</span>}
      {children}
    </div>
  )
}

export default DefaultModal
