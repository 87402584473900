import { publicAPI } from '../public_api/publicAPI'

export const useSiteRequests = () => {
  const getProductsStock = async articlesArr => {
    const query = articlesArr.join(';')
    try {
      const res = await publicAPI.getPositionsStock(query)
      const { status, data } = res.data.result
      if (status === 'success') {
        return data
      } else {
        return null
      }
    } catch (e) {
      throw e
    }
  }

  return {
    getProductsStock
  }
}
