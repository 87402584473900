import { useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function Input({
  label,
  type = 'text',
  maxValue,
  disabled = false,
  password = false,
  icon,
  labelSmall = false,
  placeholder,
  initial = '',
  onInput,
  border = true,
  setInputRef,
  small
}) {
  const inputRef = useRef()
  const [value, setValue] = useState(initial)
  const [inputPasswordType, setInputPasswordType] = useState('password')
  const [startDate, setStartDate] = useState(new Date())

  const disableScroll = useCallback(ev => {
    ev.target.blur()
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('mousewheel', disableScroll)
    }
    return () => {
      if (inputRef.current) {
        inputRef.current?.removeEventListener('mousewheel', disableScroll)
      }
    }
  }, [inputRef])

  useEffect(() => {
    setValue(initial)
  }, [initial])

  const onChangeHandler = ev => {
    if (maxValue !== undefined && maxValue < ev.target.value) {
      ev.target.value = maxValue
      setValue(maxValue)
      onInput(ev)
      return
    }
    setValue(ev.target.value)
    onInput(ev)
  }

  const togglePasswordType = () => {
    if (inputPasswordType === 'text') {
      setInputPasswordType('password')
    } else {
      setInputPasswordType('text')
    }
  }

  return (
    <div className='flex flex-col items-start'>
      {label && (
        <>
          {labelSmall ? (
            <span className='block text-Regular(12_14) text-Content/Light mb-[0.6rem]'>{label}</span>
          ) : (
            <span className='text-Content/Middle text-Regular(14_16) mb-[0.6rem]'>{label}</span>
          )}
        </>
      )}

      <div className='flex w-[100%] relative'>
        {icon && (
          <i
            className={`${icon} absolute top-[50%] text-Content/Light left-[1.2rem] translate-y-[-50%] text-Regular(18_24)
					`}></i>
        )}

        {type === 'date' ? (
          <div
            className='px-[1.2rem]
					py-[1.7rem]
					block
					w-[100%]
					text-Regular(16_18)
					placeholder:text-Regular(16_18)
					placeholder:text-Content/Middle
					focus:border-Accent/Blue
					focus:outline-0'>
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
          </div>
        ) : (
          <input
            ref={e => {
              setInputRef && setInputRef(e)
              inputRef.current = e
            }}
            onChange={onChangeHandler}
            placeholder={placeholder}
            value={value}
            type={password ? inputPasswordType : type}
            className={`
					${disabled ? 'pointer-events-none text-Content/Middle bg-Content/Light' : 'text-Content/Dark bg-Dominant/Main'}
					${border ? 'border border-Content/Border' : ''}
					${icon ? 'pl-[4.8rem]' : ''}
					${password ? 'pr-[4.8rem]' : ''}
				  px-[1.2rem]
				  ${small ? 'py-[1.2rem]' : 'py-[1.7rem]'}
				  block
				  w-[100%]
				  text-Regular(16_18)
				  placeholder:text-Regular(16_18)
				  placeholder:text-Content/Middle
				  focus:border-Accent/Blue
				  focus:outline-0
			 `}></input>
        )}

        {password && (
          <i
            onClick={togglePasswordType}
            className={`hover:cursor-pointer icon-eye absolute top-[50%] text-Content/Light right-[1.2rem] translate-y-[-50%] text-Regular(18_24)
					`}></i>
        )}
      </div>
    </div>
  )
}

export default Input
