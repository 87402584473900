import { useEffect, useState } from 'react'

function Checkbox({ label, active = false, onChange, className }) {
  const [isActive, setIsActive] = useState(active)

  useEffect(() => {
    setIsActive(active)
  }, [active])

  const onChangeHandler = () => {
    setIsActive(!isActive)
    onChange && onChange()
  }

  return (
    <div
      onClick={onChangeHandler}
      className={`
				${label ? '' : ''}
				 flex items-center gap-[0.8rem] ease-in-out duration-300`}>
      <div
        className={`
					${isActive ? 'bg-Accent/Yellow' : 'bg-white'}
					flex items-center justify-center
					 group-hover:border-Accent/Yellow ease-in-out duration-300 w-[24px] h-[24px] border border-Content/Border
				`}>
        {isActive && <i className='icon-mark before:!font-bold'></i>}
      </div>
      {label && <div className={'text text-Regular(16_18) ' + className}>{label}</div>}
    </div>
  )
}

export default Checkbox
