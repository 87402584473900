import React from 'react'
import Loader from '../../loader/loader'

export const DatepickerLoader = () => {
  return (
    <div className={`absolute inset-0 flex items-center justify-center`}>
      <Loader />
    </div>
  )
}
