import { useEffect, useState, useRef, useCallback } from 'react'

import { date } from '../../../utils/date'
import Loader from '../../loader/loader'
import ReadyTimePicker from '../../readyTimePicker/readyTimePicker'
import PositionStatus from '../../positionStatus/positionStatus-mobile'
import PositionUser from '../../positionUser/positionUser-mobile'
import usePosition from '../../../hooks/position.hook'
import ButtonBorderRound from '../../ui/buttonBorderRound/buttonBorderRound'
import ButtonGrey from '../../ui/buttonGrey/buttonGrey'
import ConfirmModal from '../confirmModal/confirmModal-mobile'
import Modal from '../../modal/modal'
import ButtonRed from '../../ui/buttonRed/buttonRed'
import { emmiter } from '../../../helpers/Emmiter'
import Checkbox from '../../ui/checkbox/checkbox'
import ProductSetLabel from '../../productSetLabel/ProductSetLabel'

const Th = ({ name, className }) => {
  return (
    <div
      className={`${className} flex-auto flex bg-Dominant/Light  justify-center items-center p-[1.2rem] border border-Content/Border border-l-0 border-r-0 border-t-0`}>
      <span className='text-Regular(12_14) font-semibold'>{name}</span>
    </div>
  )
}

const getCurrentStage = stages => {
  if (!stages) return null
  return [...stages]?.reverse().find(x => x.isCurrentStage).stage.title
}

function PositionModal({
  positionId,
  setCommentModal,
  setStickerModal,
  onOrderDetail,
  getDashbord,
  setProductsSetModal
}) {
  const intervalId = useRef(null)
  const {
    setUser,
    setStatus,
    getPosition,
    setPrintUser,
    setPositionsToCanceled,
    moveToAdditionalWorkStage,
    setPositionsToClosed,
    setPrintStatus,
    setPositionsToUrgent
  } = usePosition()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isModalCancelOpened, setIsModalCancelOpened] = useState(false)
  const [isModalCloseOpened, setIsModalCloseOpened] = useState(false)
  const [isModalActionsOpened, setIsModalActionsOpened] = useState(false)
  const [isAdditionalWork, setIsAdditionalWork] = useState(false)

  const currenStage = getCurrentStage(data?.c_position_stages)

  const getData = useCallback(async () => {
    if (!positionId) return
    setLoading(true)
    const { data } = await getPosition({
      positionId
    })
    if (!data.drawings.length) {
      data.c_position_stages = data.c_position_stages.filter(x => [1, 2, 4, 5, 6].indexOf(x.stage.step) === -1)
    }
    setData(data)
    const isAtAdditionalWork = data.c_position_stages.find(x => x.isCurrentStage && x.stage.additionalWork)
    setIsAdditionalWork(isAtAdditionalWork)
    setLoading(false)
  }, [getPosition])

  const onSetStatus = positionStageId => async statusId => {
    await setStatus(positionStageId, statusId)
    await getData()
  }

  const onSetPrintStatus = (drawingId, positionStageId) => async statusId => {
    await setPrintStatus(positionStageId, statusId, drawingId)
    await getData()
  }

  const onSetPrintUser = (positionStageId, drawingId) => async userId => {
    await setPrintUser(positionStageId, userId, drawingId)
  }

  const onSetUser = positionStageId => async userId => {
    await setUser(positionStageId, userId)
    await getData()
  }

  const onClosePosition = async () => {
    setIsModalCloseOpened(false)
    setIsModalActionsOpened(false)
    await setPositionsToClosed([data.id])
    getData()
  }

  const onCancelPosition = async () => {
    setIsModalCancelOpened(false)
    setIsModalActionsOpened(false)
    await setPositionsToCanceled([data.id])
    getData()
  }

  const afterAdditionalWorkHandler = async id => {
    await moveToAdditionalWorkStage({
      positionStageId: id
    })
    emmiter.emmit('setCommentModal', false)
    await getData()
  }

  const onAdditionalWorkHandler = async () => {
    const { id } = data
    const {
      stage: { id: stageId }
    } = data.c_position_stages.find(x => x.stage.additionalWork)
    emmiter.emmit('setCommentModal', {
      positionId: data.id,
      isOpen: true,
      isFullMod: false,
      stageId,
      callback: () => afterAdditionalWorkHandler(id),
      buttonName: 'Отправить на доработку'
    })
  }

  useEffect(() => {
    getData()
    intervalId.current = setInterval(getData, 20000)
    return () => {
      clearInterval(intervalId.current)
    }
  }, [getData])

  const onUrgent = async () => {
    await setPositionsToUrgent({
      ids: [data.id],
      isUrgent: !data.isUrgent
    })
    getDashbord()
  }

  const { order: { id: orderId, company, localComment, managerComment } = {} } = data || {}

  let orderComment
  orderComment = localComment?.comment || managerComment

  const onOpenSetModal = e => {
    e.stopPropagation()
    {
      /*TODO kitID*/
    }
  }

  return (
    <div className=''>
      {loading && !data && <Loader></Loader>}
      {data && (
        <div>
          <div className=''>
            <div className='flex flex-col gap-[12px]'>
              <div className={`flex items-center justify-between`}>
                <div className='flex gap-[0.5rem]'>
                  <span className='block text-Regular(16_20)'>Статус позиции:</span>
                  <span className='text-Regular(16_18) font-bold'>{currenStage}</span>
                </div>
                <div className='flex flex-col gap-[1.2rem]'>
                  <Checkbox
                    onChange={onUrgent}
                    label='Срочная позиция'
                    padding={false}
                    className='text-Accent/Red font-medium'
                    active={data.isUrgent}></Checkbox>
                </div>
              </div>

              <div className='flex gap-[0.5rem] '>
                <span className='block text-Regular(16_20)'>Заказ:</span>
                <span
                  onClick={() => onOrderDetail(data.order.id)}
                  className='block text-Regular(16_20) font-bold text-Accent/Blue hover:cursor-pointer'>
                  №{data.order.orderId}
                </span>
              </div>
              <div className='flex gap-[0.5rem]'>
                <span className='block text-Regular(16_20)'>Заказчик:</span>
                <span className='block text-Regular(16_20) font-bold'>{data.order.company}</span>
              </div>
              <div className='flex gap-[0.5rem]'>
                <span className='block text-Regular(16_20)'>Дата готовности:</span>
                {data.readyDate && (
                  <span className='text-Regular(16_20) font-bold flex items-center'>
                    <span className='block text-Regular(16_20) font-bold '>
                      {date.transform(data.readyDate) || '-'}
                    </span>
                    <ReadyTimePicker small={true} positionId={data.id} callback={() => getData(true)}></ReadyTimePicker>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='pb-[26px] border border-Content/Border border-t-0 border-l-0 border-r-0'>
            {orderComment && (
              <div className='mt-[2rem]  gap-[1rem] bg-Accent/Light_Yellow  text-Regular(14_16) px-[1rem] py-[2px] leading-1'>
                <span className='font-medium'>Комментарий администратора к заказу:</span>
                <span> {orderComment}</span>
              </div>
            )}
            <div className='pt-[36px] pb-[26px] flex flex-col items-start overflow-auto mr-[-18px]'>
              <table className='border border-Content/Border '>
                <tbody>
                  <tr>
                    {/* <th className="p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold">Фото</th> */}
                    <th className='p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
                      Артикул
                    </th>
                    <th className='p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
                      Название
                    </th>
                    <th className='p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
                      Количество
                    </th>
                    {!!data.drawings.length && (
                      <th className='p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
                        Метод печати
                      </th>
                    )}
                    {data.logo && (
                      <th className='p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
                        Логотип
                      </th>
                    )}
                    {!!data.drawings.length && (
                      <th className='p-[1.2rem] border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
                        Кол-во цветов
                      </th>
                    )}
                  </tr>
                  <tr className='text-Regular(16_18) '>
                    {/* <td className="min-w-[140px] p-[1.2rem] border border-Content/Border">
											<div className="w-[8rem] h-[7rem] flex justify-center items-center">
												<img className="max-w-[100%] max-h-[100%]" src={data.image} alt="" />
											</div>
										</td> */}
                    <td className='min-w-[140px] p-[1.2rem] border border-Content/Border'>
                      {/*TODO kitID*/}
                      {/*{data.isSet && <ProductSetLabel onOpenSetModal={onOpenSetModal}/>}*/}
                      {data.code}
                    </td>
                    <td className='min-w-[140px] p-[1.2rem] border border-Content/Border'>{data.title}</td>
                    <td className='min-w-[140px] p-[1.2rem] border border-Content/Border text-center'>
                      <div className='relative flex-col flex items-center'>
                        {data.quantityUnderOrder && (
                          <div className=' flex gap-[0.3rem] left-[100%] bottom-[100%]  text-Regular(12_14)'>
                            <span className='text-Accent/Green'> {data.quantityInStock}</span>/{' '}
                            <span className='text-Accent/Orange'>{data.quantityUnderOrder}</span>
                          </div>
                        )}

                        {data.quantity}
                      </div>
                    </td>
                    {!!data.drawings.length && (
                      <td className='min-w-[140px] p-[1.2rem] border border-Content/Border '>
                        <div className='flex flex-col gap-[0.4rem]'>
                          {data.drawings.map(({ name }) => (
                            <span className='line-clamp-1'>{name}</span>
                          ))}
                        </div>
                      </td>
                    )}
                    {data.logo && (
                      <td className='min-w-[140px] p-[1.2rem] border border-Content/Border'>{data.logo}</td>
                    )}
                    {!!data.drawings.length && (
                      <td className='min-w-[140px] p-[1.2rem] border border-Content/Border '>
                        <div className='flex flex-col gap-[0.4rem]'>
                          {data.drawings.map(({ stamps }) => (
                            <span className='line-clamp-1 text-center'>{stamps || 1}</span>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='flex justify-between w-[100%]'>
              <div className='flex gap-[10rem]'>
                <div className='flex gap-[3.6rem] items-center'>
                  <i
                    onClick={() =>
                      setCommentModal({
                        positionId: data.id,
                        isOpen: true,
                        isFullMod: true,
                        callback: getData
                      })
                    }
                    className='icon-comment text-Regular(24_30) text-Accent/Blue hover:cursor-pointer'></i>
                  <i
                    onClick={() =>
                      setStickerModal({
                        isOpen: true,
                        title: data.title,
                        quantity: data.quantity,
                        code: data.code
                      })
                    }
                    className='icon-stik text-Regular(24_30) text-Accent/Lilac  hover:cursor-pointer'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-[3.6rem]'>
            <div className='flex flex-col gap-[2.6rem] overflow-auto'>
              {data?.c_position_stages.map(x => {
                return (
                  <div
                    className={`
										${
                      x.isCurrentStage
                        ? 'mt-[30px] mb-[30px] before:absolute before:inset-x-[0] before:inset-y-[-30px] before:z-[-1] before:bg-[#D5E6FF]'
                        : ''
                    }
										relative
										flex flex-col gap-[0.8rem]
										bg-white
										`}>
                    {x.isCurrentStage && (
                      <span className='absolute rounded-br-[10px] left-[0px] top-[-30px] p-[3px] text-white bg-Accent/Blue text-Regular(14_16)'>
                        Текущий этап
                      </span>
                    )}
                    <span
                      style={{ backgroundColor: x.stage.color }}
                      className='py-[0.9rem] px-[1.2rem] self-start text-Regular(16_18) font-semibold'>
                      {x.stage.title}
                    </span>
                    <table>
                      <tbody>
                        <tr>
                          <td className='min-w-[150px] border-collapse border border-Content/Border align-top'>
                            <Th name='Ответственный'></Th>
                            <div className='min-h-[8rem] p-[1.2rem]'>
                              {x.stage.stampStage ? (
                                data.drawings.map(({ user, id }, _, arr) => {
                                  return (
                                    <div key={id} className=' flex'>
                                      <PositionUser
                                        stageId={x.stage.id}
                                        currentData={user}
                                        multi={arr.length > 1}
                                        onSetData={onSetPrintUser(x.id, id)}
                                        isCurrentStage={x.isCurrentStage}></PositionUser>
                                    </div>
                                  )
                                })
                              ) : (
                                <PositionUser
                                  small={true}
                                  stageId={x.stage.id}
                                  currentData={x.user}
                                  onSetData={onSetUser(x.id)}
                                  isCurrentStage={x.isCurrentStage}></PositionUser>
                              )}
                            </div>
                          </td>
                          <td className='min-w-[180px] border-collapse border border-Content/Border align-top'>
                            <Th name='Текущий статус'></Th>
                            <div className='min-h-[8rem] p-[1.2rem]'>
                              {x.stage.stampStage ? (
                                <div
                                  className={`
																		${data.drawings?.length > 1 ? 'py-[0.2rem]' : ''}
																											grid
																											flex-col
																											gap-[1px]
																											w-[100%]							
																											h-[100%]`}>
                                  {data.drawings.map(({ status: drawingStatus, id: drawingId, name }, _, arr) => {
                                    let currentStatus

                                    if (drawingStatus) {
                                      currentStatus = drawingStatus
                                    } else {
                                      currentStatus = x.status
                                    }

                                    return (
                                      <div key={drawingId} className=' grid grid-cols-[1fr_1fr] items-center gap-[5px]'>
                                        <PositionStatus
                                          user={x.user}
                                          service={x.stage.service}
                                          blocked={x.stage.blocked}
                                          stageId={x.stage.id}
                                          getData={getData}
                                          isCurrentStage={x.isCurrentStage}
                                          currentData={currentStatus}
                                          onSetData={onSetPrintStatus(drawingId, x.id)}
                                          timestamps={x.stageChangeTimeStamps}
                                          multi={arr.length > 1}></PositionStatus>
                                        <span className='text-Regular(12_14) line-clamp-1 overflow-hidden'>{name}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              ) : (
                                <PositionStatus
                                  className='text-Regular(12_14)'
                                  user={x.user}
                                  currentData={x.status}
                                  stageId={x.stage.id}
                                  small={true}
                                  getData={getData}
                                  isCurrentStage={x.isCurrentStage}
                                  onSetData={onSetStatus(x.id)}
                                  timestamps={x.stageChangeTimeStamps}
                                  stampSpecialId={data.stampSpecialId}></PositionStatus>
                              )}
                            </div>
                          </td>
                          <td className='border-collapse border border-Content/Border align-top'>
                            <Th name='Комментарий'></Th>
                            <div className='min-h-[8rem] flex flex-col p-[1.2rem] gap-[1rem]'>
                              <div className='flex flex-col gap-[1rem] '>
                                {x.comments?.map(y => {
                                  return (
                                    <div className='flex flex-col p-[10px] items-start gap-[4px]  bg-Dominant/Dop border border-Content/Border'>
                                      <span className='text-Regular(14_16) font-semibold'>{y.roleOwner}</span>
                                      <span className='text-Regular(14_16)'>{y.comment}</span>
                                      <span className='text-left text-Regular(12_14) text-Content/Middle'>
                                        {date.transform(y.createdAt)}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                              <span
                                className='hover:cursor-pointer hover:opacity-70 ease-in-out duration-300 ml-auto text-Accent/Blue text-Regular(14_16)'
                                onClick={() =>
                                  setCommentModal({
                                    positionId: data.id,
                                    stageId: x.stage.id,
                                    isOpen: true,
                                    isFullMod: false,
                                    callback: () => {
                                      setCommentModal({
                                        isOpen: false
                                      })
                                      getData()
                                    }
                                  })
                                }>
                                + Добавить комментарий
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
      {data && (
        <div className='sticky shadow-default p-[17px] ml-[-18px] mr-[-18px] bg-[#fff] bottom-0 flex gap-[36px] justify-between'>
          {!isAdditionalWork && <ButtonRed onPress={onAdditionalWorkHandler} name='Требуется доработка'></ButtonRed>}
          <ButtonGrey onPress={() => setIsModalActionsOpened(true)} name='Действия с позицией'></ButtonGrey>
        </div>
      )}

      <Modal isOpen={isModalActionsOpened} closeModal={() => setIsModalActionsOpened(false)}>
        <div className='flex flex-col gap-[2.6rem] p-[70px] rounded-[8px]'>
          <ButtonGrey onPress={() => setIsModalCancelOpened(true)} name='Отменить позицию'></ButtonGrey>
          <ButtonBorderRound onPress={() => setIsModalCloseOpened(true)} name='Закрыть позицию'></ButtonBorderRound>
        </div>
      </Modal>
      <Modal isOpen={isModalCancelOpened} closeModal={() => setIsModalCancelOpened(false)}>
        <ConfirmModal
          title='Вы уверены, что хотите отменить позицию?'
          onResolve={onCancelPosition}
          onReject={() => setIsModalCancelOpened(false)}></ConfirmModal>
      </Modal>
      <Modal isOpen={isModalCloseOpened} closeModal={() => setIsModalCloseOpened(false)}>
        <ConfirmModal
          title='Вы уверены, что хотите закрыть позицию?'
          onResolve={onClosePosition}
          onReject={() => setIsModalCloseOpened(false)}></ConfirmModal>
      </Modal>
    </div>
  )
}

export default PositionModal
