import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://markli.by/'
})

export const publicAPI = {
  getPositionsStock: async query => {
    return instance.get(`rest/1/4cd8xq1lmhtz2yd4/product_stock.get${query ? `?articles=${query}` : ''}`)
  }
}
