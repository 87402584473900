import React from 'react'
import './App.css'
import { AuthContext } from './context/auth.context'
import { useAuth } from './hooks/auth.hook'
import { AxiosProvider } from './context/request.context'
import useCssVariables from './hooks/cssVariables.hook'
import './fontello/css/fontello.css'
import Desktop from './templates/desktop'
import Mobile from './templates/mobile'
import { useDevice } from './hooks/device.hook'
import { Metrika } from './components/metrika/Metrika'

function App() {
  const { authState, login, logout } = useAuth()

  const isMobile = useDevice()

  useCssVariables()

  return (
    <>
      <Metrika/>
      <AuthContext.Provider value={{ authState, login, logout }}>
        <AxiosProvider>
          {!isMobile ? <Desktop authState={authState}></Desktop> : <Mobile authState={authState}></Mobile>}
        </AxiosProvider>
      </AuthContext.Provider>
    </>

  )
}

export default App
