import Loader from '../../loader/loader'

function ButtonGrey({ name, onPress, disabled, loading, active, success }) {
  return (
    <div
      onClick={onPress}
      className={`${disabled ? 'pointer-events-none bg-Dominant/Dop' : 'bg-Dominant/Dop'} 
			inline-block py-[1.1rem] group px-[1.8rem]  hover:cursor-pointer  font-medium rounded-[8px] 
			 text-Content/Dark text-Regular(16_20) border border-1 ${active ? 'border-Accent/Red bg-[#FFFFFF]' : ''}
			 ${success && !disabled ? 'border-[#07af0e] bg-[#FFFFFF]' : ''}
			 `}>
      <span
        className={`relative group-hover:text-Content/Middle ${success && !disabled ? 'text-[#07af0e]' : 'text-Content/Middle'} ease-in-out duration-300 items-center  `}>
        {loading && <Loader></Loader>}
        <div
          className={`
					${loading ? 'opacity-0' : 'opacity-100'}
					flex gap-[0.8rem]
				`}>
          <span>{name}</span>
          {/* <i className="icon-arrow-right text-Regular(18_24)"></i> */}
        </div>
      </span>
    </div>
  )
}

export default ButtonGrey
