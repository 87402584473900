import React, { useEffect, useState } from 'react'
import ButtonGrey from '../ui/buttonGrey/buttonGrey'
import usePosition from '../../hooks/position.hook'
import DropdownHOC from '../dropdownHOC/dropdownHOC'

export const DrawingsControl = ({ editableData, setEditableData, setWarningModal, className }) => {
  const { getPrintList } = usePosition()
  const [erpPrints, setErpPrints] = useState([])
  const { drawings } = editableData || {}
  const isDrawingsExists = !!drawings?.length

  const getPrintListRequest = async () => {
    const res = await getPrintList()
    if (res?.data.length) {
      const updatedPrints = res.data
      setErpPrints(updatedPrints)
    }
  }

  useEffect(() => {
    getPrintListRequest()
  }, [])

  const selectDrawing = selectedDrawing => {
    const updatedDrawings = editableData.drawings.map(draw => {
      if (draw.id === selectedDrawing.drawingId) {
        if (draw.newAdded) {
          //newAdded = true в методе который мы добавили но еще не сохранили
          return {
            ...draw,
            print_group: selectedDrawing.print_group,
            code: selectedDrawing.code,
            name: selectedDrawing.name
          }
        } else {
          //если редактируется уже существующий метод,
          // добавляем в него еще один объект,
          //чтобы сохранить оригинальные данные для бэка
          return {
            ...draw,
            updateFor: { ...selectedDrawing, stamps: 0, logo: '' }
          }
        }
      } else {
        return draw
      }
    })

    const updatedEditableData = {
      ...editableData,
      drawings: updatedDrawings
    }
    setEditableData(updatedEditableData)
  }

  const onAddDrawing = () => {
    //дополняем редактируемый объект позиции нужными полями
    const tempId = crypto.randomUUID()
    const newAdded = true
    const updatedEditableData = {
      ...editableData,
      drawings: [
        ...editableData.drawings,
        {
          id: tempId,
          newAdded,
          code: null,
          name: 'Добавьте метод',
          stamps: 0,
          logo: ''
        }
      ]
    }
    setEditableData(updatedEditableData)
  }

  const onRemoveDrawing = (drawingId, groupCode) => {
    console.log(drawingId, groupCode)
    const updatedEditableData = {
      ...editableData,
      drawings: editableData.drawings.filter(dr => dr.id !== drawingId)
    }
    if (groupCode) {
      const clb = () => {
        setWarningModal({ isOpen: false })
        setEditableData(updatedEditableData)
      }
      setWarningModal({ isOpen: true, clb })
    } else {
      setEditableData(updatedEditableData)
    }
  }

  return (
    <div className={`flex flex-1 flex-col gap-[8px] justify-center w-[100%]`}>
      {isDrawingsExists &&
        drawings.map(draw => {
          const { name, id, code, updateFor, groupCode } = draw
          return (
            <div className={`grid grid-cols-[22.6rem_1fr] gap-[8px] ${className ? className : ''}`}>
              <div className={`flex-grow`}>
                <DropdownHOC
                  fixedWidth
                  minWidth={`min-w-[200px]`}
                  title={updateFor?.name || name}
                  items={erpPrints.map(el => ({
                    ...el,
                    selected: el.name === (updateFor?.name || name),
                    title: el.name,
                    drawingId: id,
                    onEvent: el => selectDrawing(el)
                  }))}></DropdownHOC>
              </div>
              <div className={`flex-grow-0 flex items-center`} onClick={() => onRemoveDrawing(id, groupCode)}>
                <span className='w-[2rem] h-[2rem] flex items-center justify-center hover:cursor-pointer hover:rotate-180 ease-in-out duration-300 hover:text-Accent/Blue'>
                  <i className='icon-close text-Regular(18_24) flex items-center justify-center'></i>
                </span>
              </div>
            </div>
          )
        })}
      <ButtonGrey onPress={onAddDrawing} name={'Добавить метод печати'} />
    </div>
  )
}
