import { useCallback, useEffect, useState } from 'react'
import dashbordStore from '../../../store/dashbord'
import useUsers from '../../../hooks/users.hook'
import OutsideAlerter from '../../outsideAlerter/outsideAlerter'
import Modal from '../../modal/modal-mobile'
import SettingsModal from '../../modals/settingsModal/settingsModal-mobile'
import { emmiter } from '../../../helpers/Emmiter'
import useSettings from '../../../hooks/settings.hook'

function Settings() {
  const { settings } = dashbordStore
  const [newSettings, setNewSettings] = useState({})
  const { sanitizeSettings, updateTableSettings } = useSettings()
  const { getMe, saveSettings } = useUsers()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setNewSettings(JSON.parse(JSON.stringify(settings.table || {})))
  }, [settings.table])

  const closeSettings = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onChange = key => {
    const item = newSettings[key]
    item.available = !item.available
    setNewSettings({ ...newSettings })
  }

  const onSubmit = async () => {
    await updateTableSettings(sanitizeSettings(newSettings))
    setIsOpen(false)
  }

  const selectAll = async isAvailable => {
    const settings = Object.entries(newSettings).reduce((acc, [key, item]) => {
      acc[key] = item
      if (!item.constant) {
        item.available = isAvailable
      }
      return acc
    }, {})
    setNewSettings(settings)
  }

  const onCancel = () => {
    setNewSettings(JSON.parse(JSON.stringify(settings)))
    setIsOpen(false)
  }

  useEffect(() => {
    ;(async () => {
      const { settings } = await getMe()
      // dashbordStore.setSettings(JSON.parse(JSON.stringify(mergeSettings(settings) || defaultSettings)));
    })()
  }, [getMe])

  return (
    <div className='relative'>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className='border hover:cursor-pointer hover:border-Accent/Blue ease-in-out duration-300 flex items-center justify-center border-Content/Border w-[42px] h-[42px] group'>
        <i className='icon-gear  group-hover:text-Accent/Blue text-Regular(18_24) text-Content/Light'></i>
      </div>
      <Modal title='Настроить таблицу' closeModal={() => setIsOpen(false)} isOpen={isOpen}>
        <SettingsModal
          newSettings={newSettings}
          onChange={onChange}
          onSubmit={onSubmit}
          onCancel={onCancel}
          selectAll={selectAll}></SettingsModal>
      </Modal>
    </div>
  )
}

export default Settings
