import { useEffect, useState } from 'react'
import Container from '../container/container'
import Switcher from '../ui/swticher/switcher'
import PrintLoading from '../printLoading/printLoading'
import OutsideAlerter from '../outsideAlerter/outsideAlerter'
import StatusLegend from '../statusLegend/statusLegend'
import stagesStore from '../../store/stage'
import { observer } from 'mobx-react-lite'
import Link from '../ui/link/link'
import useDashbord from '../../hooks/dashbord.hook'
import dashbordStore from '../../store/dashbord'
import ButtonIcon from '../ui/buttonIcon/buttonIcon'
import { StageStatuses } from '../stageStatuses/stageStatuses'
import usePosition from '../../hooks/position.hook'
import { emmiter } from '../../helpers/Emmiter'
import PrintsCountInfo from '../printsCountInfo/printsCountInfo'

function Footer() {
  const [loadingInfo, setLoadingInfo] = useState(false)
  const [printsCountInfo, setPrintsCountInfo] = useState(false)
  const [statusLegend, setStatusLegend] = useState(false)
  const { getDocsLink } = useDashbord()
  const [link, setLink] = useState(null)
  const { statuses, step } = stagesStore.currentStage || {}
  const selected = dashbordStore.getSelected()
  const { setStagesToUrgent, setPositionsToUrgent, setPositionsToCanceled } = usePosition()

  const finishAction = (getData = false) => {
    dashbordStore.clearSelected()
    getData && emmiter.emmit('triggerGetData')
  }

  const setPositionsToUrgentHandler = async () => {
    console.log(dashbordStore.selectedPositionsStages)
    // return;
    await setPositionsToUrgent({
      ids: dashbordStore.selectedPositionsStages.map(x => x.position_id),
      isUrgent: true
    })
    finishAction(true)
  }
  const setPositionsToCanceledHandler = async () => {
    await setPositionsToCanceled(dashbordStore.selectedPositionsStages.map(x => x.position_id))
    finishAction(true)
  }

  const hidePanel = () => {
    setLoadingInfo(false)
    setStatusLegend(false)
  }

  useEffect(() => {
    ;(async () => {
      const {
        data: { link }
      } = await getDocsLink()
      setLink(link)
    })()
  }, [])

  return (
    <OutsideAlerter
      onEvent={hidePanel}
      className={`

			relative
			sticky
			shadow-default
			bottom-0
			`}>
      <div
        className={`
				${loadingInfo || statusLegend || printsCountInfo ? ' z-[80]  shadow-negative' : 'max-h-[0] overflow-hidden'}
				 ease-out duration-300 top-0 left-0 right-0 bg-white border border-Content/Light border-l-0 border-r-0 border-t-0   pb-[0px]
				`}>
        <div className='flex flex-col gap-[3.6rem] py-[2.6rem]'>
          {loadingInfo && <PrintLoading></PrintLoading>}
          {printsCountInfo && <PrintsCountInfo></PrintsCountInfo>}
          {statusLegend && <StatusLegend statuses={statuses}></StatusLegend>}
        </div>
      </div>
      {!!selected?.length && (
        <div className='bg-white py-[1rem] border border-Content/Light border-t-0 border-r-0 border-l-0'>
          <Container>
            <div className='flex items-center justify-between gap-[30px]'>
              <div className={`flex gap-[2.6rem]`}>
                <ButtonIcon
                  onPress={() => window.print()}
                  Icon={() => <i className={`icon-stage-5 text-Accent/Blue text-Regular(18_24)`}></i>}
                  className='border-Accent/Blue'
                  name='Отправить на печать'></ButtonIcon>
                <ButtonIcon
                  onPress={setPositionsToUrgentHandler}
                  Icon={() => (
                    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <g clipPath='url(#clip0_902_27652)'>
                        <path
                          d='M10 11.8335C9.77342 11.8335 9.55188 11.9007 9.36345 12.0266C9.17502 12.1525 9.02816 12.3315 8.94143 12.5408C8.85471 12.7502 8.83201 12.9806 8.87623 13.2029C8.92044 13.4251 9.02957 13.6293 9.18982 13.7896C9.35006 13.9498 9.55423 14.0589 9.7765 14.1031C9.99877 14.1474 10.2292 14.1247 10.4385 14.0379C10.6479 13.9512 10.8269 13.8043 10.9528 13.6159C11.0787 13.4275 11.1459 13.2059 11.1459 12.9793C11.1459 12.6754 11.0252 12.384 10.8103 12.1691C10.5954 11.9542 10.3039 11.8335 10 11.8335ZM10 10.4585C10.2432 10.4585 10.4763 10.3619 10.6482 10.19C10.8201 10.0181 10.9167 9.78494 10.9167 9.54183V6.79183C10.9167 6.54871 10.8201 6.31555 10.6482 6.14365C10.4763 5.97174 10.2432 5.87516 10 5.87516C9.75693 5.87516 9.52377 5.97174 9.35186 6.14365C9.17995 6.31555 9.08338 6.54871 9.08338 6.79183V9.54183C9.08338 9.78494 9.17995 10.0181 9.35186 10.19C9.52377 10.3619 9.75693 10.4585 10 10.4585ZM10 0.833496C8.18705 0.833496 6.41477 1.37111 4.90732 2.37836C3.39987 3.3856 2.22495 4.81724 1.53115 6.49223C0.837346 8.16722 0.655815 10.0103 1.00951 11.7885C1.36321 13.5666 2.23625 15.2 3.51823 16.482C4.80021 17.764 6.43356 18.637 8.21172 18.9907C9.98987 19.3444 11.833 19.1629 13.508 18.4691C15.183 17.7752 16.6146 16.6003 17.6218 15.0929C18.6291 13.5854 19.1667 11.8132 19.1667 10.0002C19.164 7.56984 18.1974 5.23983 16.4789 3.52134C14.7604 1.80284 12.4304 0.836199 10 0.833496ZM10 17.3335C8.54965 17.3335 7.13182 16.9034 5.92586 16.0976C4.7199 15.2918 3.77997 14.1465 3.22493 12.8065C2.66989 11.4665 2.52466 9.99202 2.80762 8.5695C3.09058 7.14697 3.78901 5.8403 4.81459 4.81471C5.84018 3.78913 7.14685 3.0907 8.56938 2.80774C9.99191 2.52478 11.4664 2.67 12.8064 3.22505C14.1464 3.78009 15.2917 4.72002 16.0975 5.92598C16.9033 7.13194 17.3334 8.54976 17.3334 10.0002C17.3311 11.9444 16.5578 13.8084 15.183 15.1831C13.8082 16.5579 11.9443 17.3313 10 17.3335Z'
                          fill='#E30613'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_902_27652'>
                          <rect width='20' height='20' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  className='border-Accent/Red'
                  name='Сделать срочными'></ButtonIcon>
                <ButtonIcon
                  onPress={setPositionsToCanceledHandler}
                  Icon={() => <i className={`icon-stage-12 text-gray-300 text-Regular(18_24)`}></i>}
                  className='border-gray-300'
                  name='Отменить позиции'></ButtonIcon>
              </div>
              {!!statuses && <StageStatuses stageStatuses={statuses} step={step} />}
            </div>
          </Container>
        </div>
      )}

      <div className='py-[1.8rem] bg-white relative z-[100]'>
        <Container>
          <div className='pb-[1.2rem] flex gap-[2.6rem] justify-between items-center'>
            {/* <Switcher
			label="Загрузка специалистов"></Switcher> */}
            <div className={`flex gap-[20px]`}>
              <Switcher
                active={printsCountInfo}
                onChange={() => setPrintsCountInfo(!printsCountInfo)}
                label='Загрузка печати'></Switcher>
              {/*<Switcher*/}
              {/*    active={loadingInfo}*/}
              {/*    onChange={() => setLoadingInfo(!loadingInfo)}*/}
              {/*    label="Загрузка печати"*/}
              {/*></Switcher>*/}
            </div>
            {stagesStore.currentStage?.id && (
              <Switcher
                active={statusLegend}
                onChange={() => setStatusLegend(!statusLegend)}
                label='Подсказка по статусам'></Switcher>
            )}
          </div>
          <div className='flex justify-between pt-[1.2rem] border border-Content/Border border-l-0 border-r-0 border-b-0'>
            <span className='block text-Regular(16_18) text-Content/Dark'>Маркли, 2023. Управление производством</span>
            <div className='flex gap-[6rem] items-center'>
              {link && <Link blank={true} href={link} name='Документация системы'></Link>}

              <span className='block text-Regular(16_18) text-Content/Dark'>Версия 1.0.0</span>
            </div>
          </div>
        </Container>
      </div>
    </OutsideAlerter>
  )
}

export default observer(Footer)
