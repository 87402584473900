import Button from '../../ui/button/button'
import ButtonSimple from '../../ui/buttonSimple/buttonSimple'
import ButtonBorder from '../../ui/buttonBorder/buttonBorder'
import Checkbox from '../../ui/checkbox/checkbox-mobile'
import DefaultModal from '../defaultModal/defaultModal-mobile'
import Multiselect from '../../multiselect/multiselect'
import Input from '../../ui/input/input'
import Datepicker from '../../ui/datepicker/datepicker'

function SearchModal({ formRef, updateScheme, statuses, users, stages, isAllMod, schemeRef, onSubmit }) {
  return (
    <DefaultModal>
      <div ref={formRef}>
        <div className='flex flex-col gap-[1.2rem]'>
          {isAllMod ? (
            <Multiselect
              onEvent={selected =>
                updateScheme({
                  key: 'stage',
                  path: 'stage.id',
                  type: 'multi',
                  label: 'Этап',
                  val: selected
                })
              }
              current={schemeRef.current['stage.id']}
              label='Этап'
              placeholder='Не выбран'
              items={stages}></Multiselect>
          ) : (
            <Multiselect
              onEvent={selected =>
                updateScheme({
                  key: 'status',
                  path: 'status.id',
                  type: 'multi',
                  label: 'Статус',
                  val: selected
                })
              }
              current={schemeRef.current['status.id']}
              label='Статус'
              placeholder='Не выбран'
              items={statuses}></Multiselect>
          )}

          <Multiselect
            onEvent={selected =>
              updateScheme({
                key: 'user',
                path: 'user.id',
                type: 'multi',
                label: 'Ответственный',
                val: selected
              })
            }
            current={schemeRef.current['user.id']}
            label='Ответственный'
            placeholder='Не выбран'
            items={users}></Multiselect>
          <Input
            onInput={({ target: { value } }) =>
              updateScheme({
                key: 'position',
                path: 'position.order.orderId',
                type: 'search',
                label: 'Номер заказа',
                val: value
              })
            }
            initial={schemeRef.current['position.order.orderId']?.val}
            labelSmall={true}
            label='Номер заказа'></Input>
          <Input
            onInput={({ target: { value } }) =>
              updateScheme({
                key: 'position',
                path: 'position.order.company',
                type: 'search',
                label: 'Заказчик',
                val: value
              })
            }
            initial={schemeRef.current['position.order.username']?.val}
            labelSmall={true}
            label='Заказчик'></Input>
          <Input
            onInput={({ target: { value } }) =>
              updateScheme({
                key: 'position',
                path: 'position.code',
                type: 'search',
                label: 'Артикул',
                val: value
              })
            }
            initial={schemeRef.current['position.code']?.val}
            labelSmall={true}
            label='Артикул'></Input>

          <Datepicker
            onChange={({ value }) =>
              updateScheme({
                key: 'position',
                path: 'position.order.date',
                type: 'search',
                label: 'Дата',
                val: value
              })
            }
            initial={schemeRef.current['position.order.date']?.val}
            labelSmall={true}
            label='Дата'></Datepicker>
        </div>

        <div className='mt-[2.6rem]'>
          <Button onPress={onSubmit} name='Применить'></Button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default SearchModal
