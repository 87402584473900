import { useContext, useCallback } from 'react'
import { AxiosContext } from '../context/request.context'
const qs = require('qs')

function useProblems() {
  const { authRequest } = useContext(AxiosContext)

  const getProblems = useCallback(async () => {
    return await authRequest({
      url: `problems`
    })
  }, [authRequest])

  return {
    getProblems
  }
}

export default useProblems
