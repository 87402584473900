import DropdownHOC from '../dropdownHOC/dropdownHOC'
import dashbordStore from '../../store/dashbord'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import useSettings from '../../hooks/settings.hook'

function SortDropdown() {
  const {
    settings: { sort, groupView }
  } = dashbordStore

  const { updateSettings } = useSettings()

  useEffect(() => {
    if (groupView && sort.path !== 'position.order.date') {
      // dashbordStore.prevSort = dashbordStore.sort;
      dashbordStore.setSort({
        path: 'position.order.date',
        name: 'Новые наверх',
        correction: 'desc'
      })
    } else {
      // dashbordStore.setSort(dashbordStore.prevSort);
    }
  }, [groupView])

  const onSortHandler = ({ name, _default, custom = false, path, correction }) => {
    const options = {
      name,
      _default,
      custom
    }

    if (path) {
      delete options._default
      options.path = path
    }

    if (correction) {
      options.correction = correction
    }

    dashbordStore.setSort(options)
    updateSettings()
  }

  if (!sort) return null

  return (
    <div className='min-w-[17.5rem]'>
      {!groupView ? (
        <DropdownHOC
          title={sort.name || 'Срочные наверх'}
          items={[
            {
              onEvent: () =>
                onSortHandler({
                  name: 'Срочные наверх',
                  _default: ['isUrgent:desc', 'id:desc']
                }),
              color: '#E30613',
              title: 'Срочные наверх'
            },
            {
              onEvent: () =>
                onSortHandler({
                  name: 'Старые наверх',
                  _default: ['createdAt:asc']
                }),
              color: '#D5E6FF',
              title: 'Старые наверх'
            },
            {
              onEvent: () =>
                onSortHandler({
                  name: 'Новые наверх',
                  _default: ['createdAt:desc']
                }),
              color: '#E4F3D2',
              title: 'Новые наверх'
            },
            {
              onEvent: () =>
                onSortHandler({
                  name: 'По приоритету',
                  _default: ['sort:desc, id:desc'],
                  custom: true
                }),
              color: '#FDF6E3',
              title: 'По приоритету'
            }
          ]}></DropdownHOC>
      ) : (
        <DropdownHOC
          title={sort.name}
          items={[
            {
              onEvent: () =>
                onSortHandler({
                  name: 'Срочные наверх',
                  _default: ['isUrgent:desc', 'id:desc']
                }),
              color: '#E30613',
              title: 'Срочные наверх'
            },
            {
              onEvent: () =>
                onSortHandler({
                  name: 'Старые наверх',
                  correction: 'asc',
                  path: 'position.order.date'
                }),
              color: '#D5E6FF',
              title: 'Старые наверх'
            },
            {
              onEvent: () =>
                onSortHandler({
                  name: 'Новые наверх',
                  correction: 'desc',
                  path: 'position.order.date'
                }),
              color: '#E4F3D2',
              title: 'Новые наверх'
            }
          ]}></DropdownHOC>
      )}
    </div>
  )
}

export default observer(SortDropdown)
