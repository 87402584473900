import Loader from '../../loader/loader'

function ButtonBorderRound({ name, onPress, disabled, loading }) {
  return (
    <div
      onClick={onPress}
      className={`
				${disabled ? 'pointer-events-none border-Dominant/Dop' : 'border-Accent/Red'}
				border border-1  inline-block py-[1.1rem] group px-[1.8rem]  hover:cursor-pointer  font-medium rounded-[8px]  text-Content/Dark text-Regular(16_20)`}>
      <span
        span
        className='relative group-hover:text-Content/Middle text-Content/Dark ease-in-out duration-300 items-center '>
        {loading && <Loader></Loader>}
        <div
          className={`
					${loading ? 'opacity-0' : 'opacity-100'}
					flex gap-[0.8rem]
				`}>
          <span>{name}</span>
          {/* <i className="icon-arrow-right text-Regular(18_24)"></i> */}
        </div>
      </span>
    </div>
  )
}

export default ButtonBorderRound
