import { useCallback, useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import Comment from '../comment/comment-mobile'
import OutsideAlerter from '../outsideAlerter/outsideAlerter'

function Comments({ comments }) {
  const [isOpen, setIsOpen] = useState(false)
  const closeComments = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    const handler = () => {
      setIsOpen(false)
    }
    document.addEventListener('scroll', handler)
    return () => document.removeEventListener('scroll', handler)
  }, [])

  return (
    <OutsideAlerter
      onEvent={closeComments}
      className={`
			${isOpen ? 'z-[21]' : 'z-[20]'}	
			flex justify-center items-center w-[30px] h-[30px]
			`}>
      <i
        onClick={() => setIsOpen(!isOpen)}
        data-tooltip-id={'my-tooltip'}
        data-tooltip-position-strategy='fixed'
        className={`
					${!isOpen ? ' icon-comment text-Regular(24_30)' : ' icon-close text-[30px]'}
					text-Regular(24_30) hover:cursor-pointer  text-Accent/Blue`}></i>
      <Tooltip
        className='comment-tooltip'
        isOpen={isOpen}
        openOnClick={true}
        opacity='1'
        style={{
          backgroundColor: '#fff',
          padding: 0,
          boxShadow: 'none',
          pointerEvents: 'auto',
          zIndex: 10
        }}
        id={'my-tooltip'}>
        <Comment comments={comments}></Comment>
      </Tooltip>
    </OutsideAlerter>
  )
}

export default Comments
