import { date } from '../../utils/date'

function Comment({ comments, setIsOpen }) {
  return (
    <div className='w-[29rem] p-[1.2rem] max-h-[40rem] overflow-auto   shadow-default bg-white text-Content/Dark'>
      <i
        onClick={() => setIsOpen(false)}
        className='icon-close text-Regular(18_24) hover:cursor-pointer hover:text-Accent/Blue   flex justify-center items-center absolute top-[1rem] right-[1rem]'></i>
      {comments.map(x => (
        <div className='border border-Content/Border gap-[1.2rem] border-t-0 border-l-0 border-r-0 flex flex-col py-[2rem] last:border-none first:pt-[0px] last:pb-[0]'>
          {(x.roleOwner || x.nameOwner) && (
            <span className='text-Regular(16_18)'>
              <span>{x.roleOwner} </span>
              <span className='font-medium'>{x.nameOwner}</span>
            </span>
          )}
          <span className='text-Regular(16_18)'>{x.comment}</span>
          {x.createdAt && (
            <span className='text-Regular(14_16) text-Content/Middle'>{date.transform(x.createdAt)}</span>
          )}
        </div>
      ))}
    </div>
  )
}

export default Comment
