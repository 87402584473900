import Loader from '../../loader/loader'

function ButtonGrey({ name, onPress, disabled, loading }) {
  return (
    <div
      onClick={onPress}
      className={`
				${disabled ? 'pointer-events-none bg-Dominant/Dop' : 'bg-Dominant/Dop'}
				inline-block py-[0.6rem] group px-[1.2rem]  hover:cursor-pointer  font-medium rounded-[8px] h-[36px] flex items-center  text-Content/Dark text-Regular(14_16)`}>
      <span
        span
        className='relative group-hover:text-Content/Middle text-Content/Dark ease-in-out duration-300 items-center '>
        {loading && <Loader></Loader>}
        <div
          className={`
					${loading ? 'opacity-0' : 'opacity-100'}
					flex gap-[0.8rem]
				`}>
          <span>{name}</span>
          {/* <i className="icon-arrow-right text-Regular(18_24)"></i> */}
        </div>
      </span>
    </div>
  )
}

export default ButtonGrey
