import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Button from '../../ui/button/button'
import dashbordStore from '../../../store/dashbord'
import Checkbox from '../../ui/checkbox/checkbox'
import useUsers from '../../../hooks/users.hook'
import ButtonBorder from '../../ui/buttonBorder/buttonBorder'
import ButtonSimple from '../../ui/buttonSimple/buttonSimple'
import OutsideAlerter from '../../outsideAlerter/outsideAlerter'
import useSettings from '../../../hooks/settings.hook'

function Settings() {
  const { settings } = dashbordStore
  const { sanitizeSettings, updateTableSettings } = useSettings()
  const [newSettings, setNewSettings] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setNewSettings(JSON.parse(JSON.stringify(settings.table || {})))
  }, [settings.table])

  const closeSettings = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onChange = key => {
    const item = newSettings[key]
    item.available = !item.available
    setNewSettings({ ...newSettings })
  }

  const onSubmit = async () => {
    await updateTableSettings(sanitizeSettings(newSettings))
    setIsOpen(false)
  }

  const selectAll = async isAvailable => {
    const settings = Object.entries(newSettings).reduce((acc, [key, item]) => {
      acc[key] = item
      if (!item.constant) {
        item.available = isAvailable
      }
      return acc
    }, {})
    setNewSettings(settings)
  }

  const handler = ev => {
    if (ev.key === 'Enter') {
      onSubmit()
    }
    if (ev.key === 'Escape') {
      setIsOpen(false)
    }
  }

  const onCancel = () => {
    setNewSettings(JSON.parse(JSON.stringify(settings)))
    setIsOpen(false)
  }

  return (
    <OutsideAlerter onEvent={closeSettings}>
      <div className='relative'>
        <div
          onKeyDown={handler}
          tabIndex={0}
          onClick={() => setIsOpen(!isOpen)}
          className='border hover:cursor-pointer hover:border-Accent/Blue ease-in-out duration-300 flex items-center justify-center border-Content/Border w-[4.6rem] h-[4.6rem] group'>
          <i className='icon-gear  group-hover:text-Accent/Blue text-Regular(18_24) text-Content/Light'></i>
        </div>

        {isOpen && (
          <div
            onKeyDown={handler}
            tabIndex={0}
            className='border z-30 w-[80rem] bg-white border-Content/Border absolute top-[100%] left-0'>
            <div className='flex border-Dominant/Dop border border-t-0 border-r-0 border-l-0 justify-between p-[2.6rem]'>
              <span className='block text-Regular(16_18)'>Настроить таблицу</span>
              <i
                onClick={closeSettings}
                className='hover:cursor-pointer icon-close absolute right-[2rem] top-[2rem] text-Regular(24_30)'></i>
            </div>
            <div className='py-[1.4rem] px-[2.6rem] grid grid-cols-3'>
              {Object.entries(newSettings || {})
                .filter(([_, item]) => item.configurable && !item.constant)
                .map(([key, item]) => {
                  if (key == 'stage') return null
                  return (
                    <div key={key} className='py-[0.9rem]'>
                      {item.available}
                      <Checkbox onChange={() => onChange(key)} label={item.title} active={item.available}></Checkbox>
                    </div>
                  )
                })}
            </div>
            <div className='p-[2.6rem] flex justify-between items-center'>
              {/* <span>По умолчанию</span> */}
              <div className='flex gap-[1.2rem]'>
                <Button onPress={onSubmit} name='Применить'></Button>
                <ButtonSimple onPress={onCancel} name='Отменить'></ButtonSimple>
              </div>
              <div className='flex gap-[1.8rem]'>
                <ButtonBorder onPress={() => selectAll(true)} name='Выбрать всё'></ButtonBorder>
                <ButtonBorder onPress={() => selectAll(false)} name='Отменить всё'></ButtonBorder>
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideAlerter>
  )
}

export default observer(Settings)
