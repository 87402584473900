import { useEffect, useState } from 'react'
import useDashbord from '../../hooks/dashbord.hook'
import Loader from '../loader/loader'

function PrintsCountInfo() {
  const { getPrintsCount } = useDashbord()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      const data = await getPrintsCount()
      setData(data)
      setLoading(false)
    })()
  }, [getPrintsCount])

  const Td = ({ name, children, className }) => {
    return (
      <td
        className={`
			${className}
			px-[2rem] py-[2.3rem]  border border-Content/Border`}>
        {children || name}
      </td>
    )
  }

  return (
    <div className={`overflow-x-auto`}>
      <div className='text-Regular(16_18) font-semibold mb-[1.2rem] text-center'>Загрузка печати</div>
      {loading ? (
        <Loader></Loader>
      ) : (
        <table className={`m-[0_auto]`}>
          <tbody>
            <tr className='text-Regular(14_16) font-semibold text-center'>
              <Td className='hover:cursor-pointer w-[16rem] bg-[#E1CCE7]'></Td>
              {Object.values(data.drawings || {}).map(x => (
                <Td name={x.name}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name='Итого'></Td>
            </tr>
            <tr className='text-Regular(16_18) text-center'>
              <Td className='bg-[#E1CCE7] font-semibold' name='Тираж, шт'></Td>
              {Object.values(data.drawings || {}).map(({ circulation }) => (
                <Td name={circulation}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name={data.totalCirculation}></Td>
            </tr>
            <tr className='text-Regular(16_18) text-center'>
              <Td className='bg-[#E1CCE7] font-semibold' name='Отпечатков, шт'></Td>
              {Object.values(data.drawings || {}).map(({ prints }) => (
                <Td name={prints}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name={data.totalPrints}></Td>
            </tr>
            <tr className='text-Regular(16_18) text-center'>
              <Td className='bg-[#E1CCE7] font-semibold' name='Нанесений, шт'></Td>
              {Object.values(data.drawings || {}).map(({ application }) => (
                <Td name={application}></Td>
              ))}
              <Td className='bg-[#D5E6FF] w-[16rem] font-semibold' name={data.totalApplication}></Td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}

export default PrintsCountInfo
