import { useEffect, useState } from 'react'
import usersStore from '../../../store/users'
import stageStore from '../../../store/stage'
import Input from '../../ui/input/input'
import Button from '../../ui/button/button'
import Modal from '../../modal/modal-mobile'
import { emmiter } from '../../../helpers/Emmiter'
import { getDeclination } from '../../../helpers/common'
import DropdownHOC from '../../dropdownHOC/dropdownHOC'
import usePosition from '../../../hooks/position.hook'

function StampProgressModal() {
  const [initialValue, setInitialValue] = useState(0)
  const [seed, setSeed] = useState(1)
  const [modal, setModal] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [user, setUser] = useState(null)
  const { setPrintQuantity } = usePosition()
  const [subusers, setSubusers] = useState([])
  const { username, id: userId } = user || {}
  const { drawings = [], drawingId } = modal

  const role = stageStore.currentStage.m_role
  const candidates = usersStore.users
    .filter(x => x.m_roles.find(y => y.id === role?.id))
    .map(x => ({ title: x.username, onEvent: () => onSetUser(x) }))

  const total = subusers.reduce((acc, curr) => {
    return (acc += curr.readyCount)
  }, 0)

  const remain = modal.quantity - total < 0 ? 0 : modal.quantity - total
  const { readyCount: currentUserCount = 0 } = subusers.find(x => x.user.id === user?.id) || {}
  const maxValue = remain + currentUserCount

  useEffect(() => {
    emmiter.on('stampProgressModal', data => {
      setModal(data)
    })
    return () => emmiter.off('stampProgressModal')
  }, [])

  const onSetQuantity = value => {
    if (+value <= remain) {
      setQuantity(value)
    } else {
      setQuantity(remain)
    }
  }

  const onSetUser = user => {
    setUser(user)
  }

  const onSubmit = async () => {
    const { drawingId, positionStageId, getData } = modal
    const { subusers: updated } = await setPrintQuantity({
      positionStageId,
      userId,
      subusers,
      quantity,
      drawingId
    })
    setSeed(Math.random())
    setSubusers(updated)
    setUser(null)
    setQuantity(null)
    getData(true)
    setInitialValue(0)
  }

  useEffect(() => {
    setSubusers(drawings.find(x => x.id === drawingId)?.subusers || [])
  }, [modal])

  useEffect(() => {
    if (user && quantity) {
      if (quantity > maxValue) {
        setInitialValue(maxValue)
      }
    }
  }, [maxValue, quantity, user])

  return (
    <Modal isOpen={modal} closeModal={() => setModal(false)}>
      <div className='w-[90vw] max-w-[76rem] min-h-[10rem] pt-[0rem]'>
        <span className='block text-center text-Regular(38_42) text-Content/Dark mb-[2rem]'>Прогресс печати</span>
        <div className='text-Regular(14_16) text-center text-Content/Middle'>{modal.name}</div>
        <div className='text-Regular(16_20) text-center mt-[0.5rem]'>
          {`${total || 0} ${getDeclination(total, ['штук', 'штук', 'штук'])} (осталось ${remain})`}
        </div>
        <div className='p-[2rem]'>
          <div className='py-[3rem] px-[2rem] bg-Dominant/Dop '>
            <span className='text-Regular(16_18)'>
              {modal.title} - <span className='font-medium'>{modal.code}</span>{' '}
            </span>
            <div className='flex flex-col mt-10'>
              <div className='text-Regular(16_18) font-medium'>Исполнители:</div>
              <div className='flex flex-col'>
                {subusers.map(({ readyCount, user: { username } }) => (
                  <span className='text-Regular(16_18)'>
                    {username}: {readyCount}
                  </span>
                ))}
              </div>
            </div>
            <div className='mt-[3.8rem]'>
              <div className='flex flex-col gap-[1rem]'>
                <Input
                  key={seed}
                  type='number'
                  onInput={({ target: { value } }) => onSetQuantity(value)}
                  maxValue={maxValue}
                  placeholder='Введите число'
                  label='Сколько вы уже распечатали?'
                  initial={initialValue || ''}></Input>
                <DropdownHOC
                  items={candidates}
                  title={username || 'Выберите исполнителя'}
                  className='flex items-center h-[100%] bg-white'></DropdownHOC>
              </div>
            </div>

            <div className='flex justify-center mt-[3.8rem]'>
              <Button name='Сохранить' onPress={onSubmit}></Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StampProgressModal
