function ButtonResolveMobile({ name, onPress }) {
  return (
    <div
      onClick={onPress}
      className='hover:bg-Accent/Light_Green hover:cursor-pointer duration-300 ease-in-out  py-[0.6rem] group px-[1.2rem]  font-medium rounded-[8px] bg-Accent/Green text-white text-Regular(14_16)'>
      <span className='flex justify-center  items-center gap-[0.8rem]'>
        <span>{name}</span>
        <i className='icon-fcheck-circle text-Regular(18_24)'></i>
      </span>
    </div>
  )
}

export default ButtonResolveMobile
