import React, { useEffect, useState } from 'react'
import usersStore from '../../../store/users'
import Fancybox from '../../fancybox/fancybox'
import ProductSetLabel from '../../productSetLabel/ProductSetLabel'
import ButtonRed from '../../ui/buttonRed/buttonRed'
import { emmiter } from '../../../helpers/Emmiter'
import ButtonGrey from '../../ui/buttonGrey/buttonGrey'
import ButtonBorderRound from '../../ui/buttonBorderRound/buttonBorderRound'
import { setItemsParser } from '../../../utils/setItemsParser'
import Input from '../../ui/input/input'
import { DrawingsControl } from '../../drawingsControl/DrawingsControl'
import Loader from '../../loader/loader'
import Modal from '../../modal/modal'

export const PositionBlock = ({
  data,
  getData,
  isAdditionalWork,
  setStickerModal,
  onSetPositionModalPrint,
  setIsModalCancelOpened,
  setIsModalCloseOpened,
  currenStage,
  editPosition,
  getDashboardData,
  showOrderKitModal,
  originalCPositions
}) => {
  const { order: { id, localComment, managerComment } = {}, code, title, quantity, kitID, id: positionId } = data || {}
  let orderComment = localComment?.comment || managerComment

  const isEditControlsShow = ([2, 3, 4, 5].includes(currenStage.step)) && originalCPositions?.length > 8
  //TODO && originalCPositions?.length > 8 можно будет убрать (проверка для старых заказов, если они были без МП, их редактировать не получится
  const [editMode, setEditMode] = useState(false)
  const [editableData, setEditableData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [warningModal, setWarningModal] = useState({ isOpen: false })
  const [beforeSaveModal, setBeforeSaveModal] = useState(false)


  const onOpenSetModal = e => {
    e.stopPropagation()
    showOrderKitModal(id, kitID)
  }

  const onAdditionalWorkHandler = async () => {
    const { id } = data
    const {
      stage: { id: stageId }
    } = data.c_position_stages.find(x => x.stage.additionalWork)

    emmiter.emmit('setMoveToStageModal', {
      positionId: id,
      isOpen: true,
      callback: () => getData(true)
    })
  }

  const setOrderComment = ({ orderId, comment = '' }) => {
    emmiter.emmit('setOrderCommentModal', {
      isOpen: true,
      orderId: orderId || data.order.id,
      comment,
      callback: getData
    })
  }

  const onSetEditMode = () => {
    const editablePositionState = {
      code,
      title,
      quantity,
      kitID,
      drawings: data.drawings
    }
    setEditableData(editablePositionState)
    setEditMode(!editMode)
  }

  const onCommonDataChange = (e, type) => {
    setEditableData({ ...editableData, [type]: e.target.value })
  }

  const onDrawingAdditionsChange = (e, type, id) => {
    const updatedEditableData = {
      ...editableData,
      drawings: editableData.drawings.map(el => {
        if (el.id === id) {
          const isExistedDrawingEdit = !!el.code
          if (isExistedDrawingEdit) {
            return { ...el, [type]: e.target.value }
          } else {
            return { ...el, [type]: e.target.value }
          }
        } else {
          return el
        }
      })
    }
    setEditableData(updatedEditableData)
  }

  const onEditSave = async () => {
    try {
      setBeforeSaveModal(false)
      setIsLoading(true)
      await editPosition(positionId, editableData)
      setEditMode(false)
      await Promise.all([getData(true), getDashboardData(true)])
    } catch (e) {
      console.error('edit error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && (
        <div className={`absolute top-0 left-0 right-0 bottom-0 bg-[#000000] opacity-[0.5] z-[10]`}>
          <div className={`relative w-100% h-[50vh]`}>
            <Loader></Loader>
          </div>
        </div>
      )}
      <div
        className={`py-[3rem] flex flex-col items-start border border-Content/Border border-l-0 border-r-0 border-t-0`}>
        <div className='flex items-center mb-[3rem] w-[100%] gap-[1rem]'>
          {!!orderComment && (
            <div className='  gap-[1rem] bg-Accent/Light_Yellow  text-Regular(14_16) px-[1rem] py-[2px] leading-1'>
              <span className='font-medium'>Комментарий администратора к заказу:</span>
              <span> {orderComment}</span>
            </div>
          )}
          {usersStore.isGodMod() && (
            <div
              onClick={() =>
                setOrderComment({
                  id,
                  comment: orderComment
                })
              }
              className=' ease-in-out flex-none duration-300 bg-Content/Border w-[5rem] ml-[auto] flex justify-center p-[2px] hover:cursor-pointer hover:opacity-60'>
              <i className={`icon-comment text-Regular(24_30) hover:cursor-pointer  text-Accent/Blue`}></i>
            </div>
          )}
        </div>
        <div className='border border-Content/Border border-t-0 border-r-0 border-b-0 w-[100%]'>
          <div className='grid grid-table-row'>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Фото
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Артикул
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Название
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Количество
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Метод печати
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Логотип
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border bg-Dominant/Light text-Regular(12_14) font-semibold'>
              Кол-во цветов
            </div>
          </div>
          <div className='text-Regular(16_18) grid grid-table-row__v2'>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] text-center border border-Content/Border'>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false
                  }
                }}>
                <div
                  data-fancybox='gallery'
                  href={data.image}
                  className='h-[7rem] flex justify-center items-center hover:cursor-pointer'>
                  <img className='max-w-[100%] max-h-[100%]' src={data.image} alt='' />
                </div>
              </Fancybox>
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] break-all border border-Content/Border flex flex-col justify-center'>
              {kitID && !editMode && (
                <div className={`w-max mb-[4px]`}>
                  <ProductSetLabel kitID={kitID} onOpenSetModal={onOpenSetModal} large />
                </div>
              )}
              {editMode ? (
                <div>
                  <Input border={true} initial={editableData.code} onInput={e => onCommonDataChange(e, 'code')}></Input>
                </div>
              ) : (
                <div>{code}</div>
              )}
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border flex flex-col justify-center'>
              {editMode ? (
                <div>
                  <Input
                    border={true}
                    initial={editableData.title}
                    onInput={e => onCommonDataChange(e, 'title')}></Input>
                </div>
              ) : data.link ? (
                <a
                  className='underline border-t-0 border-r-0 border-l-0 text-Accent/Blue hover:cursor-pointer hover:opacity-80 ease-out duration-300'
                  href={`https://markli.by${data.link}`}
                  target='_blank'>
                  {title}
                </a>
              ) : (
                <span>{title}</span>
              )}
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border border-t-[0] items-center text-center flex justify-center'>
              <div className='relative'>
                {data.quantityUnderOrder && (
                  <div className='absolute flex gap-[0.3rem] left-[100%] bottom-[100%]  text-Regular(12_14)'>
                    <span className='text-Accent/Green'> {data.quantityInStock}</span>/{' '}
                    <span className='text-Accent/Orange'>{data.quantityUnderOrder}</span>
                  </div>
                )}
                {editMode ? (
                  <div>
                    <Input
                      type={'number'}
                      border={true}
                      initial={editableData.quantity}
                      onInput={e => onCommonDataChange(e, 'quantity')}></Input>
                  </div>
                ) : (
                  <div>{quantity}</div>
                )}
              </div>
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border '>
              <div className='flex flex-col h-[100%] gap-[0.4rem]'>
                {editMode ? (
                  <DrawingsControl
                    className={`grid-cols-[minmax(10rem,_1fr)_2rem]`}
                    setWarningModal={setWarningModal}
                    editableData={editableData}
                    setEditableData={setEditableData}
                  />
                ) : (
                  <>
                    {!data.drawings.length && '-'}
                    {data.drawings.map(({ name }) => (
                      <span key={name} className='line-clamp-1'>
                        {name}
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border'>
              {editMode ? (
                <div className={`flex flex-col gap-[8px]`}>
                  {!editableData.drawings.length ? (
                    <Input disabled small border={true} initial={'-'}></Input>
                  ) : (
                    editableData.drawings.map(({ logo, updateFor, id }) => (
                      <Input
                        key={id}
                        small
                        border={true}
                        onInput={e => onDrawingAdditionsChange(e, 'logo', id)}
                        initial={logo || '-'}></Input>
                    ))
                  )}
                </div>
              ) : (
                <div className='flex flex-col gap-[0.4rem]'>
                  {!data.drawings.length && '-'}
                  {data.drawings.map(({ logo }) => (
                    <span key={crypto.randomUUID()} className='text-center'>
                      {logo || '-'}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <div className='p-[1.2rem] mt-[-1px] ml-[-1px] border border-Content/Border '>
              {editMode ? (
                <div className={`flex flex-col gap-[8px]`}>
                  {!editableData.drawings.length ? (
                    <Input small disabled border={true} initial={'-'}></Input>
                  ) : (
                    editableData.drawings.map(({ stamps, id }) => (
                      <Input
                        key={id}
                        small
                        border={true}
                        onInput={e => onDrawingAdditionsChange(e, 'stamps', id)}
                        initial={stamps || '1'}></Input>
                    ))
                  )}
                </div>
              ) : (
                <div className='flex flex-col gap-[0.4rem]'>
                  {!data.drawings.length && '-'}
                  {data.drawings.map(({ stamps }) => (
                    <span key={crypto.randomUUID()} className='line-clamp-1 text-center'>
                      {stamps || 1}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {isEditControlsShow && (
          <div className={`flex items-center justify-end w-[100%] mt-[1.8rem] gap-[18px]`}>
            {editMode && (
              <div className={`flex items-center justify-between flex-1`}>
                <div>
                  <div className={`text-Regular(12_14) font-semibold mb-[6px]`}>Номер набора</div>
                  <Input
                    placeholder={'Номер набора'}
                    small
                    border={true}
                    onInput={e => onCommonDataChange(e, 'kitID')}
                    initial={kitID || ''}>
                  </Input>
                </div>
                <ButtonGrey
                  onPress={() => setBeforeSaveModal(true)}
                  name={'Сохранить'}
                  success
                  disabled={false}></ButtonGrey>
              </div>

            )}
            <ButtonGrey
              onPress={onSetEditMode}
              active={editMode}
              name={editMode ? 'Отменить' : 'Редактировать позицию'}></ButtonGrey>
          </div>
        )}
        <div className='flex justify-between w-[100%] mt-[1.8rem]'>
          <div className='flex gap-[10rem]'>
            {!isAdditionalWork && <ButtonRed onPress={onAdditionalWorkHandler} name='Требуется доработка'></ButtonRed>}

            <div className='flex gap-[3.6rem] items-center'>
              <i
                onClick={() =>
                  emmiter.emmit('setCommentModal', {
                    positionId: data.id,
                    isOpen: true,
                    isFullMod: true,
                    callback: getData
                  })
                }
                className='ease-in-out duration-300 icon-comment w-[3rem] h-[3rem] flex justify-center items-center hover:bg-Dominant/Dop text-Regular(24_30) text-Accent/Blue hover:cursor-pointer'></i>
              <i
                onClick={() =>
                  setStickerModal({
                    isOpen: true,
                    title: data.title,
                    quantity: data.quantity,
                    code: data.code
                  })
                }
                className='ease-in-out duration-300 icon-comment w-[3rem] h-[3rem] flex justify-center  items-center hover:bg-Dominant/Dop icon-stik text-Regular(24_30) text-Accent/Lilac  hover:cursor-pointer'></i>
              <i
                onClick={onSetPositionModalPrint}
                className={`icon-stage-5 ease-in-out duration-300  w-[3rem] h-[3rem] flex justify-center  items-center hover:bg-Dominant/Dop text-Regular(24_30) text-Accent/Blue hover:cursor-pointer`}></i>
            </div>
          </div>

          <div className='flex gap-[2.6rem]'>
            <ButtonGrey onPress={() => setIsModalCancelOpened(true)} name='Отменить позицию'></ButtonGrey>
            <ButtonBorderRound onPress={() => setIsModalCloseOpened(true)} name='Закрыть позицию'></ButtonBorderRound>
          </div>
        </div>
      </div>
      <Modal
        closeModal={() => {
          setWarningModal({ isOpen: false })
        }}
        isOpen={warningModal.isOpen}>
        <div className='w-[62rem] min-h-[10rem]'>
          <div className='py-[5rem] px-[7rem]'>
            <div className='flex flex-col gap-[2rem]'>
              <span className='text-Regular(24_30) text-center'>Внимание!</span>
              <span className='text-Regular(18_24) text-center'>
                Это существующий метод печати. <br /> При его удалении все данные связанные с ним будут утеряны
              </span>
              <span className='text-Regular(16_18) text-center'>
                Пока вы не сохранили изменения, нажатие на кнопку "Отменить" вернет исходное состояние позиции
              </span>
              <div className='flex justify-center gap-[5rem]'>
                <div
                  onClick={() => warningModal.clb()}
                  className='hover:bg-Accent/Light_Green hover:cursor-pointer duration-300 ease-in-out min-w-[19rem] py-[1.1rem] group px-[1.8rem]  font-medium rounded-[8px] bg-Accent/Green text-white text-Regular(16_20)'>
                  <span className='flex justify-center  items-center gap-[0.8rem]'>
                    <span>Удалить</span>
                    <i className='icon-fcheck-circle text-Regular(18_24)'></i>
                  </span>
                </div>
                <div
                  onClick={() => setWarningModal({ isOpen: false })}
                  className='hover:bg-Accent/Red_Middle hover:cursor-pointer duration-300 ease-in-out min-w-[19rem] py-[1.1rem] group px-[1.8rem]  font-medium rounded-[8px] bg-Accent/Red text-white text-Regular(16_20)'>
                  <span className='flex justify-center  items-center gap-[0.8rem]'>
                    <span>Не удалять</span>
                    <i className='icon-times-circle text-Regular(18_24)'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal closeModal={() => setBeforeSaveModal(false)} isOpen={beforeSaveModal}>
        <div className='w-[62rem] min-h-[10rem]'>
          <div className='py-[5rem] px-[7rem]'>
            <div className='flex flex-col gap-[2rem]'>
              <span className='text-Regular(24_30) text-center'>Внимание!</span>
              <span className='text-Regular(18_24) text-center'>
                После сохранения данные текущей позиции будут изменены
              </span>
              <span className='text-Regular(16_18) text-center'>
                Пока вы не сохранили изменения, нажатие на кнопку "Отменить" вернет исходное состояние позиции
              </span>
              <div className='flex justify-center gap-[5rem]'>
                <div
                  onClick={onEditSave}
                  className='hover:bg-Accent/Light_Green hover:cursor-pointer duration-300 ease-in-out min-w-[19rem] py-[1.1rem] group px-[1.8rem]  font-medium rounded-[8px] bg-Accent/Green text-white text-Regular(16_20)'>
                  <span className='flex justify-center  items-center gap-[0.8rem]'>
                    <span>Изменить и сохранить</span>
                    <i className='icon-fcheck-circle text-Regular(18_24)'></i>
                  </span>
                </div>
                <div
                  onClick={() => setBeforeSaveModal(false)}
                  className='hover:bg-Accent/Red_Middle hover:cursor-pointer duration-300 ease-in-out min-w-[19rem] py-[1.1rem] group px-[1.8rem]  font-medium rounded-[8px] bg-Accent/Red text-white text-Regular(16_20)'>
                  <span className='flex justify-center  items-center gap-[0.8rem]'>
                    <span>Закрыть</span>
                    <i className='icon-times-circle text-Regular(18_24)'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
