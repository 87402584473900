import { useEffect, useState, useRef, useCallback } from 'react'
import { date } from '../../../utils/date'
import Loader from '../../loader/loader'
import Checkbox from '../../ui/checkbox/checkbox'
import ReadyTimePicker from '../../readyTimePicker/readyTimePicker'
import PositionStatus from '../../positionStatus/positionStatus'
import PositionUser from '../../positionUser/positionUser'
import usePosition from '../../../hooks/position.hook'
import ConfirmModal from '../confirmModal/confirmModal'
import Modal from '../../modal/modal'
import './style.css'
import { emmiter } from '../../../helpers/Emmiter'
import { Tooltip } from 'react-tooltip'
import { PositionBlock } from './positionBlock'
import { PositionHeader } from './positionHeader'

const Th = ({ name, className }) => {
  return (
    <div
      className={`${className} flex-auto flex bg-Dominant/Light  justify-center items-center p-[1.2rem] border border-Content/Border border-l-0 border-r-0 border-t-0`}>
      <span className='text-Regular(12_14) font-semibold'>{name}</span>
    </div>
  )
}

const getCurrentStage = stages => {
  if (!stages) return null
  return [...stages]?.reverse().find(x => x.isCurrentStage).stage
}

function PositionModal({
  positionId,
  setStickerModal,
  onOrderDetail,
  onSetPositionModalPrint,
  setProductsSetModal,
  setPositionModal,
  getDashboardData,
  showOrderKitModal
}) {
  const intervalId = useRef(null)
  const {
    setUser,
    setStatus,
    getPosition,
    setPositionsToCanceled,
    setPositionsToClosed,
    setPrintStatus,
    setPositionsToUrgent,
    setPrintUser,
    editPosition
  } = usePosition()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isModalCancelOpened, setIsModalCancelOpened] = useState(false)
  const [isModalCloseOpened, setIsModalCloseOpened] = useState(false)
  const [isAdditionalWork, setIsAdditionalWork] = useState(false)

  const [originalCPositions, setOriginalCPositions] = useState(null)

  const currenStage = getCurrentStage(data?.c_position_stages)

  const getData = useCallback(async () => {
    if (!positionId) return
    setLoading(true)
    const { data } = await getPosition({
      positionId
    })
    setOriginalCPositions([...data.c_position_stages])
    if (!data.drawings.length) {
      data.c_position_stages = data.c_position_stages.filter(x => [1, 2, 4, 5, 6].indexOf(x.stage.step) === -1)
    }
    setData(data)
    const isAtAdditionalWork = data.c_position_stages.find(x => x.isCurrentStage && x.stage.additionalWork)
    setIsAdditionalWork(isAtAdditionalWork)
    setLoading(false)
  }, [getPosition])

  const onSetStatus = positionStageId => async statusId => {
    await setStatus(positionStageId, statusId)
    await getData()
  }

  const onSetPrintStatus = (drawingId, positionStageId) => async statusId => {
    await setPrintStatus(positionStageId, statusId, drawingId)
    await getData()
  }

  const onSetUser = positionStageId => async userId => {
    await setUser(positionStageId, userId)
    await getData()
  }

  const onSetPrintUser = (positionStageId, drawingId) => async userId => {
    await setPrintUser(positionStageId, userId, drawingId)
  }

  const onClosePosition = async () => {
    setIsModalCloseOpened(false)
    await setPositionsToClosed([data.id])
    getData()
  }

  const onCancelPosition = async () => {
    setIsModalCancelOpened(false)
    await setPositionsToCanceled([data.id])
    getData()
  }

  const onSetStampProgress = ev => {
    ev.stopPropagation()
    return data => {
      emmiter.emmit('stampProgressModal', data)
    }
  }

  const onUrgent = async () => {
    await setPositionsToUrgent({
      ids: [data.id],
      isUrgent: !data.isUrgent
    })
  }

  useEffect(() => {
    getData()
    intervalId.current = setInterval(getData, 20000)
    return () => {
      clearInterval(intervalId.current)
    }
  }, [getData])

  const { order: { id, orderId, company } = {} } = data || {}

  return (
    <div className='w-[90vw] max-w-[180rem] min-h-[50rem]'>
      {loading && !data && <Loader></Loader>}
      {data && (
        <>
          <PositionHeader
            currenStage={currenStage}
            orderId={orderId}
            company={company}
            data={data}
            getData={getData}
            onUrgent={onUrgent}
            id={id}
            onOrderDetail={onOrderDetail}
          />
          <div className='px-[8rem]'>
            <PositionBlock
              originalCPositions={originalCPositions}
              currenStage={currenStage}
              data={data}
              getData={getData}
              isAdditionalWork={isAdditionalWork}
              onSetPositionModalPrint={onSetPositionModalPrint}
              setIsModalCancelOpened={setIsModalCancelOpened}
              setIsModalCloseOpened={setIsModalCloseOpened}
              setStickerModal={setStickerModal}
              setProductsSetModal={setProductsSetModal}
              editPosition={editPosition}
              loading={loading}
              setLoading={setLoading}
              setPositionModal={setPositionModal}
              getDashboardData={getDashboardData}
              showOrderKitModal={showOrderKitModal}
            />
            <div className='py-[3.6rem]'>
              <div className='flex flex-col gap-[5rem]'>
                {data?.c_position_stages.map(x => {
                  return (
                    <div
                      className={`
										${
                      x.isCurrentStage
                        ? 'mt-[2rem] mb-[2rem] before:absolute before:inset-x-[-4rem] before:inset-y-[-4rem] before:z-[-1] before:bg-[#D5E6FF]'
                        : ''
                    }
										relative
										flex flex-col gap-[0.8rem]
										bg-white
										`}>
                      {x.isCurrentStage && (
                        <span className='absolute rounded-br-[10px] left-[-4rem] top-[-4rem] p-[0.5rem] text-white bg-Accent/Blue text-Regular(14_16)'>
                          Текущий этап
                        </span>
                      )}
                      <span
                        span
                        style={{ backgroundColor: x.stage.color }}
                        className='py-[0.9rem] px-[1.2rem] self-start text-Regular(16_18) font-semibold'>
                        {' '}
                        {x.stage.title}
                      </span>
                      <table>
                        <tbody>
                          <tr>
                            <td className='w-[27rem] border-collapse border border-Content/Border align-top'>
                              <Th name='Ответственный'></Th>
                              <div className='min-h-[8rem] p-[1.2rem]'>
                                {x.stage.stampStage ? (
                                  data.drawings.map(({ user, id }, _, arr) => {
                                    return (
                                      <div key={id} className=' flex'>
                                        <PositionUser
                                          stageId={x.stage.id}
                                          currentData={user}
                                          multi={arr.length > 1}
                                          onSetData={onSetPrintUser(x.id, id)}
                                          isCurrentStage={x.isCurrentStage}></PositionUser>
                                      </div>
                                    )
                                  })
                                ) : (
                                  <PositionUser
                                    small={true}
                                    stageId={x.stage.id}
                                    currentData={x.user}
                                    onSetData={onSetUser(x.id)}
                                    isCurrentStage={x.isCurrentStage}></PositionUser>
                                )}
                              </div>
                            </td>
                            <td className='w-[35rem] border-collapse border border-Content/Border align-top'>
                              <Th name='Текущий статус'></Th>
                              <div className='min-h-[8rem] p-[1.2rem]'>
                                {x.stage.stampStage ? (
                                  <div
                                    className={`
																		${data.drawings?.length > 1 ? 'py-[0.2rem]' : ''}
																											grid
																											flex-col
																											gap-[1px]
																											w-[100%]							
																											h-[100%]`}>
                                    {data.drawings.map(
                                      (
                                        { status: drawingStatus, id: drawingId, readyCount, name, subusers = [] },
                                        _,
                                        arr
                                      ) => {
                                        let currentStatus

                                        if (drawingStatus) {
                                          currentStatus = drawingStatus
                                        } else {
                                          currentStatus = x.status
                                        }
                                        return (
                                          <div
                                            key={drawingId}
                                            className=' grid grid-cols-[1fr_1fr_7rem_auto] items-center gap-[5px]'>
                                            {currentStatus ? (
                                              <PositionStatus
                                                positionId={data.id}
                                                user={x.user}
                                                service={x.stage.service}
                                                blocked={x.stage.blocked}
                                                stageId={x.stage.id}
                                                getData={getData}
                                                isCurrentStage={x.isCurrentStage}
                                                currentData={currentStatus}
                                                onSetData={onSetPrintStatus(drawingId, x.id)}
                                                timestamps={x.stageChangeTimeStamps}
                                                multi={arr.length > 1}></PositionStatus>
                                            ) : (
                                              <span></span>
                                            )}
                                            <span className='text-Regular(12_14) line-clamp-1 overflow-hidden'>
                                              {name}
                                            </span>
                                            <span>
                                              (готово{' '}
                                              <span className='text-Accent/Green'>
                                                {subusers.reduce((acc, { readyCount }) => (acc += readyCount), 0) || 0}
                                              </span>
                                              )
                                            </span>
                                            <div
                                              onClick={ev =>
                                                onSetStampProgress(ev)({
                                                  ...data,
                                                  name,
                                                  drawingId,
                                                  positionStageId: x.id,
                                                  getData
                                                })
                                              }
                                              className='hover:cursor-pointer'>
                                              <svg
                                                width='18'
                                                height='18'
                                                viewBox='0 0 18 18'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                  d='M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
                                                  stroke='#7BB2E7'
                                                  stroke-width='2'
                                                />
                                                <g clipPath='url(#clip0_1034_27789)'>
                                                  <circle
                                                    cx='6.5'
                                                    cy='6.5'
                                                    r='1.25'
                                                    stroke='#2F80ED'
                                                    stroke-width='1.5'
                                                  />
                                                  <path
                                                    d='M12.7515 11.4795C12.7515 12.1698 12.1918 12.7295 11.5015 12.7295C10.8111 12.7295 10.2515 12.1698 10.2515 11.4795C10.2515 10.7891 10.8111 10.2295 11.5015 10.2295C12.1918 10.2295 12.7515 10.7891 12.7515 11.4795Z'
                                                    stroke='#2F80ED'
                                                    stroke-width='1.5'
                                                  />
                                                  <line
                                                    x1='12.5303'
                                                    y1='5.53033'
                                                    x2='5.53033'
                                                    y2='12.5303'
                                                    stroke='#2F80ED'
                                                    stroke-width='1.5'
                                                  />
                                                </g>
                                                <mask id='path-5-inside-1_1034_27789' fill='white'>
                                                  <path d='M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 3.60419e-07 9 0V1.97721C10.8626 1.97721 12.6488 2.71711 13.9659 4.03414C15.2829 5.35117 16.0228 7.13744 16.0228 9C16.0228 10.8626 15.2829 12.6488 13.9659 13.9659C12.6488 15.2829 10.8626 16.0228 9 16.0228V18Z' />
                                                </mask>
                                                <path
                                                  d='M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 3.60419e-07 9 0V1.97721C10.8626 1.97721 12.6488 2.71711 13.9659 4.03414C15.2829 5.35117 16.0228 7.13744 16.0228 9C16.0228 10.8626 15.2829 12.6488 13.9659 13.9659C12.6488 15.2829 10.8626 16.0228 9 16.0228V18Z'
                                                  fill='#D9D9D9'
                                                  stroke='#2F80ED'
                                                  stroke-width='4'
                                                  mask='url(#path-5-inside-1_1034_27789)'
                                                />
                                                <defs>
                                                  <clipPath id='clip0_1034_27789'>
                                                    <rect
                                                      width='9'
                                                      height='9'
                                                      fill='white'
                                                      transform='translate(4.5 4.5)'
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </div>
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <PositionStatus
                                    positionId={data.id}
                                    className='text-Regular(12_14)'
                                    currentData={x.status}
                                    stageId={x.stage.id}
                                    small={true}
                                    user={x.user}
                                    getData={getData}
                                    isCurrentStage={x.isCurrentStage}
                                    onSetData={onSetStatus(x.id)}
                                    timestamps={x.stageChangeTimeStamps}
                                    stampSpecialId={data.stampSpecialId}></PositionStatus>
                                )}
                              </div>
                            </td>
                            <td className='border-collapse border border-Content/Border align-top'>
                              <Th name='Комментарий'></Th>
                              <div className='min-h-[8rem] flex flex-col p-[1.2rem] gap-[1rem]'>
                                <div className='flex flex-col gap-[1rem] '>
                                  {x.comments?.map(y => {
                                    return (
                                      <div className='grid grid-cols-[10rem_1fr_20rem] p-[1.6rem] gap-[7rem] bg-Dominant/Dop border border-Content/Border'>
                                        <span className='text-Regular(14_16) font-semibold'>{y.roleOwner}</span>
                                        <span className='text-Regular(14_16)'>{y.comment}</span>
                                        <span className='text-right text-Regular(12_14) text-Content/Middle'>
                                          {date.transform(y.createdAt)}
                                        </span>
                                      </div>
                                    )
                                  })}
                                </div>
                                <span
                                  className='hover:cursor-pointer hover:opacity-70 ease-in-out duration-300 ml-auto text-Accent/Blue text-Regular(14_16)'
                                  onClick={() =>
                                    emmiter.emmit('setCommentModal', {
                                      positionId: data.id,
                                      stageId: x.stage.id,
                                      isOpen: true,
                                      isFullMod: false,
                                      callback: () => {
                                        emmiter.emmit('setCommentModal', {
                                          isOpen: false
                                        })
                                        getData()
                                      }
                                    })
                                  }>
                                  + Добавить комментарий
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      )}
      <Tooltip
        className='cell_position_tooltip'
        opacity='1'
        clickable={true}
        style={{
          backgroundColor: '#000000',
          padding: '12px',
          boxShadow: 'none',
          pointerEvents: 'auto',
          zIndex: 10,
          fontSize: '16px',
          maxWidth: '400px'
        }}
        id={'cell_position_tooltip'}></Tooltip>
      <Modal isOpen={isModalCancelOpened} closeModal={() => setIsModalCancelOpened(false)}>
        <ConfirmModal
          title='Вы уверены, что хотите отменить позицию?'
          onResolve={onCancelPosition}
          onReject={() => setIsModalCancelOpened(false)}></ConfirmModal>
      </Modal>
      <Modal isOpen={isModalCloseOpened} closeModal={() => setIsModalCloseOpened(false)}>
        <ConfirmModal
          title='Вы уверены, что хотите закрыть позицию?'
          onResolve={onClosePosition}
          onReject={() => setIsModalCloseOpened(false)}></ConfirmModal>
      </Modal>
    </div>
  )
}

export default PositionModal
