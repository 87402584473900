import { useCallback, useEffect, useRef, useState } from 'react'

import React from 'react'
import OutsideAlerter from '../../outsideAlerter/outsideAlerter'
import Multiselect from '../../multiselect/multiselect'
import Button from '../../ui/button/button'
import Input from '../../ui/input/input'
import Datepicker from '../../ui/datepicker/datepicker'
import usersStore from '../../../store/users'
import useDashbord from '../../../hooks/dashbord.hook'

const sanitizeScheme = data => {
  const result = Object.entries(data || {}).reduce((acc, [path, { type, val, id = 'id' }]) => {
    console.log('id', data)
    let query = {}
    let counter = 0
    path = path.split('.')
    path.forEach(x => {
      if (acc[x]) {
        counter++
        query = acc[x]
      }
    })
    if (counter) {
      path.splice(0, counter)
    }
    const buildQuery = obj => {
      if (!path.length) {
        if (type === 'multi') {
          obj['$in'] = val.map(x => x[id])
        }
        if (type === 'search') {
          obj['$containsi'] = val
        }
        if (type === 'time') {
          obj['$eq'] = val
        }
        return
      }
      const key = path.splice(0, 1)
      if (!obj[key]) {
        obj[key] = {}
      }
      buildQuery(obj[key])
    }
    buildQuery(query)
    if (counter) {
      query = {}
    }
    return { ...acc, ...query }
  }, {})
  return result
}

function Search({ statuses, setFilter, isAllMod, stages, setPage }) {
  const schemeRef = useRef({})
  const formRef = useRef(null)
  const [selected, setSelected] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [printGroups, setPrintGroups] = useState(false)
  const { getPrintGroups } = useDashbord()
  const { users } = usersStore

  useEffect(() => {
    ;(async () => {
      let { data } = await getPrintGroups()
      data = data.map(x => ({
        id: x.id,
        title: x.name,
        code: x.code
      }))
      setPrintGroups(data)
    })()
  }, [])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const closeSearch = useCallback(() => {
    setIsOpen(false)
  }, [])

  const updateScheme = ({ val, type, label, path, ...rest }) => {
    if ((Array.isArray(val) && !val.length) || !val) {
      delete schemeRef.current[path]
    } else {
      schemeRef.current[path] = {
        label,
        val,
        type,
        path,
        ...rest
      }
    }
  }

  const onSubmit = useCallback(async () => {
    console.log(schemeRef.current)
    const res = sanitizeScheme(schemeRef.current)
    setPage(1)
    setFilter({ ...res })
    renderSelected()
    setIsOpen(false)
  }, [schemeRef.current])

  const renderSelected = () => {
    const selected = Object.entries(schemeRef.current || {}).reduce((acc, [prop, { val, label }]) => {
      acc.push({
        prop,
        label,
        value: Array.isArray(val) ? val.map(x => x.title).join(', ') : val
      })
      return acc
    }, [])
    setSelected(selected)
  }

  const onRemove = id => {
    delete schemeRef.current[id]
    onSubmit()
  }

  const handler = ev => {
    if (ev.key === 'Enter') {
      onSubmit()
    }
    if (ev.key === 'Escape') {
      toggleOpen()
    }
  }

  return (
    <div className='relative flex-1 flex '>
      <OutsideAlerter className=' w-[100%] flex' onEvent={closeSearch}>
        <div
          onClick={toggleOpen}
          onKeyDown={handler}
          tabIndex={0}
          className=' hover:border-Accent/Blue hover:cursor-pointer ease-in-out duration-300 border w-[100%]  border-Content/Border flex px-[1.2rem] gap-[0.6rem] items-center'>
          {selected.length ? (
            selected.map(x => {
              console.log(x)
              return (
                <div
                  key={x.label}
                  className=' max-w-[30rem] px-[0.7rem] h-[3.2rem] text-Regular(16_18) flex gap-[0.6rem]  items-center whitespace-nowrap bg-[#BDDAF6]'>
                  <span className='overflow-hidden text-ellipsis inline-block'>
                    {x.label} : {x.value}
                  </span>
                  <i
                    className='icon-close text-[2.4rem] text-Content/Middle hover:cursor-pointer'
                    onClick={ev => {
                      ev.stopPropagation()
                      onRemove(x.prop)
                    }}></i>
                </div>
              )
            })
          ) : (
            <span className='block text-Regular(16_18) text-Content/Middle'>Фильтр</span>
          )}
        </div>
        {isOpen && (
          <div
            ref={formRef}
            onKeyDown={handler}
            tabIndex={0}
            className={`
							border  w-[66rem] border-Content/Border bg-white z-30 pt-[5rem] px-[2.6rem] shadow-md pb-[2.6rem] absolute top-[100%] left-0
						`}>
            <i
              onClick={closeSearch}
              className='hover:cursor-pointer icon-close absolute right-[2rem] top-[2rem] text-Regular(24_30)'></i>
            <div className='flex flex-col gap-[1.2rem] max-h-[40vh] overflow-auto pr-[1rem]'>
              {isAllMod ? (
                <Multiselect
                  onEvent={selected =>
                    updateScheme({
                      key: 'stage',
                      path: 'stage.id',
                      type: 'multi',
                      label: 'Этап',
                      val: selected
                    })
                  }
                  current={schemeRef.current['stage.id']}
                  label='Этап'
                  placeholder='Не выбран'
                  items={stages}></Multiselect>
              ) : (
                <Multiselect
                  onEvent={selected =>
                    updateScheme({
                      key: 'status',
                      path: 'status.id',
                      type: 'multi',
                      label: 'Статус',
                      val: selected
                    })
                  }
                  current={schemeRef.current['status.id']}
                  label='Статус'
                  placeholder='Не выбран'
                  items={statuses}></Multiselect>
              )}
              <Multiselect
                onEvent={selected =>
                  updateScheme({
                    key: 'user',
                    path: 'user.id',
                    type: 'multi',
                    label: 'Ответственный',
                    val: selected
                  })
                }
                current={schemeRef.current['user.id']}
                label='Ответственный'
                placeholder='Не выбран'
                items={users}></Multiselect>
              <Multiselect
                onEvent={selected => {
                  updateScheme({
                    key: 'position',
                    path: 'position.drawings.groupCode',
                    id: 'code',
                    type: 'multi',
                    label: 'Метод печати',
                    val: selected
                  })
                }}
                current={schemeRef.current['position.drawings.groupCode']}
                label='Метод печати'
                placeholder='Не выбран'
                items={printGroups}></Multiselect>
              <Input
                onInput={({ target: { value } }) =>
                  updateScheme({
                    key: 'position',
                    path: 'position.order.orderId',
                    type: 'search',
                    label: 'Номер заказа',
                    val: value
                  })
                }
                initial={schemeRef.current['position.order.orderId']?.val}
                labelSmall={true}
                label='Номер заказа'></Input>
              <Input
                onInput={({ target: { value } }) =>
                  updateScheme({
                    key: 'position',
                    path: 'position.order.company',
                    type: 'search',
                    label: 'Заказчик',
                    val: value
                  })
                }
                initial={schemeRef.current['position.order.username']?.val}
                labelSmall={true}
                label='Заказчик'></Input>
              <Input
                onInput={({ target: { value } }) =>
                  updateScheme({
                    key: 'position',
                    path: 'position.code',
                    type: 'search',
                    label: 'Артикул',
                    val: value
                  })
                }
                initial={schemeRef.current['position.code']?.val}
                labelSmall={true}
                label='Артикул'></Input>
              <Input
                onInput={({ target: { value } }) =>
                  updateScheme({
                    key: 'position.order',
                    path: 'position.order.manager',
                    type: 'search',
                    label: 'Менеджер',
                    val: value
                  })
                }
                initial={schemeRef.current['position.order.manager']?.val}
                labelSmall={true}
                label='Менеджер'></Input>
              <Datepicker
                onChange={({ value }) =>
                  updateScheme({
                    key: 'position',
                    path: 'position.order.date',
                    type: 'search',
                    label: 'Дата заказа',
                    val: value
                  })
                }
                initial={schemeRef.current['position.order.date']?.val}
                labelSmall={true}
                label='Дата заказа'></Datepicker>
              <Datepicker
                showTime={true}
                onChange={({ value }) =>
                  updateScheme({
                    key: 'position',
                    path: 'position.readyDate',
                    type: 'time',
                    label: 'Дата готовности',
                    val: value
                  })
                }
                initial={schemeRef.current['position.readyDate']?.val}
                labelSmall={true}
                label='Дата готовности'></Datepicker>
            </div>

            <div className='mt-[2.6rem]'>
              <Button onPress={onSubmit} name='Применить'></Button>
            </div>
          </div>
        )}
      </OutsideAlerter>
    </div>
  )
}

export default React.memo(Search)
