import { useCallback, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PrintPositions from '../printPositions/printPositions'

import MoveToStageModal from '../modals/moveToStageModal/moveToStageModal'
import { useDebouncedFunction } from '../../hooks/debounce.hook'
import { emmiter } from '../../helpers/Emmiter'
import { date } from '../../utils/date'

import useDashbord from '../../hooks/dashbord.hook'
import stageStore from '../../store/stage'
import Position from '../position/position'

import OrderModal from '../modals/orderModal/orderModal'
import PositionModal from '../modals/positionModal/positionModal'
import Modal from '../modal/modal'
import Switcher from '../ui/swticher/switcher'
import Pagination from '../pagination/pagination'
import OrderCommentModal from '../modals/orderCommentModal/orderCommentModal'
import SortDropdown from '../sortDropdown/sortDropdown'
import Settings from './settings/settings'
import dashbordStore from '../../store/dashbord'
import usersStore from '../../store/users'
import useInit from '../../hooks/init.hook'
import Search from './search/search'
import CommentModal from '../modals/commentModal/commentModal'
import StickerModal from '../modals/stickerModal/stickerModal'
import Loader from '../loader/loader'
import Checkbox from '../ui/checkbox/checkbox'
import './style.css'
import update from 'immutability-helper'
import usePosition from '../../hooks/position.hook'
import ConfirmModal from '../modals/confirmModal/confirmModal'
import useSettings from '../../hooks/settings.hook'
import StampProgressModal from '../modals/stampProgressModal/stampProgressModal'
import ReadyTimePicker from '../readyTimePicker/readyTimePicker'
import { ProductSetModal } from '../modals/productSetModal/ProductSetModal'
import { useSiteRequests } from '../../hooks/siteRequests.hook'
import useOrder from '../../hooks/order.hook'

const TdCell = ({
  height = 'h-[6rem]',
  useSort = true,
  name,
  sort: { path, correction } = {},
  currentStage,
  children,
  initialWidth,
  available = true,
  onClick,
  width,
  title,
  checkbox,
  sortPath,
  dimensional = true,
  onColResize,
  ...props
}) => {
  const { updateSettings } = useSettings()
  const colSizer = useRef()
  const state = useRef({})

  const onMouseDownHandler = useCallback(
    ev => {
      state.current.isActive = true
      state.current.clientX = ev.clientX
    },
    [state]
  )

  const onMouseUpHandler = useCallback(async () => {
    if (state.current.isActive) {
      dashbordStore.updateInitialColumnWidth(name)
      updateSettings()
    }
    state.current.isActive = false
  }, [state, name])

  const onMouseMoveHandler = useCallback(
    ev => {
      if (state.current.isActive) {
        let newWidth = initialWidth + (ev.clientX - state.current.clientX) / 10
        if (newWidth < 5) newWidth = 5
        onColResize(name, newWidth)
      }
    },
    [state, initialWidth]
  )

  useEffect(() => {
    colSizer.current?.addEventListener('mousedown', onMouseDownHandler)
    document.addEventListener('mouseup', onMouseUpHandler)
    document.addEventListener('mousemove', onMouseMoveHandler)
    return () => {
      colSizer.current?.removeEventListener('mousedown', onMouseDownHandler)
      document.removeEventListener('mouseup', onMouseUpHandler)
      document.removeEventListener('mousemove', onMouseMoveHandler)
    }
  }, [colSizer, state, initialWidth, available])

  let direction

  if (path === sortPath) {
    direction = correction
  }

  if (!available || ((currentStage?.service || !currentStage?.id) && props.hideOnService)) return null

  return (
    <th
      style={{
        width: (width || 5) + 'rem'
      }}
      className={`
			${height}
			${props.className}
			${checkbox ? 'flex-0' : 'flex-0'}
			p-[1.2rem] flex items-center  overflow-hidden
			${dimensional && 'relative'}
		`}>
      {children}
      <div
        onClick={() =>
          sortPath &&
          useSort &&
          dashbordStore.setSort({
            path: sortPath,
            name: title,
            correction: correction === 'asc' ? 'desc' : 'asc'
          })
        }
        className='flex  gap-[0.6rem] items-center hover:cursor-pointer group'>
        <span className='text-Regular(12_14) text-Content/Dark font-semibold'>{title}</span>
        {props.configurable && sortPath && useSort && (
          <span
            className={`
						${
              direction === 'asc'
                ? 'text-Content/Dark'
                : direction === 'desc'
                  ? 'text-Content/Dark rotate-180'
                  : 'text-Content/Light'
            }
						group-hover:text-Accent/Blue
						`}>
            <i className={`icon-sort text-[1.6rem] flex-auto`}></i>
          </span>
        )}
        {!checkbox && dimensional && (
          <span
            ref={colSizer}
            onClick={ev => ev.stopPropagation()}
            className='
							after:absolute
							after:top-0
							after:bottom-0
							after:w-[10px]
							after:left-0
							after:translate-x-[-50%]
							hover:cursor-col-resize
							w-[1px] h-[50%] top-[50%] translate-y-[-50%] absolute right-0  bg-Content/Border'></span>
        )}
      </div>
    </th>
  )
}

const TableHeader = observer(
  ({
    items,
    item,
    selectedPositionsStages,
    sort,
    settings,
    isAllMod,
    currentStage,
    useSort,
    groupView,
    orderId,
    selectedPositionsOrderId
  }) => {
    const onColResize = (name, width) => {
      dashbordStore.changeColumnWith(name, width)
    }

    const getItemsFields = () => {
      return items.map(x => ({
        blocked: x.blocked,
        id: x.id,
        position_id: x.position.id,
        drawings: x.position.drawings,
        status: x.status,
        title: x.position.title,
        code: x.position.code
      }))
    }

    const isOrderPositionsSelected = orderId === selectedPositionsOrderId

    const clearSelected = () => {
      if (groupView) {
        dashbordStore.clearSelected()
      }
    }

    const selectAllPositions = () => {
      const selectedItemsFileds = getItemsFields()

      if (selectedPositionsStages.length === items.length && !groupView) {
        return dashbordStore.addPositions([])
      }

      if (groupView) {
        dashbordStore.addPositions(selectedItemsFileds, orderId)
      } else {
        dashbordStore.addPositions(selectedItemsFileds)
      }
    }

    const isCheckboxActive = groupView
      ? !!items.length && items.length === selectedPositionsStages.length && orderId === selectedPositionsOrderId
      : !!items.length && items.length === selectedPositionsStages.length

    return (
      <tr className='flex bg-white'>
        <div className='w-[4.8rem] flex items-center py-[0.9rem] px-[1.2rem]'></div>

        {/* <TdCell
				className="absolute w-[4.8rem] flex items-center">

			</TdCell>
			<TdCell
				checkbox={true}
				className="w-[4.8rem] flex items-center">

			</TdCell> */}
        {Object.entries(settings).map(([key, x]) => {
          if (isAllMod && key == 'status') return null
          if (!isAllMod && key == 'stage') return null
          return (
            <TdCell
              useSort={useSort}
              currentStage={currentStage}
              onColResize={onColResize}
              sort={sort}
              key={key}
              name={key}
              {...x}></TdCell>
          )
        })}
        <div className='absolute w-[4.8rem] h-[calc(6rem-1px)] flex items-center py-[0.9rem] px-[1.2rem] bg-[#fff]'>
          <Checkbox
            active={isCheckboxActive}
            onChange={isOrderPositionsSelected ? clearSelected : selectAllPositions}></Checkbox>
        </div>
      </tr>
    )
  }
)

function Dashbord() {
  useInit()
  const [filter, setFilter] = useState(null)
  const intervalId = useRef(null)
  const prevSortState = useRef(null)
  const { getDashbord } = useDashbord()
  const { updateGroupView } = useSettings()
  const { getProductsStock } = useSiteRequests()
  const {
    settings: { groupView, table, sort },
    pageSize,
    selectedPositionsStages,
    selectedPositionsOrderId
  } = dashbordStore
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState([])
  const [isAllMod, setIsAllMod] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [positionModal, setPositionModal] = useState(false)
  const [satusAlertModal, setAlertStatusModal] = useState(false)
  const { setSort } = usePosition()
  const [commentModal, setCommentModal] = useState({
    isOpen: false,
    positionId: null,
    stageId: null,
    isFullMod: true,
    callback: null,
    positionsIDs: [],
    title: ''
  })

  const [moveToStageModal, setMoveToStageModal] = useState({
    isOpen: false,
    positionId: null,
    callback: null
  })
  const [orderCommentModal, setOrderCommentModal] = useState({
    isOpen: false,
    id: null,
    comment: '',
    orderId: null
  })
  const [stickerModal, setStickerModal] = useState({
    isOpen: false
  })
  const [productsSetModal, setProductsSetModal] = useState({ isOpen: false })
  const { id, role, statuses } = stageStore.currentStage || {}
  const { stages, currentStage } = stageStore || {}

  const [loading, setLoading] = useState(false)
  const [positionsStock, setPositionsStock] = useState({})

  const getPositionsStock = async items => {
    try {
      let itemsArticles
      const itemsCopy = [...items]
      if (groupView) {
        itemsArticles = itemsCopy.reduce((acc, cur) => {
          const orderPositions = cur[1].items
          const orderPositionsArticles = !!orderPositions.length && orderPositions.map(el => el.position.code)
          if (orderPositionsArticles) {
            return [...acc, ...orderPositionsArticles]
          } else {
            return [...acc]
          }
        }, [])
      } else {
        itemsArticles = items.map(el => el.position.code)
      }
      const uniqArticlesArray = Array.from(new Set(itemsArticles))
      const response = await getProductsStock(uniqArticlesArray)
      if (response) {
        setPositionsStock(response)
      }
    } catch (e) {
      console.error(`getPositionsStock => ${e}`)
    }
  }

  const onSetFilter = useCallback(
    filter => {
      console.log('=>>', filter)
      setFilter(filter)
    },
    [setFilter]
  )

  const onPageChange = page => {
    setPage(page)
  }

  const onOrderDetail = id => {
    dashbordStore.clearSelected()
    setDetailModal(id)
    setPositionModal(false)
  }

  const onPositionDetail = (id, positionStageId) => {
    dashbordStore.clearSelected()
    setPositionModal(id)
    dashbordStore.addPosition({ id, position_id: positionStageId })
  }

  const onSetPositionModalPrint = async () => {
    await setPositionModal(false)
    await setDetailModal(false)
    await window.print()
    dashbordStore.clearSelected()
  }

  const groupItems = data => {
    const group = data.reduce((acc, curr) => {
      const { orderId, localComment, id, comment, readyDate, managerComment, dontDelay } = curr.position.order
      const deliveryinfo = curr.position.order.deliveryinfo?.name
      if (acc.get(id)) {
        acc.get(id).items.push(curr)
      } else {
        acc.set(id, {
          items: [curr],
          deliveryinfo,
          localComment,
          dontDelay,
          readyDate: date.transform(readyDate),
          orderId,
          managerComment,
          comment
        })
      }
      return acc
    }, new Map())

    const result = []
    for (const item of group) {
      result.push(item)
    }

    return result
  }

  const setComment = ({ id, stageId }) => {
    setCommentModal(prev => ({
      ...prev,
      isOpen: true,
      stageId,
      positionId: id,
      isFullMod: true,
      positionsIDs: [],
      title: '',
      callback: closeCommentModalAndReftesh
    }))
  }

  const setOrderComment = ({ id, orderId, comment = '' }) => {
    setOrderCommentModal({
      isOpen: true,
      id,
      orderId,
      comment
    })
  }

  const setSticker = props => {
    setStickerModal({
      isOpen: true,
      ...props
    })
  }

  const closeCommentModalAndReftesh = async () => {
    commentModal.callback && (await commentModal.callback())

    setCommentModal(prev => ({
      ...prev,
      isOpen: false,
      isFullMod: true,
      stageId: null,
      positionId: null,
      positionsIDs: [],
      callback: null,
      title: ''
    }))

    await getData(true)
  }

  const { func: setSortDebounced, timeoutId: sortTimeoutId } = useDebouncedFunction(map => {
    setSort({ map })
  }, 700)

  const moveCard = useCallback(
    async (dragIndex, hoverIndex) => {
      const sorted = update(items, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, items[dragIndex]]
        ]
      })

      setItems(sorted)

      if (sorted[dragIndex]?.id && sorted[hoverIndex]?.id) {
        const map = sorted.map((x, index) => {
          return {
            id: x.id,
            sort: Math.abs(index - (sorted.length - 1))
          }
        })
        setSortDebounced(map)
      }
    },
    [items]
  )

  const getPositionsStocks = itemsToSet => {
    if (!id || currentStage.step !== 3 || !itemsToSet.length) {
      setPositionsStock({})
    } else {
      getPositionsStock(itemsToSet)
    }
  }

  const getData = useCallback(
    async (silent = false, interval = false, signal) => {
      if (!table) return
      if (id || id === null) {
        !silent && setLoading(true)
        !interval && dashbordStore.clearSelected()
        const { data, meta: { pagination } = {} } =
          (await getDashbord({
            stage: id,
            page: page,
            pageSize,
            filter,
            sort: sort,
            signal
          })) || {}

        if (!data) return

        if (!id) {
          setIsAllMod(true)
        } else {
          setIsAllMod(false)
        }
        let itemsToSet
        if (!id) {
          const stackedPositions = data.reduce((acc, curr, index) => {
            const currentPositionID = curr.position.id
            const formattedCurrent = {
              ...curr,
              stageInfo: [{ stage: curr.stage, status: curr.status }]
            }
            if (index === 0) {
              acc.push(formattedCurrent)
              return acc
            } else {
              acc.forEach((el, i) => {
                const positionId = el.position.id
                if (currentPositionID === positionId) {
                  el.stageInfo.push({ stage: curr.stage, status: curr.status })
                } else if (currentPositionID !== positionId && i === acc.length - 1) {
                  return acc.push(formattedCurrent)
                }
              })
            }
            return acc
          }, [])
          itemsToSet = stackedPositions.filter(x => x.position.order)
          setItems(itemsToSet)

          if (groupView) {
            itemsToSet = groupItems(stackedPositions.filter(x => x.position.order))
            setItems(itemsToSet)
          }
        } else {
          if (groupView) {
            itemsToSet = groupItems(data.filter(x => x.position.order))
            setItems(itemsToSet)
          } else {
            if (sort.custom) {
              prevSortState.current = data.map(x => x.id)
            }
            itemsToSet = data.filter(x => x.position.order)
            setItems(itemsToSet)
          }
        }

        getPositionsStocks(itemsToSet)

        setMeta(pagination)
        !silent && setLoading(false)
      }
    },
    [getDashbord, id, page, filter, groupView, table, sort, pageSize]
  )

  const memoizedGetData = useCallback(() => {
    getData(true, true)
  }, [getData])

  const { func: debouncedGetData, timeoutId: getDataTimeoutId } = useDebouncedFunction(memoizedGetData, 3000)

  useEffect(() => {
    emmiter.on('setUserAlert', () => setAlertStatusModal(true))
    emmiter.on('triggerGetData', () => {
      getData(true)
    })
    return () => {
      emmiter.off('setUserAlert')
      emmiter.off('triggerGetData')
    }
  }, [getData])

  useEffect(() => {
    clearTimeout(getDataTimeoutId)
  }, [currentStage?.id])

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    getData(false, false, signal)
    intervalId.current = setInterval(() => getData(true, true), 20000)
    return () => {
      clearInterval(intervalId.current)
      abortController.abort()
    }
  }, [getData])

  useEffect(() => {
    setPage(1)
  }, [id])

  useEffect(() => {
    emmiter.on('setCommentModal', ev => {
      setCommentModal(ev)
    })
    return () => {
      emmiter.off('setCommentModal')
    }
  }, [])

  useEffect(() => {
    emmiter.on('setMoveToStageModal', ev => {
      setMoveToStageModal(ev)
    })
    return () => {
      emmiter.off('setMoveToStageModal')
    }
  }, [])

  useEffect(() => {
    emmiter.on('setOrderCommentModal', ev => {
      console.log('ev', ev)
      setOrderCommentModal(ev)
    })
    return () => {
      emmiter.off('setOrderCommentModal')
    }
  }, [])
  const drawingsFilter = filter?.position?.drawings?.groupCode?.$in || null

  const showOrderKitModal = async (orderId, kitID) => {
    setProductsSetModal({ isOpen: true, orderId, kitID })
  }

  const render = (
    {
      isUrgent,
      blocked,
      comments,
      stage,
      position,
      stageInfo,
      user,
      dashbordDate,
      createdAt,
      id,
      status: currentStatus,
      isCurrentStage,
      stageChangeTimeStamps
    },
    index
  ) => {
    const code = position.code
    const kitID = position.kitID
    const positionStock = positionsStock[code]

    return (
      <Position
        {...position}
        key={id}
        stageInfo={stageInfo}
        index={index}
        isUrgent={isUrgent}
        positionStageId={id}
        timestamps={stageChangeTimeStamps}
        user={user}
        statuses={statuses}
        status={currentStatus}
        comments={comments}
        settings={table}
        createdAt={createdAt}
        dashbordDate={dashbordDate}
        isAllMod={isAllMod}
        role={role}
        stage={stage}
        isCurrentStage={isCurrentStage}
        blocked={blocked}
        onOrderDetail={onOrderDetail}
        onPositionDetail={onPositionDetail}
        setComment={setComment}
        setSticker={setSticker}
        debouncedGetData={debouncedGetData}
        getData={getData}
        moveCard={moveCard}
        groupView={groupView}
        drawingsFilter={drawingsFilter}
        positionStock={positionStock}
        showOrderKitModal={showOrderKitModal}
        kitID={kitID}></Position>
    )
  }

  return (
    <>
      <div className='bg-Dominant/Dop py-[2rem] flex-1 flex flex-col gap-[0.8rem] min-h-[100px] relative  w-[100%]'>
        <div className='max-w-[96%] w-[calc(100vw-15rem)] m-auto'>
          <div className='p-[1.2rem] bg-white flex gap-[2.6rem]'>
            <Settings></Settings>
            <Search
              setPage={setPage}
              isAllMod={isAllMod}
              setFilter={onSetFilter}
              statuses={statuses}
              stages={stages}></Search>
            {/* {!!Object.keys(sort).length && sort.path &&
							<div
								onClick={() => dashbordStore.resetSort()}
								className='bg-Dominant/Dop text-Regular(16_18) p-[0.8rem] flex gap-[0.3rem] items-center'>
								<span className="text-Content/Middle">Сортировка:</span>
								<span>{sort.name}</span>
								<i className='hover:cursor-pointer icon-close text-Regular(18_24)'></i>
							</div>} */}
            <Switcher
              active={groupView}
              onChange={() => updateGroupView(!groupView)}
              label='Сгруппировать по заказам'></Switcher>
            <SortDropdown></SortDropdown>
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className='flex-1   max-w-[96%] w-[calc(100vw-15rem)] m-auto relative overflow-hidden'>
            {loading ? (
              <Loader></Loader>
            ) : table && !!items.length ? (
              <div className='js-scrollable-dashbord overflow-auto h-[100%]'>
                {Array.isArray(items[0]) ? (
                  items?.map(
                    ([id, { items: item, localComment, comment, managerComment, orderId, readyDate, dontDelay }]) => {
                      let orderComment

                      orderComment = localComment?.comment || managerComment
                      return (
                        <div key={id} className='mb-[2rem]'>
                          <div className='grid items-center gap-[1rem] grid-cols-[auto_1fr] mb-[1.1rem]'>
                            <span className='text-Content/Dark block font-semibold text-Regular(16_18) '>
                              {orderId}{' '}
                            </span>
                            <div className='flex flex-1  justify-between overflow-hidden'>
                              <div className='flex items-center gap-[1rem] overflow-hidden max-w-[80%]'>
                                {usersStore.isGodMod() && (
                                  <div
                                    data-tooltip-id={'comment-info'}
                                    data-tooltip-content='Комментарий менеджера к заказу'
                                    data-tooltip-place='top'
                                    onClick={() =>
                                      setOrderComment({
                                        orderId: id,
                                        id,
                                        comment: orderComment
                                      })
                                    }
                                    className='ease-in-out flex-none duration-300 bg-[#fff] w-[5rem]flex justify-center p-[2px] hover:cursor-pointer hover:opacity-60'>
                                    <i
                                      className={`icon-comment text-Regular(24_30) hover:cursor-pointer  text-Accent/Blue`}></i>
                                  </div>
                                )}
                                {orderComment && (
                                  <div
                                    data-tooltip-id={'comment-info'}
                                    data-tooltip-content={orderComment}
                                    data-tooltip-place='top'
                                    className='bg-Accent/Light_Yellow  truncate text-Regular(14_16)  px-[1rem] py-[2px] leading-1'>
                                    {orderComment}
                                  </div>
                                )}
                              </div>
                              <div className='flex items-center gap-[1rem]'>
                                {readyDate && (
                                  <div
                                    data-tooltip-id={'comment-info'}
                                    data-tooltip-content='Время готовности заказа'
                                    data-tooltip-place='top'
                                    className={`${dontDelay ? 'bg-[#FF7F50]' : 'bg-Accent/Blue_Light'} truncate text-Regular(14_16)  px-[1rem] py-[2px] leading-1`}>
                                    {readyDate}{' '}
                                    {dontDelay && <span className='text-Regular(16_18)'>. Не переносить.</span>}
                                  </div>
                                )}
                                <ReadyTimePicker
                                  dontDelay={dontDelay}
                                  tooltip='Время готовности заказа'
                                  orderId={id}
                                  callback={async () => getData(true)}></ReadyTimePicker>
                              </div>
                            </div>
                          </div>

                          <table className='product-table '>
                            <tbody>
                              <TableHeader
                                orderId={Number(id)}
                                items={item}
                                sort={sort}
                                settings={table}
                                selectedPositionsStages={selectedPositionsStages}
                                selectedPositionsOrderId={selectedPositionsOrderId}
                                isAllMod={isAllMod}
                                currentStage={currentStage}
                                useSort={false}
                                groupView={groupView}></TableHeader>
                              {item.map(render)}
                            </tbody>
                          </table>
                        </div>
                      )
                    }
                  )
                ) : (
                  <table className='product-table'>
                    <tbody>
                      <TableHeader
                        orderId={Number(id)}
                        items={items}
                        sort={sort}
                        settings={table}
                        selectedPositionsStages={selectedPositionsStages}
                        selectedPositionsOrderId={selectedPositionsOrderId}
                        isAllMod={isAllMod}
                        currentStage={currentStage}
                        groupView={groupView}></TableHeader>
                      {items?.map(render)}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <span className='absolute left-[50%]  top-[50%] translate-x-[-50%] translate-y-[-50%] text-Regular(24_30) text-Content/Light'>
                Данных пока нет
              </span>
            )}
          </div>
        </DndProvider>
        <div className='max-w-[96%] w-[calc(100vw-15rem)] m-auto p-[1.2rem] bg-white flex relative'>
          <div className='w-[100%] '>
            <Pagination {...meta} onPageChange={onPageChange}></Pagination>
          </div>
        </div>
      </div>

      <PrintPositions items={items} settings={table}></PrintPositions>

      <Modal closeModal={() => setDetailModal(false)} isOpen={!!detailModal}>
        <OrderModal
          onPositionDetail={onPositionDetail}
          orderId={detailModal}
          onSetPositionModalPrint={onSetPositionModalPrint}
          showOrderKitModal={showOrderKitModal}
          getDashboardData={getData}></OrderModal>
      </Modal>

      <Modal
        closeModal={() => {
          setPositionModal(false)
          dashbordStore.clearSelected()
        }}
        isOpen={!!positionModal}>
        <PositionModal
          onOrderDetail={onOrderDetail}
          setCommentModal={setCommentModal}
          setStickerModal={setStickerModal}
          positionId={positionModal}
          onSetPositionModalPrint={onSetPositionModalPrint}
          setPositionModal={setPositionModal}
          getDashboardData={getData}
          showOrderKitModal={showOrderKitModal}></PositionModal>
      </Modal>
      <Modal
        closeModal={() =>
          setCommentModal({
            stageId: null,
            positionId: null,
            isFullMod: true,
            isOpen: false,
            positionsIDs: []
          })
        }
        isOpen={commentModal.isOpen}>
        <CommentModal commentModal={commentModal} getData={getData} setCommentModal={setCommentModal}></CommentModal>
      </Modal>
      <Modal
        isOpen={moveToStageModal.isOpen}
        closeModal={() =>
          setMoveToStageModal({
            positionId: null,
            isOpen: false
          })
        }>
        <MoveToStageModal
          getData={getData}
          setMoveToStageModal={setMoveToStageModal}
          moveToStageModal={moveToStageModal}></MoveToStageModal>
      </Modal>

      <Modal closeModal={() => setStickerModal(prev => ({ ...prev, isOpen: false }))} isOpen={stickerModal.isOpen}>
        <StickerModal stickerModal={stickerModal} setStickerModal={setStickerModal}></StickerModal>
      </Modal>
      <StampProgressModal></StampProgressModal>
      <Modal isOpen={satusAlertModal} closeModal={() => setAlertStatusModal(false)}>
        <ConfirmModal title='Выберите ответственного, чтобы иметь возможность выставлять статус позиции'></ConfirmModal>
      </Modal>
      <Modal
        closeModal={() =>
          setOrderCommentModal({
            isOpen: false,
            orderId: null,
            id: null,
            comment: ''
          })
        }
        isOpen={orderCommentModal.isOpen}>
        <OrderCommentModal
          setOrderCommentModal={setOrderCommentModal}
          orderCommentModal={orderCommentModal}
          getData={getData}></OrderCommentModal>
      </Modal>
      <Modal
        isOpen={productsSetModal.isOpen}
        closeModal={() =>
          setProductsSetModal({
            isOpen: false,
            setName: '',
            setItems: []
          })
        }>
        <ProductSetModal productsSetModal={productsSetModal} />
      </Modal>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: '14px'
        }}
        openOnClick={true}
        id={'cell-click-alert'}></Tooltip>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: '14px'
        }}
        id={'cell-hover-alert'}></Tooltip>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: '14px'
        }}
        id={'cell-info'}></Tooltip>
      <Tooltip
        style={{
          zIndex: 1000,
          fontSize: '14px',
          maxWidth: '600px'
        }}
        id={'comment-info'}></Tooltip>
    </>
  )
}

export default observer(Dashbord)
