function ButtonIcon({ name, Icon = () => null, onPress, className }) {
  return (
    <div
      onClick={onPress}
      className={`hover:cursor-pointer hover:opacity-70 ease-in-out duration-300 border text-Regular(16_18) flex items-center gap-[0.8rem] px-[1.4rem] py-[0.9rem] ${className}`}>
      <Icon></Icon>
      {name}
    </div>
  )
}

export default ButtonIcon
