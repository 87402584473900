import { useCallback, useEffect, useRef, useState } from 'react'
import Input from '../../ui/input/input'
import DefaultModal from '../defaultModal/defaultModal'
import Button from '../../ui/button/button'
import exportAsImage from '../../../utils/exportAsImage'

const Sticker = ({ company, title, logo, code, quantityPerBox }) => {
  return (
    <div className='mb-[5rem] text-center text-Regular(24_30) p-[1.2rem] flex flex-col items-center gap-[0.8rem] bg-white max-w-[40rem] m-auto'>
      <span>{company}</span>
      <span className='text-Regular(18_24)'>
        <span>{title} - </span>
        <span className='font-bold'>{code}</span>
      </span>
      <span>{logo}</span>
      {quantityPerBox && <span>{quantityPerBox} штук</span>}
    </div>
  )
}

function StickerModal({ stickerModal, setStickerModal }) {
  const itemsRef = useRef([])
  const [isReady, setIsReady] = useState(false)
  const [form, setForm] = useState({})
  const [estimated, setEstimated] = useState({
    total: 0,
    remainderItems: 0,
    remainderBox: 0
  })

  useEffect(() => {
    setForm({
      company: stickerModal.company || '',
      logo: stickerModal.logo || '',
      orderQuantity: stickerModal.quantity,
      code: stickerModal.code || ''
    })
  }, [stickerModal])

  const onInputHandler = ({ prop, val }) => {
    const value = val.target.value
    setForm(prev => ({
      ...prev,
      [prop]: value
    }))
    if (prop === 'quantityPerBox' && value) {
      const total = Math.floor(form.orderQuantity / value)
      const remainderItems = form.orderQuantity % value
      const remainderBox = Math.ceil(+remainderItems / value)
      setEstimated({
        total,
        remainderItems,
        remainderBox
      })
    }
    itemsRef.current = []
  }

  const onImagesExport = async () => {
    await exportAsImage(itemsRef.current[0], `${form.orderQuantity}шт_${form.company || 'стикер'}`)
    if (estimated.remainderBox) {
      await exportAsImage(
        itemsRef.current[itemsRef.current.length - 1],
        `${form.orderQuantity}шт_${form.company || 'стикер'}`
      )
    }
  }

  return (
    <div className='w-[90vw] max-w-[76rem] min-h-[10rem]'>
      <DefaultModal title='Создать стикер'>
        <div className='py-[5rem] px-[4rem] bg-Dominant/Dop '>
          <div className='text-Regular(16_18) mb-[5rem]'>
            <span>{stickerModal.title} - </span>
            <span className='font-bold'>{stickerModal.code}</span>
          </div>
          <div className='grid grid-cols-2 gap-[2.6rem] mb-[5rem]'>
            <Input
              onInput={val => onInputHandler({ prop: 'company', val })}
              initial={stickerModal.company}
              border={false}
              label='Компания'></Input>
            <Input
              onInput={val => onInputHandler({ prop: 'logo', val })}
              border={false}
              label='Название логотипа'></Input>
            <Input
              border={false}
              disabled={true}
              initial={stickerModal.quantity + ' штук'}
              label='Штук в заказе'></Input>
            <Input
              onInput={val => onInputHandler({ prop: 'quantityPerBox', val })}
              border={false}
              type='number'
              maxValue={stickerModal.quantity}
              // initial={form.quantityPerBox || ''}
              label='Штук в коробке'></Input>
          </div>
          <Sticker
            logo={form.logo}
            company={form.company}
            title={stickerModal.title}
            quantityPerBox={form.quantityPerBox}
            code={stickerModal.code}></Sticker>
          <div className='flex justify-between'>
            {form.quantityPerBox && (
              <div className='flex flex-col gap-[0.4rem] text-Regular(16_18)'>
                <span>
                  <b>{estimated.total}</b> коробки по <b>{form.quantityPerBox}</b> шт
                </span>
                {estimated.remainderItems > 0 && (
                  <span>
                    <b>{estimated.remainderBox}</b> коробка по <b>{estimated.remainderItems}</b> шт
                  </span>
                )}
              </div>
            )}
            <div className='ml-auto'>
              <Button disabled={!form.quantityPerBox} name='Создать' onPress={onImagesExport}></Button>
            </div>
          </div>
        </div>
        <div className='opacity-0 h-[1px] overflow-hidden pointer-events-none top-0 absolute flex flex-wrap gap-[2rem]'>
          {new Array(estimated.total).fill(1).map((_, index) => {
            return (
              <div key={index} className='sticker-to-print' ref={ref => ref && (itemsRef.current[index] = ref)}>
                <Sticker
                  logo={form.logo}
                  company={form.company}
                  title={stickerModal.title}
                  quantityPerBox={form.quantityPerBox}></Sticker>
              </div>
            )
          })}
          {new Array(estimated.remainderBox).fill(1).map((_, index) => {
            return (
              <div
                key={index}
                className='sticker-to-print'
                ref={ref => (itemsRef.current[itemsRef.current.length] = ref)}>
                <Sticker
                  logo={form.logo}
                  company={form.company}
                  title={stickerModal.title}
                  quantityPerBox={estimated.remainderItems}></Sticker>
              </div>
            )
          })}
        </div>
      </DefaultModal>
    </div>
  )
}

export default StickerModal
