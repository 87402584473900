import { useEffect, useRef, useState } from 'react'
import Marquee from 'react-fast-marquee'
import useDashbord from '../../hooks/dashbord.hook'

function Notification() {
  const [notifications, setNotifications] = useState()
  const { getNotifications } = useDashbord()
  const containerRef = useRef(null)
  const [textRef, setTextRef] = useState()
  const [play, setPlay] = useState(false)

  useEffect(() => {
    const containerWidth = containerRef.current?.getBoundingClientRect().width
    const textWidth = textRef?.getBoundingClientRect().width
    setPlay(textWidth > containerWidth)
  }, [textRef, containerRef])

  useEffect(() => {
    ;(async () => {
      const { data } = await getNotifications()
      setNotifications(data?.map(x => x.title).join(' | '))
    })()
  }, [])

  if (!notifications) return null

  return (
    <div ref={containerRef} className='py-[1.3rem] px-[1rem] bg-Accent/Rose text-Regular(14_16)'>
      <Marquee
        style={{
          overflow: 'hidden'
        }}
        pauseOnHover={true}
        play={play}>
        <span className='pr-[10px]' ref={ref => setTextRef(ref)}>
          {notifications}
        </span>
      </Marquee>
    </div>
  )
}

export default Notification
