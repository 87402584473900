import { useContext, useCallback } from 'react'
import { AxiosContext } from '../context/request.context'
const qs = require('qs')

function usePosition() {
  const { authRequest } = useContext(AxiosContext)

  const setUser = async (positionStageId, userId) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: 'c-position-stages/updateUser/' + positionStageId,
        data: {
          user: userId
        }
      })
    } catch {}
  }

  const setReadyDate = async data => {
    try {
      return await authRequest({
        method: 'PUT',
        url: '/positionss/setReadyDate',
        data
      })
    } catch {}
  }

  const setStatus = async (positionStageId, statusId, drawingId = null) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: 'c-position-stages/updateStatus/' + positionStageId,
        data: {
          status: statusId,
          drawing: drawingId
        }
      })
    } catch {}
  }
  const groupSetStatus = async (statusId, positions, drawingId = null) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: '/c-position-stages/updateStatuses',
        data: {
          status: statusId,
          positions: positions,
          drawing: drawingId
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const groupSetPrintStatus = async (statusId, positions, drawingId = null) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: 'c-position-stages/updatePrintStatuses/',
        data: {
          status: statusId,
          drawing: drawingId,
          positions: positions
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const setPrintStatus = async (positionStageId, statusId, drawingId = null) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: 'c-position-stages/updatePrintStatus/' + positionStageId,
        data: {
          status: statusId,
          drawing: drawingId
        }
      })
    } catch {}
  }

  const setPrintUser = async (positionStageId, userId, drawingId = null) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: 'c-position-stages/updatePrintUser/' + positionStageId,
        data: {
          user: userId,
          drawing: drawingId
        }
      })
    } catch {}
  }

  const setPrintQuantity = async ({ positionStageId, userId, subusers = [], quantity, drawingId = null }) => {
    try {
      return await authRequest({
        method: 'PUT',
        url: 'c-position-stages/updatePrintQuantity/' + positionStageId,
        data: {
          quantity,
          subusers,
          drawing: drawingId,
          user: userId
        }
      })
    } catch {}
  }

  const setPositionsToUrgent = async ({ ids, isUrgent }) => {
    return await authRequest({
      method: 'POST',
      url: 'c-position-stages/setUrgentPosition',
      data: {
        ids,
        isUrgent
      }
    })
  }

  const setStagesToUrgent = async ids => {
    return await authRequest({
      method: 'PUT',
      url: 'c-position-stages/setUrgentStage',
      data: ids
    })
  }

  const setPositionsToCanceled = async ids => {
    return await authRequest({
      method: 'POST',
      url: 'c-position-stages/setPositionsToCanceled',
      data: ids
    })
  }

  const setPositionsToDelivered = async ids => {
    return await authRequest({
      method: 'POST',
      url: 'c-position-stages/setPositionsToDelivered ',
      data: ids
    })
  }

  const setPositionsToClosed = async ids => {
    return await authRequest({
      method: 'POST',
      url: 'c-position-stages/setPositionsToClosed',
      data: ids
    })
  }

  const setComment = async data => {
    return await authRequest({
      method: 'POST',
      url: '/comment/set-comment',
      data
    })
  }
  const groupSetComment = async data => {
    return await authRequest({
      method: 'POST',
      url: '/comment/set-group-comment',
      data
    })
  }

  const moveToStage = async ({ positionId, stageId, comment, problems }) => {
    return await authRequest({
      method: 'PUT',
      url: 'c-position-stages/moveToStage/',
      data: {
        positionId,
        stageId,
        comment,
        problems
      }
    })
  }
  const moveGroupToAdditionalWorkStage = async positionsIDs => {
    return await authRequest({
      method: 'POST',
      url: 'c-position-stages/moveGroupToAdditionalWorkStage/',
      data: { positionsIDs: positionsIDs }
    })
  }

  const getPosition = useCallback(
    async ({ positionId }) => {
      const query = qs.stringify(
        {
          populate: {
            order: {
              populate: {
                localComment: true
              }
            },
            drawings: {
              populate: {
                status: true,
                user: true,
                subusers: {
                  populate: {
                    user: true
                  }
                }
              }
            },
            c_position_stages: {
              populate: {
                user: true,
                status: true,
                stage: true,
                comments: true
              },
              //TODO STAGE HIDE FILTER
              filters: {
                stage: {
                  isHideStage: { $eq: false }
                }
              }
            }
          }
        },
        {
          encodeValuesOnly: true // prettify URL
        }
      )

      return await authRequest({
        url: `positionss/getPositon/${positionId}?` + query
      })
    },
    [authRequest]
  )

  const getPrintList = useCallback(async () => {
    const query = qs.stringify({
      populate: {
        print_group: true
      }
    })
    return await authRequest({
      url: `print-lists?` + query
    })
  }, [authRequest])

  const setSort = useCallback(
    async data => {
      return await authRequest({
        method: 'POST',
        url: 'c-position-stages/setSort',
        data
      })
    },
    [authRequest]
  )

  const editPosition = useCallback(
    async (positionId, data) => {
      return await authRequest({
        method: 'POST',
        url: `positionss/editPosition/${positionId}`,
        data
      })
    },
    [authRequest]
  )

  const createPosition = useCallback(
    async data => {
      return await authRequest({
        method: 'POST',
        url: `positionss/createPosition`,
        data
      })
    },
    [authRequest]
  )

  const drop = useCallback(async () => {
    if (/localhost/.test(window.origin) || /erpmarkli\.slamdev\.ru/.test(window.origin)) {
      return await authRequest({
        method: 'POST',
        url: 'c-position-stages/dropTable'
      })
    }
  }, [authRequest])

  const parserC = useCallback(async () => {
    return await authRequest({
      method: 'GET',
      url: 'c-position-stages/1cParse'
    })
  }, [authRequest])

  return {
    setUser,
    setSort,
    setStatus,
    setPositionsToUrgent,
    setStagesToUrgent,
    setComment,
    setPositionsToCanceled,
    setPositionsToClosed,
    setPrintStatus,
    setPrintUser,
    getPosition,
    moveToStage,
    moveGroupToAdditionalWorkStage,
    setPositionsToDelivered,
    drop,
    groupSetStatus,
    groupSetPrintStatus,
    groupSetComment,
    setPrintQuantity,
    setReadyDate,
    parserC,
    getPrintList,
    editPosition,
    createPosition
  }
}

export default usePosition
