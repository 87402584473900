import { LuUsersRound } from 'react-icons/lu'
import { useCallback, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import OutsideAlerter from '../outsideAlerter/outsideAlerter'

function DrawingsUserInfo({ subusers = [] }) {
  const [isOpen, setIsOpen] = useState(false)

  if (!subusers.length) return null

  return (
    <OutsideAlerter
      onClick={ev => ev.stopPropagation()}
      onEvent={() => setIsOpen(false)}
      className={`${isOpen ? 'z-[21]' : ''}   flex items-center justify-center`}>
      <div
        onClick={() => setIsOpen(true)}
        data-tooltip-id={'drawings-tooltip'}
        data-tooltip-position-strategy='absolute'
        className='w-[3rem] h-[3rem] flex justify-center items-center text-[1.5rem] text-Accent/Blue  hover:cursor-pointer hover:text-Accent/Blue_Light'>
        <LuUsersRound></LuUsersRound>
        <Tooltip
          className='comment-tooltip'
          isOpen={isOpen}
          openOnClick={true}
          opacity='1'
          style={{
            backgroundColor: '#fff',
            padding: 0,
            boxShadow: 'none',
            pointerEvents: 'auto',
            zIndex: 10
          }}
          id={'drawings-tooltip'}>
          <div className='text-[1.5rem]'>
            <i
              onClick={ev => {
                ev.stopPropagation()
                setIsOpen(false)
              }}
              className='icon-close text-Content/Dark text-Regular(18_24) hover:cursor-pointer hover:text-Accent/Blue   flex justify-center items-center absolute top-[1rem] right-[1rem]'></i>
            <div class='w-[29rem] p-[1.2rem] max-h-[40rem] overflow-auto  shadow-default bg-white text-Content/Dark'>
              <div class='text-Regular(14_16) font-bold'>Исполнители:</div>
              {subusers.map(({ user, readyCount }) => (
                <div>
                  {user.username}: {readyCount}
                </div>
              ))}
            </div>
          </div>
        </Tooltip>
      </div>
    </OutsideAlerter>
  )
}

export default DrawingsUserInfo
