import PositionStatus from '../../positionStatus/positionStatus'
import PositionUser from '../../positionUser/positionUser'
import usePosition from '../../../hooks/position.hook'
import stageStore from '../../../store/stage'
import Checkbox from '../../ui/checkbox/checkbox'
import dashbordStore from '../../../store/dashbord'
import { observer } from 'mobx-react-lite'
import Comments from '../../comments/comments'
import Cell from './cell'
import ProductSetLabel from '../../productSetLabel/ProductSetLabel'

function OrderStages({
  data,
  getData,
  onSelectPosition,
  selectAllPositions,
  onPositionDetail,
  selectedPositionsStages,
  debouncedGetData,
  showOrderKitModal,
  orderIdDB
}) {
  const { setUser, setStatus, setPrintStatus, setPrintUser } = usePosition()
  const { stages } = stageStore

  const onSetStatus = positionStageId => async statusId => {
    await setStatus(positionStageId, statusId)
    debouncedGetData()
  }

  const onSetPrintStatus = (drawingId, positionStageId) => async statusId => {
    await setPrintStatus(positionStageId, statusId, drawingId)
  }

  const onSetPrintUser = (drawingId, positionStageId) => async userId => {
    await setPrintUser(positionStageId, userId, drawingId)
  }

  const onSetUser = positionStageId => async userId => {
    await setUser(positionStageId, userId)
    await getData()
  }

  return (
    <div className='product-table border border-Content/Border border-l-0 border-r-0 border-b-0'>
      <div className='flex'>
        <div>
          <div className='flex'>
            <Cell ml='ml-[0px]' width='w-[4.8rem]' height='h-[6rem]'>
              <Checkbox
                active={data.positions?.length === selectedPositionsStages.length}
                onChange={selectAllPositions}></Checkbox>
            </Cell>
            <Cell width='w-[10rem]' height='h-[6rem]'>
              <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                <span>Фото</span>
              </div>
            </Cell>
            <Cell height='h-[6rem]'>
              <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                <span>Артикул</span>
              </div>
            </Cell>
            <Cell height='h-[6rem]'>
              <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                <span>Название товара</span>
              </div>
            </Cell>
          </div>
          {data.positions.map(({ id, isUrgent, stampSpecialId, link, image, code, title, kitID, stages }) => {
            const isSelected = dashbordStore.getIsPositionsSelected(id)
            const onOpenSetModal = e => {
              e.stopPropagation()
              showOrderKitModal(orderIdDB, kitID)
            }
            return (
              <div className='flex '>
                <div
                  className={`
										relative
										${isUrgent ? 'after:absolute after:top-[0] after:left-[100%] after:bottom-0 after:w-[0.4rem] after:bg-Accent/Red' : ''}
									`}>
                  <Cell ml='ml-[0px]' width='w-[4.8rem]'>
                    <Checkbox active={isSelected} onChange={() => onSelectPosition(id)}></Checkbox>
                  </Cell>
                </div>

                <Cell width='w-[10rem]'>
                  <img src={image} alt='' className='max-h-[100%] m-auto' />
                </Cell>
                <Cell>
                  <div className={'flex flex-col gap-[8px] items-center'}>
                    {kitID && <ProductSetLabel kitID={kitID} onOpenSetModal={onOpenSetModal} />}
                    <span className='line-clamp-3 text-Regular(16_18)'>{code}</span>
                  </div>
                </Cell>
                <Cell>
                  <span className='line-clamp-3 text-Regular(12_14)'>
                    {link ? (
                      <a
                        className='underline border-t-0 border-r-0 border-l-0 text-Accent/Blue hover:cursor-pointer hover:opacity-80 ease-out duration-300'
                        href={`https://markli.by${link}`}
                        target='_blank'>
                        {title}
                      </a>
                    ) : (
                      <>{title}</>
                    )}
                  </span>
                </Cell>
              </div>
            )
          })}
        </div>
        <div className='flex-1 overflow-auto'>
          <div className='grid grid-auto-cols'>
            {stages.map(x => (
              <Cell width={x.stampStage ? ' w-[46rem]' : 'w-[20rem]'} height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>{x.title}</span>
                </div>
              </Cell>
            ))}
          </div>
          {data.positions.map(({ id: positionId, stampSpecialId, drawings, image, code, title, stages }) => {
            return (
              <div className='grid grid-auto-cols'>
                {stages.map(
                  (
                    {
                      id: stageId,
                      service,
                      stampStage,
                      item: { id, isCurrentStage, blocked, user, status, comments, stageChangeTimeStamps } = {}
                    },
                    index
                  ) => {
                    if (!status) {
                      return (
                        <div
                          className={`
								  	${stampStage ? 'w-[46rem]' : 'w-[20rem]'}
								  	ml-[-1px] mt-[-1px]  h-[10rem] border border-Content/Border bg-Content/Light`}></div>
                      )
                    } else {
                      return (
                        <div
                          className={`
								  ${stampStage ? 'w-[46rem] overflow-auto' : 'w-[20rem]'}
										relative ml-[-1px] mt-[-1px] grid grid-template-rows-[1fr_2fr]gap-[6px]  h-[10rem] border border-Content/Border p-[6px] `}>
                          {comments?.length > 0 && <Comments comments={comments}></Comments>}
                          {!stampStage && (
                            <PositionUser
                              service={service}
                              small={true}
                              stageId={stageId}
                              currentData={user}
                              onSetData={onSetUser(id)}
                              isCurrentStage={isCurrentStage}></PositionUser>
                          )}

                          {stampStage ? (
                            <div className='grid'>
                              <div
                                className={`
															${drawings?.length > 1 ? 'py-[0.2rem]' : ''}
															grid
															flex-col
															gap-[1px]
															w-[100%]							
															h-[100%]`}>
                                {drawings.map(
                                  ({ status: drawingStatus, id: drawingId, user, name, subusers = [] }, _, arr) => {
                                    let currentStatus

                                    if (drawingStatus) {
                                      currentStatus = drawingStatus
                                    } else {
                                      currentStatus = status
                                    }

                                    return (
                                      <div
                                        key={id}
                                        className=' grid grid-cols-[12rem_10rem_10rem_10rem] items-center gap-[5px]'>
                                        <PositionUser
                                          service={service}
                                          stageId={stageId}
                                          currentData={user}
                                          multi={arr.length > 1}
                                          onSetData={onSetPrintUser(drawingId, id)}
                                          isCurrentStage={isCurrentStage}></PositionUser>
                                        <span className='text-Regular(12_14) line-clamp-1 overflow-hidden'>{name}</span>
                                        <PositionStatus
                                          positionId={positionId}
                                          user={user}
                                          service={service}
                                          blocked={blocked}
                                          stageId={stageId}
                                          getData={getData}
                                          debouncedGetData={debouncedGetData}
                                          isCurrentStage={isCurrentStage}
                                          currentData={currentStatus}
                                          onSetData={onSetPrintStatus(drawingId, id)}
                                          timestamps={stageChangeTimeStamps}
                                          multi={arr.length > 1}></PositionStatus>

                                        <span>
                                          (готово{' '}
                                          <span className='text-Accent/Green'>
                                            {subusers.reduce((acc, { readyCount }) => (acc += readyCount), 0) || 0}
                                          </span>
                                          )
                                        </span>
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          ) : (
                            <PositionStatus
                              positionId={positionId}
                              className='text-Regular(12_14)'
                              user={user}
                              currentData={status}
                              blocked={blocked}
                              stageId={stageId}
                              getData={getData}
                              small={true}
                              isCurrentStage={isCurrentStage}
                              onSetData={onSetStatus(id)}
                              timestamps={stageChangeTimeStamps}
                              stampSpecialId={stampSpecialId}
                              debouncedGetData={debouncedGetData}></PositionStatus>
                          )}
                        </div>
                      )
                    }
                  }
                )}
              </div>
            )
          })}
        </div>
        <div>
          <Cell width='w-[4.8rem]' height='h-[6rem]'></Cell>
          {data.positions.map(({ id }) => (
            <Cell width='w-[4.8rem]'>
              <div
                onClick={() => onPositionDetail(id)}
                className='hover:cursor-pointer w-[20px] h-[20px] flex justify-center items-center'>
                <i className='duration-300 ease-in-out hover:text-Accent/Blue icon-drag text-Regular(24_30) text-Content/Light'></i>
              </div>
            </Cell>
          ))}
        </div>
      </div>
    </div>
  )
}

export default observer(OrderStages)
