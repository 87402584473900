import { makeAutoObservable } from 'mobx'

class Stage {
  currentStage = {}
  stages = []

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentStage(stage) {
    if (stage) {
      this.currentStage = stage
    } else {
      this.currentStage.id = null
    }
  }
  setStages(stages) {
    if (stages.length) {
      this.stages = stages
    }
  }
}

const stage = new Stage()

export default stage
