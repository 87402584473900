import Checkbox from '../../ui/checkbox/checkbox'
import Cell from './cell'
import dashbordStore from '../../../store/dashbord'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tooltip'
import ProductSetLabel from '../../productSetLabel/ProductSetLabel'

function OrderDetail({
  data,
  getData,
  onSelectPosition,
  selectAllPositions,
  onPositionDetail,
  selectedPositionsStages,
  showOrderKitModal,
  orderIdDB
}) {
  const selectedLength = selectedPositionsStages.length

  return (
    <>
      <div className='product-table border border-Content/Border border-l-0 border-r-0 border-b-0'>
        <div className='flex'>
          <div>
            <div className='flex'>
              <Cell ml='ml-[0px]' width='w-[4.8rem]' height='h-[6rem]'>
                <Checkbox active={data.positions?.length === selectedLength} onChange={selectAllPositions}></Checkbox>
              </Cell>
              <Cell height='h-[6rem]' width='w-[10rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Фото</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Артикул</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Название товара</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Количество</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Метод печати</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Количество цветов</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Логотип</span>
                </div>
              </Cell>
              <Cell height='h-[6rem]'>
                <div className='flex flex-col gap-[0.8rem] text-center font-semibold'>
                  <span>Этап</span>
                </div>
              </Cell>
            </div>
            {data.positions.map(
              ({
                id,
                link,
                isUrgent,
                quantity,
                quantityInStock,
                quantityUnderOrder,
                c_position_stages,
                drawings,
                image,
                code,
                title,
                stages,
                kitID
              }) => {
                const isSelected = dashbordStore.getIsPositionsSelected(id)
                const c_position_ID = c_position_stages[0]?.id

                const onOpenSetModal = e => {
                  e.stopPropagation()
                  showOrderKitModal(orderIdDB, kitID)
                }

                return (
                  <div className='flex '>
                    <div
                      className={`
										relative
										${isUrgent ? 'after:absolute after:top-[0] after:left-[100%] after:bottom-0 after:w-[0.4rem] after:bg-Accent/Red' : ''}
									`}>
                      <Cell ml='ml-[0px]' width='w-[4.8rem]'>
                        <Checkbox active={isSelected} onChange={() => onSelectPosition(id, c_position_ID)}></Checkbox>
                      </Cell>
                    </div>

                    <Cell width='w-[10rem]'>
                      <img src={image} alt='' className='max-h-[100%] m-auto' />
                    </Cell>
                    <Cell>
                      <div className={'flex flex-col gap-[8px] items-center'}>
                        {kitID && <ProductSetLabel onOpenSetModal={onOpenSetModal} kitID={kitID} />}
                        <span className='line-clamp-4 break-all text-Regular(16_18)'>{code}</span>
                      </div>
                    </Cell>
                    <Cell>
                      <span
                        className='line-clamp-4 text-Regular(12_14)'
                        data-tooltip-id='cell_tooltip'
                        data-tooltip-content={title}>
                        {link ? (
                          <a
                            className='underline break-all border-t-0 border-r-0 border-l-0 text-Accent/Blue hover:cursor-pointer hover:opacity-80 ease-out duration-300'
                            href={`https://markli.by${link}`}
                            target='_blank'>
                            {title}
                          </a>
                        ) : (
                          <>{title}</>
                        )}
                      </span>
                    </Cell>
                    <Cell>
                      <div className='flex flex-col items-center'>
                        {quantityUnderOrder && (
                          <div className='flex gap-[0.3rem] left-[100%] bottom-[100%]  text-Regular(12_14)'>
                            <span className='text-Accent/Green'> {quantityInStock}</span>/{' '}
                            <span className='text-Accent/Orange'>{quantityUnderOrder}</span>
                          </div>
                        )}
                        <span className='line-clamp-4 text-Regular(16_18)'>{quantity}</span>
                      </div>
                    </Cell>
                    <Cell>
                      <div className='grid gap-[0.4rem] h-[100%] overflow-auto w-[100%]'>
                        {drawings?.map(({ name }, _, arr) => (
                          <div className='flex items-center justify-center'>
                            <span
                              className={`${arr.length == 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                              {name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Cell>
                    <Cell>
                      <div className='grid gap-[0.4rem] h-[100%] w-[100%] overflow-auto'>
                        {drawings?.map(({ stamps }, _, arr) => (
                          <div className='flex items-center justify-center'>
                            <span
                              className={`${arr.length == 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-1`}>
                              {stamps || 1}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Cell>
                    <Cell>
                      <div className='grid gap-[0.4rem] h-[100%] overflow-auto w-[100%]'>
                        {drawings?.map(({ logo }, _, arr) => (
                          <div className='flex items-center justify-center'>
                            <span
                              className={`${arr.length == 1 ? 'text-Regular(16_18)' : 'text-Regular(12_14)'} line-clamp-4`}
                              data-tooltip-id='cell_tooltip'
                              data-tooltip-content={logo}>
                              {logo || '-'}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Cell>
                    <div
                      style={{
                        backgroundColor: c_position_stages[0]?.stage?.color
                      }}>
                      <Cell>
                        <span className='line-clamp-3 text-Regular(16_18)'>{c_position_stages[0]?.stage?.title}</span>
                      </Cell>
                    </div>
                  </div>
                )
              }
            )}
            <Tooltip
              className='cell_tooltip'
              opacity='1'
              clickable={true}
              style={{
                backgroundColor: '#000000',
                padding: '12px',
                boxShadow: 'none',
                pointerEvents: 'auto',
                zIndex: 10,
                fontSize: '16px',
                maxWidth: '400px'
              }}
              id={'cell_tooltip'}></Tooltip>
          </div>

          <div>
            <Cell width='w-[4.8rem]' height='h-[6rem]'></Cell>
            {data.positions.map(({ id }) => (
              <Cell width='w-[4.8rem]'>
                <div
                  onClick={() => onPositionDetail(id)}
                  className='hover:cursor-pointer w-[20px] h-[20px] flex justify-center items-center'>
                  <i className='duration-300 ease-in-out hover:text-Accent/Blue icon-drag text-Regular(24_30) text-Content/Light'></i>
                </div>
              </Cell>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(OrderDetail)
