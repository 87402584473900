function ButtonSimple({ name, onPress }) {
  return (
    <div
      onClick={onPress}
      className='ease-in-out duration-300 py-[1.1rem] hover:cursor-pointer hover:text-Content/Middle px-[1.8rem] rounded-[8px] font-medium  text-Content/Dark text-Regular(16_20)'>
      <span>{name}</span>
    </div>
  )
}

export default ButtonSimple
